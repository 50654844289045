import { AnimatePresence } from 'framer-motion';
import React from 'react';
import { StyledList } from './styled';
import { useNotification } from '~/shared/hooks/useNotification';
import { Notification } from './components/Notification';

/**
 * Displays UI based on notification state
 * can be used in root or a scoped location
 *
 */
export const Notifications = () => {
    const { notifications, dismiss } = useNotification();

    return (
        <StyledList layout>
            <AnimatePresence>
                {notifications.map((props) => (
                    <li key={props.id}>
                        <Notification onDismiss={() => dismiss(props.id)} {...props} />
                    </li>
                ))}
            </AnimatePresence>
        </StyledList>
    );
};
