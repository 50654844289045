import styled from '@emotion/styled';
import { switchProp } from 'styled-tools';
import { animations } from '../../../theme';
import { Variant } from './ProductImage';

export const StyledImageWrapper = styled.div<{ variant?: Variant; isLoaded: boolean }>(
    ({ theme }) => ({
        position: 'relative',
        height: '100%',
        backgroundColor: theme.colors.white,
        overflow: 'hidden'
    }),
    switchProp('variant', {
        product: ({ theme, isLoaded }) => ({
            // Workaround until layout="raw" is not experimental anymore
            img: {
                padding: `0 ${theme.spaces[4]}!important`,
            },
            '&:after': {
                content: '""',
                border: `1px solid ${theme.colors.grey30}`,
                position: 'absolute',
                inset: 0,
                transition: `
                    opacity
                    ${animations.getDuration('moderate01')}
                    ${animations.getEasing('entrance', 'expressive')}
                `,
                opacity: isLoaded ? 1 : 0,
            },
        }),
        fallback: ({ theme }) => ({
            // Blend with fallback image
            img: {
                backgroundColor: theme.colors.grey20,
            },
        }),
    })
);
