import type { PageKey } from './types';

export class PageNotFoundError extends Error {
  queryKeys: PageKey;
  response: Response;

  constructor(response: Response, keys: PageKey) {
    super();
    this.name = 'PageNotFoundError';
    this.message = `Request failed with status code 404 Not Found on url ${response.url}`;
    this.queryKeys = keys;
    this.response = response;
  }
}

export class PageUnknownError extends Error {
  queryKeys?: PageKey;
  response: Response;

  constructor(response: Response, keys?: PageKey) {
    super();
    this.name = 'PageError';
    this.message = `Request failed with status code ${response?.status} on url ${response?.url}`;
    this.response = response;

    if (keys) {
      this.queryKeys = keys;
    }
  }
}
