import React, { FC } from 'react'
import { Loader, SvgIcon, Text } from 'ui';
import { useFavorites } from '../hooks/use-favorites';
import { useTranslations } from '~/services/cms';
import styles from './AddFavoriteButton.module.css'
import { useAuthentication } from '~/shared/hooks';
import clsx from 'clsx';

interface IProps {
    productId: any;
    hideText?: boolean;
    className?: string;
}

const AddFavoriteButton: FC<IProps> = ({ productId, hideText, className }) => {
    const translate = useTranslations()
    const { isInTheList, favoritesMutation } = useFavorites()
    const { isAuthenticated } = useAuthentication();

    const isSelected = isInTheList(productId)
    const isFetching = favoritesMutation?.isLoading

    if (!isAuthenticated) {
        return null
    }


    return (
        <button
            className={clsx(styles.button, className)}
            disabled={isFetching}
            onClick={() => favoritesMutation.mutate(productId)}
        >
            <div className={styles.iconWrapper}>
                {isFetching && (
                    <Loader
                        className={styles.loader}
                        padding="0"
                        size="26px"
                        borderWidth="2px" />
                )}
                <SvgIcon name='star' className={isSelected ? styles.iconSelected : styles.icon} />
            </div>
            {!hideText && (
                <Text variant='bodySmall' color='natural700Color'>
                    {isSelected
                        ? translate('favorites.removeFromFavorites')
                        : translate('favorites.addAsFavorite')
                    }
                </Text>
            )}
        </button>
    )
}

export default AddFavoriteButton