import styled from '@emotion/styled';
import { CSSObject, keyframes } from '@emotion/react';
import { SkeletonProps } from './Skeleton';
import { cover as coverStyle, shimmer as shimmerStyle } from 'utils';

const shimmerAnimation = keyframes({
  '100%': { maskPosition: 'left' },
});

/**
 * Adds "shimmer" effect, can be used to indicate a loading state
 */
export const shimmer: CSSObject = {
  '@supports(-webkit-mask-image: url(#mask)) or (mask-image: url(#mask))': {
    mask: 'linear-gradient(-60deg,#000 30%,#0005,#000 70%) right/300% 100%',
    animation: `${shimmerAnimation} 1s infinite`,
  },
};

export const StyledBlock = styled.div<SkeletonProps>(
  ({ theme, width, height, cover, shimmer }) => ({
    backgroundColor: theme.colors.grey30,
    ...(width ? { width: `${width}px` } : {}),
    ...(height ? { height: `${height}px` } : {}),
    ...(cover ? { ...coverStyle } : {}),
    ...(shimmer ? { ...shimmerStyle } : {}),
  })
);
