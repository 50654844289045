import styles from './OrderHistoryList.module.css';
import React from 'react';
import { Text } from 'ui';
import clsx from 'clsx';
import { CommerceModel } from 'api-types';
import { useTranslations } from '~/services/cms';

type IProps = CommerceModel.OrderHistory;

export const OrderHistoryList = (order: IProps) => {

    const translate = useTranslations();

    return (
        <div className={styles.gridRoot}>
            {order.lines?.map((line, index) => (
                <div className={styles.divItem} key={index}>
                    <div>
                        <Text className={clsx([styles.partNo, styles.item])}>
                            {translate('profile.productId')}
                        </Text>
                        <Text className={clsx([styles.partData, styles.item])}>
                            {line.jpGroupNumber}
                        </Text>
                    </div>

                    <div>
                        <Text className={clsx([styles.partNo, styles.item])}>
                            {translate('profile.name')}
                        </Text>
                        <Text className={clsx([styles.partData, styles.item])}>
                            {line.name || '-'}
                        </Text>
                    </div>

                    <div>
                        <Text className={clsx([styles.description, styles.item])}>
                            {translate('profile.description')}
                        </Text>
                        <Text className={clsx([styles.partDesc, styles.item])}>
                            {line.description || '-'}
                        </Text>
                    </div>

                    <div>
                        <Text className={clsx([styles.quantity, styles.item])}>
                            {translate('profile.quantity')}
                        </Text>
                        <Text className={clsx([styles.partQty, styles.item])}>{line.quantity}</Text>
                    </div>
                </div>
            ))}
        </div>
    );
};
