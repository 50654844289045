import type { ProductKey } from './types';

export class ProductError extends Error {
  queryKeys: ProductKey;
  response: Response;

  constructor(response: Response, keys: ProductKey) {
    super();
    this.name = 'ProductError';
    this.message =
      'Request failed with status code 500 Internal Server Error on url ' + response.url;
    this.queryKeys = keys;
    this.response = response;
  }
}
