import clsx from 'clsx';
import { FC, forwardRef } from 'react'
import { Button, IButtonProps } from '../../index';
import styles from './DropdownButton.module.css'

export const DropdownButton: FC<IButtonProps> = forwardRef((props, ref) => {
    const {
        variant = 'secondary',
        iconPosition = 'right',
        iconName = 'chevron-down',
        size = 'large',
        isFlexible = true,
        children,
        className,
        ...restProps
    } = props

    return (
        <Button
            className={clsx(styles.button, className)}
            isFlexible
            iconName={iconName}
            variant={variant}
            iconPosition={iconPosition}
            size={size}
            ref={ref}
            {...restProps}
        >
            {children}
        </Button>
    )
})