import React, { ReactNode } from 'react';
import { Hydrate, HydrateProps, QueryClient, QueryClientProvider } from '@tanstack/react-query';
/**
 * Find default query options as
 * `QueryObserverOptions` at https://github.com/tannerlinsley/react-query/blob/master/src/core/types.ts
 */
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            // Disabled since we don't rely on realtime data
            refetchOnWindowFocus: false,

            // Time before revalidating data. Set to 5min to
            // match reactQuery's default cache time
            staleTime: 30000,
        },
    },
});

export const RequestProvider = ({
    children,
    state,
}: {
    children: ReactNode;
    state?: HydrateProps['state'];
}) => {
    return (
        <QueryClientProvider client={queryClient}>
            <Hydrate state={state}>{children}</Hydrate>
        </QueryClientProvider>
    );
};
