import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { FavoritesModel } from 'api-types';
import { useNotification } from '~/shared/hooks/useNotification';
import { useAuthentication, usePrefixUrl } from '~/shared/hooks';

interface IFavoritesResponse extends FavoritesModel.FavoriteList {
    statusCode: number
}

export const useFavorites = () => {
    const { push } = useNotification();
    const { favoritesBaseUrl } = usePrefixUrl()
    const { isAuthenticated, session } = useAuthentication();

    const queryClient = useQueryClient()

    const { isLoading, isError, data } = useQuery<IFavoritesResponse>({
        refetchOnWindowFocus: true,
        queryKey: ['favorites'],
        enabled: isAuthenticated,
        queryFn: async () => {
            const res = await fetch(favoritesBaseUrl, {
                headers: {
                    'Authorization': `Bearer ${session?.accessToken}`
                }
            })
            const json = await res.json()
            return ({
                ...json,
                statusCode: res.status
            })
        }
    })

    function isInTheList(itemId: string) {
        return Boolean(data?.items?.find(f => f.productId === itemId))
    }

    const favoritesMutation = useMutation({
        mutationFn: async (id: string) => {
            const isSelected = isInTheList(id);
            const res = await fetch(`${favoritesBaseUrl}/line/${id}`, {
                method: isSelected ? 'DELETE' : 'POST',
                headers: {
                    'Authorization': `Bearer ${session?.accessToken}`
                }
            });
            return await (res.json());
        },
        onError: (err) => {
            console.log('err', err)
            push({ severity: 'warning', text: 'Could add to favorites!!!' });
        },
        onSuccess: data => {
            queryClient.setQueryData(['favorites'], data)
        }
    })


    return {
        favoritesMutation,
        data,
        isLoading,
        isError,
        isInTheList
    }
}