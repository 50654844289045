import { Ref, FC, forwardRef, MouseEventHandler } from 'react';
import NextLink from 'next/link';
import { IButtonCommonProps } from '../Buttons.models';

const ButtonWrapper: FC<IButtonCommonProps> = forwardRef(({
    className,
    isLoading,
    disabled,
    href,
    target,
    children,
    download,
    type = 'button',
    ...restProps
}, ref): JSX.Element => {
    if (href && !disabled) {
        return (
            <NextLink href={href}>
                <a className={className}
                    target={target}
                    ref={ref as Ref<HTMLAnchorElement>}
                    href={href}
                    style={restProps?.style}
                    download={download}
                    {...restProps['aria-label'] && { "aria-label": restProps['aria-label'] }}
                    aria-label={restProps['aria-label']}
                    onClick={restProps?.onClick as unknown as MouseEventHandler<HTMLAnchorElement>}
                    {...target === '_blank' ? {
                        rel: 'noopener noreferrer'
                    } : null}>
                    {children}
                </a>
            </NextLink>
        );
    }

    return (
        <button
            className={className}
            disabled={isLoading || disabled}
            type={type}
            {...restProps}
            ref={ref as Ref<HTMLButtonElement>}
        >
            {children}
        </button>
    );
});

export default ButtonWrapper;
