import React, { FC, useRef } from 'react'
import { Button, Text, Progress } from 'ui';
import styles from './LoadMore.module.css'
import { useTranslations } from '~/services/cms';

interface IProps {
    onClick: () => void;
    total?: number;
    loaded?: number;
    isFetching: boolean;
    hasNextPage: boolean | undefined;
}

export const getPercentage = (partialValue: number, totalValue: number) => {
    if (partialValue === 0 || totalValue === 0) return 0
    return (100 * partialValue) / totalValue

}

export const LoadMore: FC<IProps> = (props) => {
    const {
        total = 0,
        loaded = 0,
        onClick,
        isFetching,
        hasNextPage,
    } = props

    const translate = useTranslations();
    const buttonEl = useRef(null)
    const allLoaded = total <= loaded


    return (
        <div className={styles.root}>
            <div className={styles.progress}>
                <Progress percentage={getPercentage(loaded, total)} />
            </div>
            <Text>
                {
                    translate('common.showLoadedOutOfTotal')
                        .replace('[loaded]', loaded.toString())
                        .replace('[total]', total.toString())
                }
            </Text>
            <Button
                isLoading={isFetching}
                onClick={onClick}
                ref={buttonEl}
                disabled={!hasNextPage || isFetching || allLoaded}
            >
                {translate('common.loadMore')}
            </Button>
        </div>
    )
}
