import { ButtonHTMLAttributes, FC, ReactElement, useRef, useState } from 'react'
import styles from './MultipleSelector.module.css'
import { Text, DropdownButton, Skeleton } from '../../index';
import { useClickAway } from 'react-use';

interface IMultipleSelectorOptionProps
    extends Pick<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick' | 'disabled'> {
    label: string | JSX.Element;
    secondaryText?: string | number;
    isSelected?: boolean;
}

export const MultipleSelectorOption: FC<IMultipleSelectorOptionProps> = (props) => {
    const {
        onClick, label, isSelected, secondaryText, ...rest
    } = props
    return (
        <li className={styles.optionsListItem}>
            <button
                onClick={onClick}
                className={styles.optionButton}
                {...rest}>
                {/* TODO: replace with <CheckBox /> when ready */}
                <span className={styles.checkBox}>
                    {isSelected && <span className={styles.checkBoxChecked} />}
                </span>
                <div className={styles.labelWrapper}>
                    <Text textAlign='left'>{label}</Text>
                    {secondaryText && (
                        <Text color='natural700Color'
                            variant='bodySmall'
                            fontWeight='light'
                        >{secondaryText}</Text>
                    )}
                </div>
            </button>

        </li>
    )
}

interface IProps {
    label?: string;
    isFetching?: boolean;
    children?: ReactElement<IMultipleSelectorOptionProps> | ReactElement<IMultipleSelectorOptionProps>[];
}

export const MultipleSelector: FC<IProps> = (props) => {
    const { label, isFetching, children } = props
    const [isOpen, setOpen] = useState(false)
    const dropdownRef = useRef<HTMLDivElement>(null);
    const handleDropdownClose = () => {
        setOpen(false);
    };

    useClickAway(
        dropdownRef,
        (e: MouseEvent) => {
            if (dropdownRef?.current?.contains(e.target as HTMLElement)) return;
            setOpen(false);
        },
        ['click']
    );
    return (
        <div className={styles.root} ref={dropdownRef}>
            <div className={styles.dropdownButtonWrapper} >
                <DropdownButton onClick={() => setOpen(prev => !prev)}>
                    {label}
                </DropdownButton>
                {isFetching && (
                    <Skeleton width='100%' height='100%' className={styles.skeleton} />
                )}
            </div>
            {isOpen && (
                <ul className={styles.optionsList} onClick={() => handleDropdownClose()}>
                    {children}
                </ul>
            )}
        </div>
    )
}