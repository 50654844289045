import { ImageLoaderProps } from 'next/image';
import { getHostUrl } from 'utils';

const normalizeSrc = (src: string) => {
    return src[0] === '/' ? src.slice(1) : src;
};

export const cloudflareLoader = ({ src, width, quality }: ImageLoaderProps) => {
    const params = [`width=${width}`];
    params.push(`format=auto`); // Auto for Avif as default with fallback with Webp
    if (quality) {
        params.push(`quality=${quality}`);
    } else {
        params.push(`quality=90`);
    }

    const paramsString = params.join(',');
    return `${getHostUrl()}/cdn-cgi/image/${paramsString}/${normalizeSrc(src)}`;
};