import styled from '@emotion/styled';
import { ifProp, switchProp } from 'styled-tools';
import { StyledButton } from '../Button/styled';
import { PlayButtonProps } from './Video';

interface StyledVideoContainerProps {
    playing?: boolean;
    cover?: boolean;
}

export const StyledVideoAspect = styled.div({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '100%',
    width: '100%',
    iframe: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
    },
});

export const StyledVideoContainer = styled.div<StyledVideoContainerProps>(
    {
        position: 'relative',
        width: '100%',
        overflow: 'hidden',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    ifProp('playing', {
        cursor: 'pointer',
    }),
    ifProp('cover', {
        height: '100%',
    })
);

export const StyledPlayButton = styled(StyledButton)<PlayButtonProps>(
    {
        border: 'none',
        borderRadius: '50%',
        appearance: 'none',
        position: 'absolute',
        top: '50%',
        left: '50%',
        backgroundColor: 'rgb(60, 57, 57)',
        height: '50px',
        width: '50px',
        transform: 'translate(-50%, -50%)',
        transition: 'opacity 0.1s, transform 0.1s ease-in-out',
        svg: {
            position: 'relative',
            left: '25%',
            stroke: 'white',
            height: '1em',
            width: '1em',
            transform: 'scale(2.5)',
            fill: 'white',
        },
    },
    switchProp('playing', {
        true: {
            opacity: 0,
            pointerEvents: 'none',
        },
        false: {
            opacity: '0.8',

        },
    })
);
