import styled from '@emotion/styled';
import { ifNotProp } from 'styled-tools';

export const StyledCheckbox = styled.input({
    top: 0,
    left: 0,
    width: '100%',
    cursor: 'inherit',
    height: '100%',
    margin: 0,
    opacity: 0,
    padding: 0,
    zIndex: 1,
    position: 'absolute',
});

export const StyledLabel = styled.label(({ theme }) => ({
    color: theme.colors.black,
    fontSize: theme.fontSizes.sm,
    display: 'block',
    cursor: 'pointer',
    padding: `4px 0 0 ${theme.spaces[3]}`,
    lineHeight: '18px',
    minHeight: 25,
}));


export const StyledCheckboxIndicator = styled.div<{ round: boolean }>(
    ({ round }) => ({
        position: 'absolute',
        top: 3,
        right: 3,
        bottom: 3,
        left: 3,
        backgroundColor: 'transparent',
        border: '1px solid transparent',
        borderRadius: round ? '100%' : '2px',
    })
)


export const StyledCheckboxElement = styled.div<{ round: boolean }>(
    ({ round }) => ({
        position: 'relative',
        border: `1px solid var(--color-neutral-500)`,
        boxSizing: 'border-box',
        width: 24,
        height: 24,
        cursor: 'pointer',
        borderRadius: round ? '100%' : '2px',
    })
);

export const StyledCheckboxWrapper = styled.div<{ valid: boolean }>(
    () => ({
        display: 'grid',
        gridTemplateColumns: `25px 1fr`,
        columnGap: '10px',
        ':hover': {
            [`${StyledCheckboxIndicator}`]: {
                backgroundColor: 'var(--color-neutral-300)',
            },
            [`input:checked + ${StyledCheckboxIndicator}`]: {
                backgroundColor: 'var(--color-neutral-300)',
            },
        },
        ':focus-within': {
            [`${StyledCheckboxElement}`]: {
                border: `2px solid var(--color-focus)`
            },
        },

        [`input:checked + ${StyledCheckboxIndicator}`]: {
            backgroundColor: 'var(--color-neutral-700)',
        },
    }),
    ifNotProp('valid', ({ theme }) => ({
        [`${StyledLabel}`]: {
            color: theme.colors.negative,
        },
    }))
);

export const StyledCheckboxHelpTexts = styled.div(({ theme }) => ({
    marginLeft: `calc(25px + ${theme.spaces[3]})`,
}));
