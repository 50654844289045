import { forwardRef } from 'react';
import type { ForwardRefComponent } from 'utils';
import { StyledAspectRatio, StyledAspectRatioContent, StyledAspectRatioProps } from './styled';
import React from 'react';

export type AspectRatioProps = StyledAspectRatioProps;

/**
 * Use for any case where a `height` (or `min-height`) determined by a fixed aspect ratio is needed
 *
 * Set widescreen ratio:
 *
 * ```tsx
 * <AspectRatio ratio={16/9}>
 *     { content }
 * </AspectRatio>
 * ```
 *
 * Set ratio, but allow long content to expand height:
 *
 * ```tsx
 * <AspectRatio ratio={16/9} asMinHeight>
 *     { content potentially taller than aspect ratio }
 * </AspectRatio>
 * ```
 */
export const AspectRatio: ForwardRefComponent<
    typeof StyledAspectRatio,
    AspectRatioProps
> = forwardRef(({ ratio, children, ...restProps }, ref) => (
    <StyledAspectRatio ratio={ratio} {...restProps} ref={ref}>
        {children && <StyledAspectRatioContent>{children}</StyledAspectRatioContent>}
    </StyledAspectRatio>
)) as ForwardRefComponent<typeof StyledAspectRatio, AspectRatioProps>;
