import React, { ReactNode } from 'react';
import { MaxWidth, Gutter } from '../../../';
import { useModuleTheme } from './useModule';
import { StyledModule } from './styled';

export type ModuleContainerProps = {
    /**
     * Adds a solid background color to the entire module space.
     * Edge to edge.
     */
    backgroundColor?: string;

    /**
     * Prevents capping the width to the max content widht
     */
    fullWidth?: boolean;

    /**
     * Add side gutters to the module. Usefull if the content
     * is not intended to go full width on mobile. eg. text modules
     */
    hasGutter?: boolean;

    children: ReactNode;

    hasPadding?: boolean;
    isHero?: boolean;

};

export const ModuleContainer = ({
    fullWidth,
    children,
    hasGutter,
    hasPadding = true,
    isHero = false,
    backgroundColor: initialBackgroundColor,

}: ModuleContainerProps) => {
    const { backgroundColor, textColor } = useModuleTheme({
        backgroundColor: initialBackgroundColor === null ? undefined : initialBackgroundColor,
    });

    const content = hasGutter ? <Gutter children={children} /> : children;

    return (
        <StyledModule
            // TODO set text color inside header to adjust link shading
            textColor={textColor}
            hasPadding={hasPadding}
            backgroundColor={backgroundColor}
            isHero={isHero}
        >
            {fullWidth ? content : <MaxWidth children={content} />}
        </StyledModule>
    );
};
