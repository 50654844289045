import { CommerceModel } from 'api-types';
import { deleteRequest } from '~/shared/utils';

export async function removeItem(variantId: string, apiUrl: string, token?: string) {
    const request = await deleteRequest<CommerceModel.Basket>(apiUrl, {
        variantId,
    }, {
        'Authorization': `Bearer ${token}`
    });

    const response = await request();

    return response;
}
