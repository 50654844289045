import { useRouter } from 'next/router';
import type { CmsModel } from 'services';
import { useTranslations as useServicesTranslations } from 'services';
import { namespacedKeys } from 'utils';
import locale from '~/constants/locale.mjs';

const fallbackLocale = locale.defaultLocale;

export function useTranslations() {
    const { locale, defaultLocale = fallbackLocale } = useRouter();
    const language = locale || defaultLocale;

    const { data: translations } = useServicesTranslations({
        locale: language,
        select: (data) => namespacedKeys<Translations>(data),
    });

    return (key: TranslationModel, values?: TranslationValue[], ignoreMissing?: boolean) =>
        translate({ key, values, ignoreMissing, translations });
}

// Transform a string based on the given values into a formatted string
function translate({ key, values, ignoreMissing, translations }: Translate) {
    let translation = key ? translations?.[key] : undefined;

    if (typeof translation === 'undefined' || translation === null) {
        if (!ignoreMissing) {
            console.warn(`Missing translation: ${key}`);
        }

        return '';
    }

    if (!translation) {
        return '';
    }

    if (values) {
        for (const i in values) {
            switch (values[i].type) {
                case TranslationValueType.Date:
                    // TODO
                    translation = '';
                    break;

                default:
                    translation = translation.replace(`{{${values[i].key!}}}`, values[i].value);
                    break;
            }
        }
    }

    return translation as string | NonNullable<Translation['name']>;
}

type Translations = CmsModel.ContentTypes.TranslationsTypes.Translations;
type TranslationModel = CmsModel.ContentTypes.TranslationsTypes.TranslationModel;

interface Translate {
    key: TranslationModel;
    values?: TranslationValue[];
    ignoreMissing?: boolean;
    translations: Translations | undefined;
}

// TODO: Get these from CMS types when BE is ready
interface Translation {
    name?: string | undefined;
    values?: TranslationValue[] | undefined;
}

interface TranslationValue {
    type?: TranslationValueType;
    key?: string | undefined;
    value?: any | undefined;
    format?: string | undefined;
}

enum TranslationValueType {
    String = 'String',
    Integer = 'Integer',
    Decimal = 'Decimal',
    Currency = 'Currency',
    DateTime = 'DateTime',
    Date = 'Date',
    Time = 'Time',
}
