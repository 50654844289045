import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { useConfig } from '~/shared/hooks';
import { isServer } from "utils";

const defaultBrowserHistory = {
    url: '/',
    location: { pathname: '' },
    listen: () => { }
};

let browserHistory = defaultBrowserHistory;

if (typeof window !== 'undefined') {
    browserHistory = { ...browserHistory, ...window.history };
    //@ts-ignore
    browserHistory.location.pathname = browserHistory?.state?.url;
}

let insightsKey = undefined;
if (isServer) {
    //@ts-ignore
    insightsKey = process.env.APPINSIGHTS_CONNECTION_STRING;
} else {
    const bodyAttribute = document.body.getAttribute('data-insight');
    insightsKey = bodyAttribute && bodyAttribute.length > 0 ? bodyAttribute : undefined;
}

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        //instrumentationKey: insightsKey,
        connectionString: insightsKey,
        maxBatchSizeInBytes: 10000,
        maxBatchInterval: 15000,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory }
        }
    }
});

console.log('Loading Application Insights...');

if (typeof window !== 'undefined' && insightsKey) {
    console.log('Loaded Application Insights');
    appInsights.loadAppInsights();
}

export { appInsights, reactPlugin }

