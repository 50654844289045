import React from 'react';
import clsx from 'clsx';

import styles from './AlignContent.module.css';

export interface AlignContentProps {
  /**
   * Horizontal align content
   */
  alignment?: 'left' | 'center' | 'right';

  /**
   * Horizontal align content
   */
  position?: 'top' | 'center' | 'bottom';

  textColor?: string;

  children: React.ReactNode;
}

export const AlignContent = ({
  alignment = 'left',
  position = 'top',
  textColor,
  children,
}: AlignContentProps) => {
  return (
    <div
      className={clsx(styles.alignContent, {
        [styles.alignmentLeft]: alignment === 'left',
        [styles.alignmentRight]: alignment === 'right',
        [styles.alignmentCenter]: alignment === 'center',
        [styles.positionTop]: position === 'top',
        [styles.positionCenter]: position === 'center',
        [styles.positionBottom]: position === 'bottom',
      })}
      style={{ color: textColor }}
    >
      {children}
    </div>
  );
};
