import { useInfiniteQuery } from "@tanstack/react-query";
import { CommerceModel } from 'api-types';
import { useAuthentication } from '~/shared/hooks';

import { useBasketPaths } from '~/components/features/Basket';

interface IProps {
    accountNumber?: string;
    skip: number;
    take: number;
}

export const useOrderHistory = ({ skip, take }: IProps) => {
    const { session } = useAuthentication();
    const { orderUrl } = useBasketPaths();

    const getUserInformationUrl = `${orderUrl}/historypaginated/`;

    const {
        data,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isLoading,
        isFetching,
        isError
    } = useInfiniteQuery<CommerceModel.PaginationModel>({
        queryKey: ["user order history paginated"],
        queryFn: async ({ pageParam = skip }) => {
            const res = await fetch(getUserInformationUrl + pageParam + "/" + take, {
                headers: {
                    'Authorization': `Bearer ${session?.accessToken}`
                }
            })
            return await res.json()
        },
        enabled: Boolean(session?.accessToken),
        keepPreviousData: true,
        getNextPageParam: (lastPage) => {
            const totalItems = lastPage.totalItems;
            const loadedItems = lastPage.skipItemsCount + lastPage?.items?.length;
            const noMorePages = loadedItems >= totalItems;

            if (noMorePages) {
                return undefined
            }

            return loadedItems || 0;
        },
        staleTime: 1000 * 60 * 60, // 1 hour
        cacheTime: 1000 * 60 * 60, // 1 hour        
    });

    const items = data?.pages?.map(p => p?.items).flat().filter(Boolean);
    const totalItems = data?.pages[0]?.totalItems;

    return {
        items,
        totalItems,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isLoading,
        isFetching,
        isError
    }
};
