interface IProps {
    displayName?: string;
    productId: string;
    variantId: string;
}

export const generatePDPLink = (props: IProps) => {

    const {
        displayName = 'product',
        productId,
        variantId
    } = props;

    const name = displayName.replace(/[./, ]/g, "-");
    return `/p/${encodeURIComponent(name)}/${productId}/${variantId}`
}