import { ProductFacetResult } from '@relewise/client'
import React, { FC } from 'react'
import { ActiveFacets } from './ActiveFacets/ActiveFacets'
import { FacetsFilter } from './FacetsFilter/FacetsFilter'
import styles from './Facets.module.css'

export interface IFacetsProps {
    relewiseFacets: ProductFacetResult | null;
    isFetching?: boolean;
    isLoading?: boolean;
    isPLP?: boolean;
    isMobile?: boolean;
}

const Facets: FC<IFacetsProps> = (props) => {


    return (
        <div className={styles.root}>
            <FacetsFilter {...props} />
            <ActiveFacets {...props} />
        </div>
    )
}

export default Facets