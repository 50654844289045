import React, { FC } from 'react'
import { Text } from 'ui';
import { useTranslations } from '~/services/cms';
import { CatalogueModel } from 'api-types';

interface IProps {
    values?: CatalogueModel.PriceTierOffer[];
}

const PriceOffers: FC<IProps> = ({ values = [] }) => {
    const translate = useTranslations()
    if (!values || values.length === 0) return null

    return (
        <>
            {values.map((val, index) => (
                <Text key={index} variant='bodyExtraSmall' color='natural700Color'>
                    {translate('product.priceOffers')
                        .replace('[minimumQuantity]', val?.minimumQuantity?.toString() || '')
                        .replace('[priceToDisplay]', val?.priceToDisplay?.toString() || '')
                    }
                </Text>
            ))}
        </>
    )
}

export default PriceOffers