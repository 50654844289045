import React from 'react';
import { Image } from 'ui';
import { useFrame } from '~/services/cms';
import { CmsModel } from 'api-types';

import styles from './PaymentIcons.module.css';
import clsx from 'clsx';

type Props = {
    center?: boolean;
};

export const PaymentIcons = ({ center }: Props) => {
    const { data: frame } = useFrame();

    return (
        <ul className={clsx(styles.paymentIcons, {
            [styles.center]: center
        })}>
            {frame?.footer?.paymentIcons?.map(
                ({ image, title }: CmsModel.FooterIcon, index: number) =>
                    image?.src && (
                        <li key={image.src + index}>
                            <Image
                                src={image.src}
                                title={title}
                                alt={title}
                                width={35}
                                height={24}
                                layout="intrinsic"
                                objectFit="contain"
                            />
                        </li>
                    )
            )}
        </ul>
    );
};
