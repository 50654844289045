import type { FrameKey } from './types';

export class FrameError extends Error {
  queryKeys: FrameKey;
  response: Response;

  constructor(response: Response, keys: FrameKey) {
    super();
    this.name = 'FrameError';
    this.message =
      'Request failed with status code 500 Internal Server Error on url ' + response.url;
    this.queryKeys = keys;
    this.response = response;
  }
}
