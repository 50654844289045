import React, { FC } from 'react'
import { FavoritesModel } from 'api-types';
import NextLink from 'next/link';
import BaseProductInfo from '~/components/shared/BaseProductInfo/BaseProductInfo';
import styles from './FavoriteCard.module.css'
// import { useTranslations } from '~/services/cms';
import AddToBasket from '../../Basket/components/AddToBasket/AddToBasket';
import AddFavoriteButton from '../AddFavoriteButton/AddFavoriteButton';
import { generatePDPLink } from '~/shared/utils';
import { CustomerPrice, IStatusCodeColor, StockStatus } from '~/components/features';

interface IProps {
    item: FavoritesModel.FavoriteListItem;

}

const FavoriteCard: FC<IProps> = ({ item }) => {
    // const translate = useTranslations()
    const {
        productId,
        name,
        jpGroupNumber,
        oldJPGroupNumber,
        packingUnit,
        // displayPrice,
        stock,
        trafficLight,
        expectedDate
    } = item

    const PDPLink = generatePDPLink({
        productId,
        variantId: productId,
        displayName: name || 'product'
    })

    return (
        <div className={styles.root}>
            <div className={styles.baseProductInfo}>
                <NextLink
                    passHref
                    aria-label={name}
                    title={name}
                    href={PDPLink}>
                    <a href={PDPLink} className={styles.productLink}>
                        <BaseProductInfo
                            imgSrc={item?.image}
                            productName={name}
                            jpGroupNumber={jpGroupNumber}
                            oeNumber={oldJPGroupNumber}
                        />
                    </a>
                </NextLink>
            </div>
            <div className={styles.stockStatus}>
                <StockStatus
                    statusCode={trafficLight as IStatusCodeColor}
                    productId={productId}
                    amount={stock}
                    expectedDeliveryDate={expectedDate}
                />
            </div>
            <div className={styles.price}>
                <CustomerPrice
                    variantId={productId as string}
                    packingUnit={packingUnit || 1}
                // unit={data?.Unit?.value}
                />
            </div>
            <div className={styles.addToBasket}>
                <AddToBasket
                    small
                    productId={productId}
                    variantId={productId}
                    colli={Number(packingUnit || 1)}
                    hideButtonText />
            </div>
            <div className={styles.addFavorite}>
                <AddFavoriteButton productId={productId} hideText />
            </div>

        </div>
    )
}

export default FavoriteCard