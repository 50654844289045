import { useGetRequest } from '~/shared/utils';
import { AccountModel } from 'api-types';
import { isBrowser } from 'utils';
import { useAuthentication } from '~/shared/hooks';

export const useUserInformation = () => {
    const { session } = useAuthentication();

    const prefixUrl = new URL(
        isBrowser ? '/api/account/proxy' : '/api',
        isBrowser ? window.location.origin : process.env.COMMERCE_API_URL
    );

    const getUserInformationUrl = `${prefixUrl}/${session?.storeKey}/Account/get`;

    return {
        ...useGetRequest<AccountModel.Customer>(getUserInformationUrl, {
            enabled: Boolean(session?.accessToken),
            retry: 2,
            keepPreviousData: true,
            headers: {
                'Authorization': `Bearer ${session?.accessToken}`
            }
        })
    };
};
