import { putRequest, deleteRequest } from '~/shared/utils';
import { CommerceModel } from 'api-types';

export async function updateItem(variantId: string, quantity: number, apiUrl: string, token?: string) {
    const request = quantity <= 0
        ? await deleteRequest<CommerceModel.Basket>(apiUrl, {
            variantId,
            quantity: 0,
        }, { 'Authorization': `Bearer ${token}` })
        : await putRequest<CommerceModel.Basket>(apiUrl, {
            variantId,
            quantity,
        }, { 'Authorization': `Bearer ${token}` })

    const response = await request();

    return response;
}
