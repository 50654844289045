import React, { FC, useState, memo } from 'react';
import { InputStepper, Button, IconTypes } from 'ui';
import styles from './AddToBasket.module.scss';
import { useTranslations } from '~/services/cms';
import { useBasketAdd } from '$features/Basket/hooks/useBasketAdd';
import clsx from 'clsx';
import { isJPG } from 'utils';

const isJPGroup = isJPG()

const closestVal = (value: number, colli = 1): number => {
    const leftover = value % colli;
    if (leftover === 0) return value;
    return value + (colli - leftover);
};

interface IProps {
    isFlexible?: boolean;
    productId: string;
    variantId: string;
    small?: boolean;
    buttonText?: string;
    iconName?: IconTypes;
    defaultValue?: number,
    colli?: number;
    hideButtonText?: boolean;
    className?: string;
}


const AddToBasket: FC<IProps> = ({
    productId, variantId,
    small, buttonText, iconName,
    defaultValue,
    hideButtonText,
    colli,
    isFlexible,
    className
}) => {

    // if HOW ignore colly and use 1
    const defaultQuantity = isJPGroup
        ? defaultValue || colli || 1
        : 1
    const colliVal = isJPGroup ? colli : 1


    const [quantity, setQuantity] = useState<number>(defaultQuantity);
    const translate = useTranslations();
    const { add } = useBasketAdd();

    const addToBasket = async () => {
        const closestQuantity = closestVal(quantity, colliVal);
        setQuantity(closestQuantity);
        if (productId) {
            await add({
                productId,
                quantity: closestQuantity,
            });
        }
    };

    return (
        <div className={clsx(styles.root, className)}>
            <InputStepper
                inputName={`${productId}${variantId}`}
                colli={colliVal}
                defaultValue={quantity}
                small={small}
                onChangeInput={(val) => setQuantity(val)}
                onChange={(val) => setQuantity(val)}
            />
            <Button
                aria-label="Add to basket value"
                isFlexible={isFlexible}
                onClick={addToBasket}
                size={small ? 'small' : 'medium'}
                iconName={iconName || 'shopping-cart'}
            >
                {!hideButtonText && (buttonText || translate('productPage.addToBasket.text'))}
            </Button>
        </div>
    );
};

export default memo(AddToBasket);