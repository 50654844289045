import { queries } from '../../../queries';
import type { PrefetchShared } from '../../../types';
import { getFrame } from './get-frame';

export async function prefetchFrame({ locale, queryClientPage, queryClientShared }: Props) {
  const localeHandled = locale === 'default' ? 'da' : locale;

  const key = queries.cms.frame(localeHandled);

  await queryClientShared.fetchQuery(key, getFrame);

  return queryClientPage.setQueryData(key, () => queryClientShared.getQueryData(key), {
    updatedAt: queryClientShared.getQueryState(key)?.dataUpdatedAt,
  });
}

interface Props extends PrefetchShared {
  locale: string;
}
