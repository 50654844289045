import type { QueryFunctionContext } from '@tanstack/react-query';
//@ts-ignore
import { HTTPError } from 'ky';
import { cmsApi } from '../../cms-api';
import { TranslationsError } from './errors';
import type { Translations, TranslationsKey } from './types';
import { getHostKeyParam } from "utils";

const exceptions = {
  500: TranslationsError,
};

export function getTranslations(context: QueryFunctionContext<TranslationsKey>) {
  const { signal, queryKey } = context;
  const [, , { locale }] = queryKey;
  const localeHandled = locale === 'default' ? 'da' : locale;

  return cmsApi
    .get(`content/translations/${localeHandled}?${getHostKeyParam()}`, {
      signal,
      throwHttpErrors: false,
      hooks: {
        afterResponse: [
          (request, options, response) => {
            if (!response.ok) {
              const HTTPException = exceptions[response.status as keyof typeof exceptions];

              if (HTTPException) {
                throw new HTTPException(response, queryKey);
              }

              throw new HTTPError(response, request, options);
            }

            return response;
          },
        ],
      },
    })
    .json<Translations>();
}
