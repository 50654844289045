import { fetcher } from './fetcher';

/**
 * Simpler version of a POST request, supplies content type and serializes body to json
 */
export const postRequest = <ResponseBody = unknown, RequestBody = unknown>(
    url: string,
    body?: RequestBody,
    headers: HeadersInit = {},
    isForm: boolean = false
) => {
    const opts: RequestInit = {
        method: 'POST'
    };

    if (body && !isForm) {
        opts['body'] = JSON.stringify(body);
    }

    if (body && isForm) {
        //@ts-ignore
        opts['body'] = body;
    }

    if (!isForm) {
        opts.headers = { 'Content-Type': 'application/json; charset=utf-8', ...headers };
    } else {
        opts.headers = { ...opts.headers, ...headers };
    }

    return fetcher<ResponseBody>(url, opts);
};
