import styled from '@emotion/styled';
import { switchProp } from 'styled-tools';

interface PriceProps {
    offer: 'none' | 'before' | 'new';
}

export const StyledPriceWrap = styled.div(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    margin: `0 -${theme.spaces[1]} ${theme.spaces[1]} -${theme.spaces[1]}`,
    fontSize: theme.fontSizes.xs,
    letterSpacing: '0.5px',
    lineHeight: theme.lineHeights.headline,
}));

export const StyledPrice = styled.div<PriceProps>(
    ({ theme }) => ({
        position: 'relative',
        fontSize: theme.fontSizes['2xs'],
        margin: `0 ${theme.spaces[1]}`,
        lineHeight: '14px',
    }),

    switchProp(
        'offer',
        {
            none: ({ theme }) => ({
                alignItems: 'flex-start',
                color: theme.colors.black,
            }),
            before: ({ theme }) => ({
                textDecoration: 'line-through',
                color: theme.colors.grey50,
            }),
            new: ({ theme }) => ({
                fontWeight: theme.fontWeights.bold,
                color: theme.colors.red50,
            }),
        },
        'none'
    )
);
