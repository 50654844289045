import React, { useEffect } from 'react';
import { useAnimation, AnimatePresence, PanInfo } from 'framer-motion';
import {
    DrawerWrapper,
    DrawerOverlay,
    DrawerContent,
    DrawerHeader,
    DrawerCloseButton,
} from './styled';
import { useTheme } from '@emotion/react';
import { useMedia } from 'react-use';
import { queries } from '../../../theme';
import { useScrollLock } from 'utils';
import { SvgIcon } from '../../../';

export type DrawerProps = {
    children?: React.ReactNode;
    direction: 'left' | 'right';
    onCloseHandler: () => void;
    isOpen: boolean;
    staticHeader?: boolean;
    header?: React.ReactNode;
};

export const Drawer = ({
    children,
    direction,
    onCloseHandler,
    isOpen,
    staticHeader,
    header,
}: DrawerProps) => {
    const constrols = useAnimation();
    const theme = useTheme();
    const { lock, unlock } = useScrollLock();

    useEffect(() => {
        isOpen ? lock() : unlock();
        constrols.start(isOpen ? 'active' : 'inactive');
    }, [isOpen, constrols]);

    const isMobile = useMedia(queries.xs, false);
    const drawerWidth = isMobile ? theme.sizes.mobileDrawerWidth : theme.sizes.drawerWidth;
    const activeX = direction == 'left' ? 0 : `calc(100vw - ${drawerWidth}px)`;
    const inactiveX = direction == 'left' ? -drawerWidth : '100vw';

    const drawerContentStyles = {
        active: {
            x: activeX,
        },
        inactive: {
            x: inactiveX,
        },
    };

    const closeDrawer = () => {
        constrols.start('inactive');
        unlock();
        onCloseHandler();
    };

    return (
        <DrawerWrapper>
            <AnimatePresence>
                {isOpen && (
                    <DrawerOverlay
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 0.3 }}
                        exit={{ opacity: 0 }}
                        onClick={closeDrawer}
                    />
                )}
            </AnimatePresence>

            <DrawerContent
                initial={{ x: isOpen ? activeX : inactiveX }}
                animate={constrols}
                variants={drawerContentStyles}
                transition={{ type: 'easeInOut', duration: 0.7 }}
                drag="x"
                dragElastic={0.3}
                dragMomentum={false}
                onDragEnd={(_: (MouseEvent | TouchEvent | PointerEvent), info: PanInfo) => {
                    const multiplier = 1 / 4;
                    const threshold = drawerWidth * multiplier;
                    const isDraggingLeft = info.offset.x < 0;
                    const isDraggingRight = info.offset.x > 0;

                    if (
                        Math.abs(info.offset.x) > threshold &&
                        isDraggingLeft &&
                        direction == 'left'
                    ) {
                        closeDrawer();
                    } else if (
                        Math.abs(info.offset.x) > threshold &&
                        isDraggingRight &&
                        direction == 'right'
                    ) {
                        closeDrawer();
                    } else {
                        isOpen ? lock() : unlock();
                        constrols.start(isOpen ? 'active' : 'inactive');
                    }
                }}
            >
                <DrawerHeader isStatic={staticHeader}>
                    {header}
                    <DrawerCloseButton
                        shape={'icon'}
                        variant={'tertiary'}
                        onClick={closeDrawer}
                        direction={direction}
                    >
                        <SvgIcon name="close" aria-hidden="true" />
                    </DrawerCloseButton>
                </DrawerHeader>
                {children}
            </DrawerContent>
        </DrawerWrapper>
    );
};
