import React, { FC } from 'react';
import { usePopperTooltip, Config as IPopperConfig } from 'react-popper-tooltip';
import clsx from 'clsx';
import styles from './Tooltip.module.css';
import 'react-popper-tooltip/dist/styles.css';
import { SvgIcon } from '../../index';
import { createPortal } from 'react-dom';


interface IWrapperProps {
    className?: string;
    children?: React.ReactNode;
}

export const TooltipContentWrapper: FC<IWrapperProps> = (
    { className, children }
) => (
    <span className={clsx(styles.contentWrapper, className)}>
        {children}
    </span>
);

interface IProps extends IPopperConfig {
    className?: string;
    content: React.ReactNode;
    children?: React.ReactNode;
}

export const Tooltip: FC<IProps> = ({
    content, children, className, placement = 'top', ...rest
}) => {

    const {
        getArrowProps,
        getTooltipProps,
        setTooltipRef,
        setTriggerRef,
        visible
    } = usePopperTooltip({ placement, ...rest });

    return (
        <>
            <span ref={setTriggerRef}
                className={clsx(styles.trigger, className)}
            >
                {children || <SvgIcon className={styles.fallbackIcon} name="info" />}
            </span>
            {visible && createPortal(
                <div
                    ref={setTooltipRef}
                    {...getTooltipProps({
                        className: styles.content
                    })}
                >
                    {content}
                    <div {...getArrowProps()} />
                </div>,
                document.body
            )}
        </>
    );
};
