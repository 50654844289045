import React from 'react';
import { ModuleHeader } from 'ui';
import { useTranslations } from '~/services/cms';
import styles from './OrderHistory.module.css';
import { Accordion, AccordionItem, Text, Loader } from 'ui';
import { LoadMore } from '~/components/features';
import { OrderHistoryList } from '../OrderHistoryList/OrderHistoryList';
import { useOrderHistory, useUserInformation } from '../../hooks';
import ErrorBox from '~/components/shared/ErrorBox/ErrorBox';


const Content = () => {
    const translate = useTranslations();

    const { isLoading: isUserInfoLoading } = useUserInformation();

    const {
        items: orderHistoryItems,
        totalItems,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isLoading: isLoadingOrderHistory,
        isFetching,
        isError
    } = useOrderHistory({ skip: 0, take: 5 });

    const isLoading = isUserInfoLoading || isLoadingOrderHistory;

    if (isLoading) return <Loader />
    if (isError) return <ErrorBox />

    if (!orderHistoryItems?.length) {
        return (
            <Text variant="body" textAlign='center'>
                {translate('profile.noOrders')}
            </Text>
        )
    }

    return (
        <>
            <span className={styles.headerText}>
                <Text className={styles.headerDate} variant="display5">
                    {translate('profile.orderDate')}
                </Text>
                <Text variant="display5">{translate('profile.orderNumber')}</Text>
            </span>
            <Accordion>
                {orderHistoryItems?.map(order => {
                    return (
                        <AccordionItem key={order.orderNumber} header={
                            <span className={styles.orderHeader}>
                                <Text variant="body" className={styles.headerDate}>
                                    {order.orderDate}
                                </Text>
                                <Text variant="body">{order.orderNumber}</Text>
                            </span>
                        }>
                            <OrderHistoryList {...order} />
                        </AccordionItem>
                    )
                })}
                <LoadMore
                    total={totalItems}
                    isFetching={isFetching && isFetchingNextPage}
                    loaded={orderHistoryItems?.length || 0}
                    hasNextPage={hasNextPage}
                    onClick={fetchNextPage} />
            </Accordion>
        </>
    )
}

export const OrderHistory = () => {
    const translate = useTranslations();

    return (
        <>
            <ModuleHeader headline={translate('profile.myOrders')} variant="display3" />
            <Content />
        </>
    );
};
