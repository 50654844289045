import React, { FC } from 'react';
import styles from './Loader.module.css';
import clsx from 'clsx';

interface IProps {
  fixed?: boolean;
  size?: string;
  borderWidth?: string;
  color?: 'primary' | 'secondary' | 'grey';
  padding?: string;
  width?: string;
  className?: string;
}

export const Loader: FC<IProps> = ({
  fixed,
  className,
  size = '50px',
  borderWidth = '5px',
  // color = 'primary',
  padding = '10px',
  width = '100%',
}) => {
  const wrapperStyles = {
    padding,
    width,
  };

  const wrapperClassName = clsx(fixed ? styles.wrapperFixed : styles.wrapper, className);

  const loaderStyles = {
    width: size,
    height: size,
    borderWidth: borderWidth,
  };

  return (
    <div className={wrapperClassName} style={wrapperStyles}>
      <div className={styles.loader} style={loaderStyles} />
    </div>
  );
};

export default Loader;
