import { Button } from '../Buttons';
import { Text } from '../Text';
import styles from './Modal.module.css'

export type ModalProps = {
    children?: string;
    setIsOpen?: any;
}
export const Modal = ({ setIsOpen, children }: ModalProps) => {
    return (
        <div onClick={() => setIsOpen(false)} className={styles.container}>
            <Text variant="bodyExtraSmall" className={styles.consentText}>{children} </Text>
            <Button variant='link' onClick={() => setIsOpen(false)}>Back</Button>
        </div>
    );
};

export default Modal;
