import styled from '@emotion/styled';
import { prop } from 'styled-tools';
import { breakpoints } from '$theme';

export const StyledSummaryItem = styled.div(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.colors.white,
    gap: theme.spaces[3],
    padding: `${theme.spaces[3]} 0`,

    [breakpoints.md]: {
        padding: `${theme.spaces[3]} ${theme.spaces[4]}`,
    },
}));

export const StyledSummaryContent = styled.div<{ justifySelf?: string }>(({ theme }) => ({
    display: 'flex',
    justifySelf: `${prop('justifySelf', 'flex-start')}`,
    flexDirection: 'column',
    '&:last-child': {
        margin: `auto 0 ${theme.spaces[1]} auto`,
        alignItems: 'end',
        gap: theme.spaces[3],
    },
}));

export const StyledSummaryItemLink = styled.a({
    textDecoration: 'none',
});
