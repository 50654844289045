import { fetcher } from '~/shared/utils/request/utils/fetcher';

/**
 * Simpler version of a GET request, supplies content type
 */
export const getRequest = <ResponseBody = unknown>(url: string, headers: HeadersInit = {}) => {
    const opts: RequestInit = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            ...headers
        },
    };

    return fetcher<ResponseBody>(url, opts);
};
