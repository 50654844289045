import React, { memo } from 'react';
import clsx from 'clsx';
import styles from './Skeleton.module.css';
// import { isSSR } from '~/shared/utils';



// Usage: const skeletonWidth = useMemo(() => `${getSkeletonRandom(80)}px`, [])
export const getSkeletonRandom = (maxWidth: number) => {
    // if (isSSR) return maxWidth;

    const minWidth = maxWidth * 0.4;
    const randomValue = Math.random();
    const randomNumber = Math.floor(randomValue * minWidth);

    return randomNumber + (maxWidth - minWidth);
};

interface ISkeleton {
    width: string;
    height: string;
    className?: string;
    variant?: 'light' | 'darker';
    children?: React.ReactNode
}

export const Skeleton: React.FC<ISkeleton> = memo(({
    children, width, height, className, variant = 'light'
}) => (
    <div
        className={clsx(className, styles[variant])}
        style={{ width, height }}
    >
        {children}
    </div>
));