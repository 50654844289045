import clsx from 'clsx';
import React from 'react';
import { Button, ModuleHeader, Text, Loader } from 'ui';
import { signOut } from 'next-auth/react';
import styles from './OverviewPage.module.css';
import { useRouter } from 'next/router';
import { useUserInformation } from '../../hooks';
import { useTranslations } from '~/services/cms';
import { useSessionStorage } from 'react-use';
import { Fields } from '$features/Checkout/models';
import { useBasket, useBasketState } from '~/components/features/Basket';
import DownloadCustomerPrices from '~/components/features/DownloadCustomerPrices/DownloadCustomerPrices';

export const OverviewPage = () => {
    const { locale, push } = useRouter();
    const translate = useTranslations();
    const { setPaymentOption } = useBasketState()
    const { deleteBasket } = useBasket();

    const [, setShippingFormFields] = useSessionStorage<Fields | null>('shippingFormFields', {});
    const [, setFormFields] = useSessionStorage<Fields | null>('formFields', {});
    const [, setPaymentFormFields] = useSessionStorage<Fields | null>('paymentFormFields', {});
    const [, setBasketFields] = useSessionStorage<Fields | null>('basketFields', {});
    const [, setInvoiceFields] = useSessionStorage<Fields | null>('invoiceFields', {});

    const {
        data: userInformation,
        isLoading: isUserInfoLoading
    } = useUserInformation();

    const logOut = () => {
        setShippingFormFields({});
        setFormFields({});
        setPaymentFormFields({});
        setBasketFields({});
        setInvoiceFields({});
        setPaymentOption('payNow')
        deleteBasket()
        signOut({ redirect: false });
        push(`/${locale}`);
        //window.location.href = `/${locale}`
    }

    const downloadName = `${userInformation?.accountNumber || ''}-${userInformation?.firstname || ''}`

    return (
        <>
            <div className={styles.overviewHeader}>
                <ModuleHeader headline={translate('profile.myOverview')} variant="display3">
                    <Button
                        variant='link'
                        size='large'
                        className={styles.logoutButton}
                        iconName='login'
                        onClick={logOut}
                        children={
                            <Text className={styles.logoutText} variant='bodySmall'>
                                {translate('auth.logout')}
                            </Text>
                        } />
                </ModuleHeader>
            </div>
            <div className={styles.flexWrapper}>
                {isUserInfoLoading ? <div className={styles.resWrapper}>
                    <Loader />
                </div> : null}
                {!isUserInfoLoading ? (
                    <>
                        <div className={styles.saldoWrapper}>
                            <div>
                                <Text variant="display3" className={styles.gridHeadline}>
                                    {translate('profile.balances')}
                                </Text>
                                <Text variant="bodySmall">{translate('profile.balance')} </Text>
                                <Text variant="bodySmall">
                                    {userInformation?.balance}
                                </Text>
                            </div>
                            <DownloadCustomerPrices fileName={downloadName} />
                        </div>
                        <div className={clsx([styles.overviewWrapper])}>
                            <ModuleHeader headline={translate('profile.myContacts')} variant="display3" />
                            <span className={styles.contact}>
                                {/* TODO remove image if not needed */}
                                {/* <img
                            src="https://www.impact.dk/wp-content/uploads/2018/02/christian-lindhardt-andersen-impact-450x350.jpg"
                            width="100px"
                            height="80px"
                        /> */}
                                <div>
                                    <Text as="h2">{`${userInformation?.firstname || ''} ${userInformation?.lastname || ''}`}</Text>
                                    <Text style={{ fontStyle: 'italic' }}>{userInformation?.billingAddress?.street}</Text>
                                    <Text style={{ fontStyle: 'italic' }}>{userInformation?.billingAddress?.zipCode} {userInformation?.billingAddress?.city}</Text>
                                    <Text style={{ fontStyle: 'italic' }}>{userInformation?.billingAddress?.country}</Text>
                                    <Text>{userInformation?.email}</Text>
                                    <Text>{userInformation?.phone}</Text>
                                </div>
                            </span>
                        </div>
                    </>
                ) : null}
            </div>
        </>
    );
};
