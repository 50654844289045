import { createQueryKeys } from '@lukemorales/query-key-factory';
export const globalizationQueries = createQueryKeys('globalization', {
  markets: null,
});

declare global {
  export interface Window {
    // Cookieinformation globals
    Cookiebot?: {
      consent: {
        necessary: boolean;
        preferences: boolean;
        statistics: boolean;
        marketing: boolean;
        method?: string;
      };
      consented?: boolean;
      declined?: boolean;
      hasResponse?: boolean;
      doNotTrack?: boolean;

      regulations: {
        gdprApplies?: boolean;
        ccpaApplies?: boolean;
        lgpdApplies?: boolean;
      };
    };
    CookieConsent?: {
      renew: () => void;
    };
  }
}
