import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { useMarkets as useServicesMarkets } from 'services';
import locale from '~/constants/locale.mjs';
import { useAuthentication } from '~/shared/hooks';


const fallbackLocale = locale.defaultLocale;

export function useMarkets() {
    const { locale, defaultLocale = fallbackLocale } = useRouter();
    const { isAuthenticated, session } = useAuthentication()
    const { data } = useServicesMarkets();

    const getStoreMarket = (store: string) => {
        return data?.markets?.find(market => {
            return market.storeKey === store
        });
    }

    const currentMarket = useMemo(() => {
        if (isAuthenticated && session?.storeKey) {
            return getStoreMarket(session.storeKey);
        } else {
            return data?.markets?.find(market => market.isDefaultStore === true);
        }
    }, [data, isAuthenticated, session]);

    return {
        markets: data?.markets || [],
        currentMarket,
        getStoreMarket,
        locale: currentMarket?.languages.find(language => language.languageCode === locale) || defaultLocale
    };
}
