// Filetype ESM so that next.config.mjs can access it

const defaultLocale = 'default';
if (process.env.NEXT_PUBLIC_INTL_LOCALES === undefined) {
    throw new Error('ENV Variables NEXT_PUBLIC_INTL_LOCALES should be defined.');
}

const envLocales = process.env.NEXT_PUBLIC_INTL_LOCALES.replace(/ /g, '').split(',');

const locales = [defaultLocale, ...envLocales]; // List of languages that are supported List of languages that are supported
const localeDetection = false;

const config = { defaultLocale, locales, localeDetection }
export default config