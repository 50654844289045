import React from 'react';
import { ModuleHeader, Loader, Text } from 'ui';
import styles from './WatchList.module.css';
import { useTranslations } from '~/services/cms';
import { useWatchlist } from '../../hooks';
import ErrorBox from '~/components/shared/ErrorBox/ErrorBox';
import FavoriteCard from '~/components/features/Favorites/FavoriteCard/FavoriteCard';
import { RelewiseProduct } from '~/components/features/ProductsList/hooks';


interface WatchListProps {
    products?: RelewiseProduct[];
}
export const WatchList: React.FC<WatchListProps> = () => {
    const translate = useTranslations();
    const { data: watchList, isLoading, isError } = useWatchlist();
    const header = <ModuleHeader headline={translate('profile.stockWatchlist')} variant="display3" />;

    if (isLoading) {
        return (
            <>
                {header}
                <Loader />
            </>
        )
    }

    if (isError) return (
        <>
            {header}
            <ErrorBox />
        </>
    )


    if (watchList && !watchList.items?.length) return (
        <>
            {header}
            <Text >
                {translate('profile.emptyWatchlist')}
            </Text>
        </>
    )

    return (
        <div className={styles.list}>
            {header}
            {watchList?.items?.map((item, i) => (
                <FavoriteCard item={item} key={`${item?.productId}-${i}`} />
            ))}
        </div>
    );
};