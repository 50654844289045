import { queries } from '../../../queries';
import type { Prefetch } from '../../../types';
import { getPage } from './get-page';
import type { PageErrors, Page } from './types';

export function prefetchPage({ locale, queryClientPage, url }: Props) {
  const localeHandled = locale === 'default' ? 'da' : locale;
  //@ts-ignore
  return queryClientPage.fetchQuery<Page, PageErrors>(queries.cms.page(localeHandled, url), getPage);
}

interface Props extends Prefetch {
  url: string;
  locale: string;
}
