import * as colors from './colors';
import { getClampFullRange } from './utils/getClampValue';

/** Import locally hosted fonts */
// import { MyriadProWoff } from './fonts';

export type ThemeType = typeof theme;

declare module '@emotion/react' {
    export interface Theme extends ThemeType { }
}

export const baseFontSize = 16; // By default the html base size

export const theme = {
    colors,
    fonts: {
        headline: 'Helvetica, Arial, sans-serif',
        body: 'Helvetica, Arial, sans-serif',
        mono: 'monospace',
    },
    aspectRatios: {
        imageProduct: '2475 / 3262',
        imageModel: '2 / 3',
    },
    fontWeights: {
        regular: 400,
        bold: 700,
    },
    lineHeights: {
        single: '1',
        compact: '1.15',
        base: '1.5',
        headline: '1.1',
    },
    fontSizes: {
        '2xs': '12px',
        xs: '14px',
        sm: '15px',
        md: '18px',
        lg: '20px',
        xl: '25px',
        '2xl': '30px',
        '3xl': '35px',
        '4xl': '40px',
        '5xl': '45px',
        '6xl': '50px',
        '7xl': '55px',
    },
    spaces: {
        '1': '5px',
        '2': '10px',
        '3': '15px',
        '4': '20px',
        '5': '30px',
        '6': '40px',
        '7': '50px',
        '8': '60px',
        '9': '70px',
        '10': '80px',
        gutter: 'min(6vw, 115px)',
    },
    sizes: {
        // Limit content
        contentMaxWidth: 1690,
        headerHeightDesktop: 70,

        // Limit page width. Background colors etc.
        pageMaxWidth: 1690,

        // Header checkout
        headerHeightCheckout: 93,
        checkoutMinHeaderHeight: 130,

        content: 'min(88vw, 1690px)',

        // Default Width for side Drawer
        drawerWidth: 480,
        mobileDrawerWidth: 300,

        // Search bar height
        searchHeight: 46,
    },
    gridConfig: {
        columnCount: {
            xs: 6,
            md: 12,
        },
        gap: getClampFullRange(24, 32),
        gutter: getClampFullRange(20, 96),
    },
    zIndices: {
        '1': 100,
        '2': 200,
        '3': 300,
        '4': 400,
        max: 999,
    },
    easings: {
        smoothEase: 'cubic-bezier(0.35, 0, 0.15, 1)',
    },
};
