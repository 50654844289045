import clsx from 'clsx';
import { FC, AnchorHTMLAttributes, memo, forwardRef } from 'react'
import styles from './Link.module.css'
import { IconTypes, SvgIcon } from '../../index';

interface IProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
    size?: 'medium' | 'large';
    underline?: boolean;
    disabled?: boolean;
    iconName?: IconTypes;
    iconPosition?: 'left' | 'right';
    iconClassName?: string;
    ref?: React.ForwardedRef<HTMLAnchorElement>;
    linkType?: string;
}

export const Link: FC<IProps> = memo(forwardRef(({
    children, size = 'medium',
    underline, className, disabled,
    iconName, iconPosition = 'left', iconClassName,
    // @ts-ignore
    linkType,
    ...rest
}, ref) => {


    const sharedStyles = clsx(
        styles.root,
        styles[size],
        className,
        {
            [styles.underline]: underline
        }
    )


    const LinkContent = () => (
        <>
            {iconName && (
                <SvgIcon
                    name={iconName}
                    className={clsx(styles.icon, iconClassName, {
                        [styles.iconRight]: iconPosition === 'right'
                    })} />
            )}
            {children}
        </>
    )

    if (disabled || !rest.href) {
        return (
            <span {...rest} className={clsx(sharedStyles, styles.inactiveLink)}>
                <LinkContent />
            </span>
        )
    }

    return (
        <a ref={ref} {...rest}
            className={clsx(sharedStyles, styles.link)}>
            <LinkContent />
        </a>
    )
}));