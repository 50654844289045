import { inferQueryKeyStore } from '@lukemorales/query-key-factory';
import type { QueryClient } from '@tanstack/react-query';
import type { HTTPError, TimeoutError } from 'ky-universal';
import { queries } from './queries';

// Generic
export type Queries = inferQueryKeyStore<typeof queries>;

export type FetchError = HTTPError | TimeoutError;

export interface Prefetch {
  queryClientPage: QueryClient;
}

export interface PrefetchShared extends Prefetch {
  queryClientShared: QueryClient;
}

// CMS
export * as CmsModel from './cms/types';
export * as CmsErrors from './cms/errors';

// Globalization
export * as GlobalizationModel from './globalization/types';
export * as GlobalizationErrors from './globalization/errors';

// Catalogue
export * as CatalogueModel from './catalogue/types';
export * as CatalogueErrors from './catalogue/errors';
