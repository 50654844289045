import getConfig from 'next/config';

type PublicConfigType = {
    API_URL: string;
    COMMERCE_API_URL: string;
    LICENSE_PLATE_API_URL: string;
    APPINSIGHTS_CONNECTION_STRING: string;
    IMAGE_CDN_URL: string;
    HOSTNAME_URL: string;
    HOSTNAME_KEY: string;
    APPINSIGHTS_INSTRUMENTATIONKEY: string;
    RELEWISE_KEY: string;
    RELEWISE_DATASET: string;
};

const publicRuntimeConfig: PublicConfigType = getConfig()?.publicRuntimeConfig;

export const API_URL = publicRuntimeConfig?.API_URL;
export const COMMERCE_API_URL = publicRuntimeConfig?.COMMERCE_API_URL;
export const LICENSE_PLATE_API_URL = publicRuntimeConfig?.LICENSE_PLATE_API_URL;
export const APPINSIGHTS_INSTRUMENTATIONKEY = publicRuntimeConfig?.APPINSIGHTS_INSTRUMENTATIONKEY;
export const APPINSIGHTS_CONNECTION_STRING = publicRuntimeConfig?.APPINSIGHTS_CONNECTION_STRING;
export const IMAGE_CDN_URL = publicRuntimeConfig?.IMAGE_CDN_URL;
export const HOSTNAME_URL = publicRuntimeConfig?.HOSTNAME_URL;
export const HOSTNAME_KEY = publicRuntimeConfig?.HOSTNAME_KEY;
export const RELEWISE_KEY = publicRuntimeConfig?.RELEWISE_KEY;
export const RELEWISE_DATASET = publicRuntimeConfig?.RELEWISE_DATASET;

