import { QueryCache, QueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { minutes } from 'utils';

// Should only be used in _app file
export const useQueryClient = () => {
  const [queryClientState] = useState(
    // This state ensures that data is not shared between different users and requests,
    // while still only creating the QueryClient once per component lifecycle.
    () => {
      const queryClient = new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false, // Disabled since we don't rely on realtime data
            staleTime: 0,
            cacheTime: minutes(5),
          },
        },
        queryCache: new QueryCache({
          // Global callback for successful queries
          // onSuccess: (query, result) => {},
          // Global callback for failed queries
          // onError: (error, query) => {k
          //   Usage:
          //   if (hashQueryKey(query.queryKey) === hashQueryKey(authKeys.user())) {}
          // }
        }),
      });

      return queryClient;
    }
  );

  return queryClientState;
};
