import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { ifProp } from 'styled-tools';
import { breakpoints } from '../../../theme';
import { ButtonDeprecated } from '../Button/Button';

export const DrawerWrapper = styled.div(({ theme }) => ({
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    pointerEvents: 'none',
    zIndex: theme.zIndices.max,
}));

export const DrawerOverlay = styled(motion.div)(({ theme }) => ({
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundColor: theme.colors.black,
    pointerEvents: 'all',
    zIndex: 0,
}));

export const DrawerContent = styled(motion.div)(({ theme }) => ({
    position: 'relative',
    zIndex: 1,
    pointerEvents: 'all',
    backgroundColor: theme.colors.white,
    height: '100vh',
    width: `${theme.sizes.drawerWidth}px`,
    boxSizing: 'border-box',
    [breakpoints.xs]: {
        width: `${theme.sizes.mobileDrawerWidth}px`,
    },
}));

export const DrawerHeader = styled.div<{ isStatic?: boolean }>(
    () => ({
        top: 0,
        right: 0,
        width: '100%',
    }),
    ifProp('isStatic', () => ({
        position: 'static',
    }))
);

export const DrawerCloseButton = styled(ButtonDeprecated)<{ direction: 'left' | 'right' }>(
    ({ direction }) => ({
        position: 'absolute',
        top: 0,
        left: direction == 'left' ? 'auto' : 0,
        right: direction == 'left' ? 0 : 'auto',
    })
);
