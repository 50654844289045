import React, { useImperativeHandle, useRef, useState } from 'react';
import { useInputField } from '../hooks/useInputField';
import { HelpText, InvalidMessage } from '../HelpText';

import clsx from 'clsx';
import styles from './InputField.module.css';

type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

export type InputFieldProps = Omit<InputProps, 'value'> & {
    /**
     * Adds a label to the input field. This is required for accessibilty.
     */
    label?: string;

    /**
     * Add an additional help text below the input field.
     */
    helpText?: string;

    /**
     * Add an additional help text below the input field.
     */
    invalidMessage?: string;

    /**
     * Set styling to indicate input is invalid.
     * Also shows the `invalidMessage` if provided
     */
    isInvalid?: boolean;

    /**
     * This component is uncontrolled - you will need to controll the value.
     */
    value?: string | number;

    /**
     * When active the label will move up above the actual input field
     */
    isActive?: boolean;

    /**
     * Append a string or a component to the input field
     */
    append?: React.ReactNode;
};

export const InputField = React.forwardRef<HTMLInputElement, InputFieldProps>(
    (
        { label, helpText, invalidMessage, id, children, isActive, isInvalid, append, ...rest },
        ref
    ) => {
        const [isValidityValid, setIsValidityValid] = useState(true);
        const [hasFocus, setHasFocus] = useState(false);
        const inputRef = useRef<HTMLInputElement>(null);
        const inputFieldRef = useRef<HTMLDivElement>(null);

        const {
            fieldId,
            labelId,
            helpTextId,
            invalidMessageId,
            describedById,
            showHelpText,
            showInvalidMessage,
        } = useInputField({
            id,
            helpText,
            isInvalid,
            invalidMessage,
        });

        const { value, defaultValue } = rest;

        const hasValue = value === undefined ? !!defaultValue : !!value;
        const isInputActive = isActive || hasFocus || hasValue || !!inputRef?.current?.value?.length;
        const isValid = !showInvalidMessage && isValidityValid;

        const onFocusHandler = () => setHasFocus(true);
        const onBlueHandler = () => {
            setHasFocus(false);
            setIsValidityValid(inputRef.current?.validity.valid ? true : false);
        };

        const onInputFieldClick = (event: React.MouseEvent) => {
            if (inputRef.current !== event.target) {
                inputRef.current?.focus();
                inputRef.current?.click();
                event?.preventDefault();
                event?.stopPropagation();
            }
        };

        useImperativeHandle(ref, () => inputRef.current as HTMLInputElement, [ref]);

        return (
            <div>
                <div className={clsx(styles.inputFieldWrapper)}
                    key={fieldId}
                    onFocus={onFocusHandler}
                    onBlur={onBlueHandler}
                >
                    <div className={clsx(styles.inputField)} onMouseDown={onInputFieldClick} ref={inputFieldRef}>
                        {label ? (
                            <label className={clsx(styles.label, {
                                [styles.labelHasFocus]: hasFocus,
                                [styles.labelInvalid]: !isValid,
                                [styles.labelActive]: isInputActive
                            })}
                                id={labelId}
                                htmlFor={fieldId}
                                title={label}
                                children={label}
                            />
                        ) : null}

                        <input className={clsx(styles.input, {
                            [styles.withLabel]: !!label,
                            [styles.activeWithLabel]: !!label && isInputActive
                        })}
                            id={fieldId}
                            aria-describedby={describedById}
                            ref={inputRef}
                            {...rest}
                        />
                        {append ? <span className={clsx(styles.additionalContent)} children={append} /> : null}
                    </div>
                    {children}
                </div>
                {showInvalidMessage ? (
                    <InvalidMessage id={invalidMessageId} children={invalidMessage} />
                ) : null}
                {showHelpText ? <HelpText id={helpTextId} children={helpText} /> : null}
            </div>
        );
    }
);
