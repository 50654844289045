import React, { memo, useEffect, useState } from 'react';
import { IStatusCodeColor, PriceGroup, StockStatus } from '~/components/features';
import { Button, Text, InputStepper } from 'ui';
import NextLink from 'next/link';
import { stringToSlug, isJPG } from 'utils';
import { useTranslations } from '~/services/cms';
import { CommerceModel } from 'api-types';
import styles from './LineItem.module.css';
import BaseProductInfo from '~/components/shared/BaseProductInfo/BaseProductInfo';
import { generateImageUrl } from '~/shared/utils';
import AddFavoriteButton from '~/components/features/Favorites/AddFavoriteButton/AddFavoriteButton';

export type Props = {
    line: CommerceModel.LineItem;
    onQuantityChange?: (quantity: number) => void;
    isUpdating?: boolean;

};

const isJPGroup = isJPG();

const closestVal = (value: number, colli = 1): number => {
    const leftover = value % colli;
    if (leftover === 0) return value;
    return value + (colli - leftover);
};

const DEBOUNCE_TIME = 1000;
let stateTimeout: NodeJS.Timeout | null = null;

export const LineItem = memo(({ line, onQuantityChange, isUpdating }: Props) => {
    const translate = useTranslations();
    const {
        images,
        name,
        quantity = 1,
        productId,
        priceOutput,
        jpGroupNumber,
        oldJPGroupNumber,
        brand,
        trafficLight,
        stock,
        packingUnit = 1,
        expectedDate
    } = line;

    const colliVal = isJPGroup ? packingUnit : 1;

    // console.log

    const [finalQuantity, setFinalQuantity] = useState(quantity);
    const linkUrl = `/p/${stringToSlug(name || '')}/${productId}/${productId}`;

    const quantityChange = (quantity: number) => {
        const closestQuantity = closestVal(quantity, colliVal);
        setFinalQuantity(closestQuantity);
        onQuantityChange && onQuantityChange(closestQuantity);
    }

    const onAmountSearch = (quantity: number) => {
        if (stateTimeout !== null) {
            clearTimeout(stateTimeout);
        }

        stateTimeout = setTimeout(() => {
            quantityChange(quantity);
        }, DEBOUNCE_TIME);
    };

    useEffect(() => {
        if (!isUpdating) {
            console.log('Stopped Updating');
            setFinalQuantity(quantity);
        }
    }, [isUpdating]);

    const imageUrl = images && images?.length > 0 ? generateImageUrl({
        size: 'productcarousel',
        fileName: images[0]?.fileName || '',
        modifiedDateTime: images[0]?.modifiedDateTime || ''
    }) : undefined;

    return (
        <li className={styles.lineItem}>
            <NextLink href={linkUrl} passHref>
                <a href={linkUrl} className={styles.productLink}>
                    <BaseProductInfo
                        productName={name}
                        imgSrc={imageUrl}
                        jpGroupNumber={jpGroupNumber}
                        oeNumber={oldJPGroupNumber}
                        brand={brand}
                    />
                </a>
            </NextLink>

            <div className={styles.lineFavorites}>
                <AddFavoriteButton productId={productId} />
            </div>

            <StockStatus
                statusCode={trafficLight as IStatusCodeColor}
                productId={productId}
                amount={stock}
                expectedDeliveryDate={expectedDate} />

            <div className={styles.lineStepper}>
                <InputStepper
                    defaultValue={finalQuantity}
                    onChange={(v) => quantityChange(v)}
                    onChangeInput={(v) => onAmountSearch(v)}
                    colli={Number(colliVal)} />
                <Button variant="link" onClick={() => quantityChange(0)}>
                    <Text as="b" variant="bodySmall">
                        {translate('commerce.basket.removeProductButton')}
                    </Text>
                </Button>
            </div>

            <PriceGroup className={styles.linePrice}
                displayPriceInclTax={priceOutput?.totalPrice?.displayTaxedPrice}
                displaySalePriceInclTax={priceOutput?.salePrice?.displayTaxedPrice}
                displayUnitPriceInclTax={priceOutput?.salePrice ? priceOutput?.salePrice?.displayTaxedPrice : priceOutput?.unitPrice?.displayTaxedPrice}
                isOnSale={priceOutput?.salePrice !== null}
                variant="body" />

        </li>
    );
});
