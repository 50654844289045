const INTERNATIONAL_KEY = 'DK';

/**
 * Use this hook to get the delivery options for a country.
 * @param props
 * @example
 * const { options } = useDeliveryOptions({ countryCode: 'US' });
 * console.log(options);
 */
export const useCountryOptions = (countryKey: string) => {
    const deliveryOptions = [
        {
            title: 'Denmark',
            value: 'DK',
        },
    ];

    // Don't ever use INT as a country key.
    const currentDeliveryKey = countryKey === INTERNATIONAL_KEY ? INTERNATIONAL_KEY : countryKey;
    const currentCountryName =
        countryKey === INTERNATIONAL_KEY ? INTERNATIONAL_KEY.toLocaleUpperCase() : countryKey;

    return { deliveryOptions: deliveryOptions || [], currentDeliveryKey, currentCountryName };
};
