import { useMarkets } from '~/services/globalization';
import { useBasketState } from './useBasketState';
import { usePrefixUrl } from '~/shared/hooks';

export const useBasketPaths = () => {
    const { checkoutBaseUrl } = usePrefixUrl()
    const { basketId } = useBasketState()
    const { currentMarket } = useMarkets();


    const marketKey = currentMarket?.storeKey;


    const basketQueryKey = `Basket-${marketKey}-${currentMarket?.countryCode}-${currentMarket?.countryCode}`;

    // Basket
    const basketUrl = `${checkoutBaseUrl}/Basket${basketId ? '/' + basketId : ''
        }`;
    const basketLineUrl = `${checkoutBaseUrl}/Basket${basketId ? '/' + basketId : ''
        }/Line`;
    const basketUploadCSVUrl = `${checkoutBaseUrl}/Basket${basketId ? '/' + basketId : ''
        }/csv`;


    // Buyer information
    const addBuyerInformationSubmitUrl = `${checkoutBaseUrl}/Basket${basketId ? '/' + basketId : ''
        }/CustomerInformation/isCheckout=true`;

    // Shipping
    const shippingApiUrl = `${checkoutBaseUrl}/Shipping`;
    const addShippingMethodUrl = `${checkoutBaseUrl}/Basket${basketId ? '/' + basketId : ''
        }/Shipping`;
    const shippingMethodUrl = `${checkoutBaseUrl}/Shipping/PostShippingInformation`;

    // Payment
    const getPaymentOptionsUrl = `${checkoutBaseUrl}/Payment`;
    const addPaymentUrl = `${checkoutBaseUrl}/Basket${basketId ? '/' + basketId : ''
        }/Payment`;
    const createPaymentUrl = `${checkoutBaseUrl}/Payment`;

    // Order
    const orderUrl = `${checkoutBaseUrl}/Order`;

    // confirm Order For Account Sales
    const confirmOrderForAccountSalesUrl = `${checkoutBaseUrl}/Order/{{OrderNumber}}/ConfirmOrderForAccountSales`;

    // Receipt
    const receiptUrl = `${checkoutBaseUrl}/Order/{{i}}/receipt`;

    return {
        basketUrl,
        basketLineUrl,
        basketUploadCSVUrl,
        basketQueryKey,
        shippingApiUrl,
        addBuyerInformationSubmitUrl,
        addShippingMethodUrl,
        shippingMethodUrl,
        getPaymentOptionsUrl,
        addPaymentUrl,
        createPaymentUrl,
        orderUrl,
        confirmOrderForAccountSalesUrl,
        receiptUrl,
        basketId
    };
};
