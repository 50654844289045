export {
    API_URL,
    COMMERCE_API_URL,
    LICENSE_PLATE_API_URL,
    APPINSIGHTS_CONNECTION_STRING,
    APPINSIGHTS_INSTRUMENTATIONKEY,
    IMAGE_CDN_URL,
    HOSTNAME_URL,
    HOSTNAME_KEY,
    RELEWISE_DATASET,
    RELEWISE_KEY
} from './runtime-config';
