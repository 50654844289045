import styled from '@emotion/styled';
import { switchProp } from 'styled-tools';
type StyledLineProps = {
    alignment: 'horizontal' | 'vertical';
    thickness?: number | string;
};
export const StyledLine = styled.div<StyledLineProps>(
    ({ theme, thickness }) => ({
        width: '100%',
        height: thickness || '1px',
        backgroundColor: theme.colors.black,
    }),
    switchProp('alignment', {
        vertical: ({ thickness }) => ({
            height: '100%',
            width: thickness || '1px',
        }),
    })
);
