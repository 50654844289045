/* JP Group Colors */
export const black = '#101426';
export const white = '#ffffff';

export const grey90 = '#192038';
export const grey70 = '#364960';
export const grey50 = '#8591AB';
export const grey40 = '#ABB4C5';
export const grey30 = '#E4E9F2';
export const grey20 = '#F1F4F8';
export const grey10 = '#FAFBFC';
export const grey = grey50;

export const positive = '#50AC86';
export const neutral = '#FFB700';
export const negative = '#D02828';

export const blue50 = '#348DF7';
export const blue = blue50;

export const primary = black;
export const secondary = grey;

export const red50 = '#FF2F37';
export const red = red50;
