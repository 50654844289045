import Head from 'next/head';
import { useProduct } from '~/services/catalogue';
import { usePage } from '~/services/cms';
import { useProductDetailsSlug } from '~/services/use-product-details-slug';
import { isSSR } from '~/shared/utils';
import { useRouter } from 'next/router';


export const MetaFields = () => {
    const { data: page } = usePage();
    const { title, description, canonical, hideFromRobots } = page?.meta || {};
    let metaViewport = "initial-scale=1.0, width=device-width";
    if (!isSSR && navigator.userAgent.indexOf('iPhone') > -1) {
        metaViewport = "width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=no";
    }
    const { query } = useRouter();
    const { productId, variantId } = useProductDetailsSlug(query?.slug)
    const getProductProps = {
        productId,
        variantId,

    }

    const { data: product } = useProduct();
    const { name } = product || {};

    // sanitize product name
    const sanitizeProductName = (name: string) => {
        return name
            .toLowerCase()
            .replace(/[^a-z0-9]+/g, '-') // Replace non-alphanumeric characters with hyphens
            .replace(/^-+|-+$/g, ''); // Remove leading and trailing hyphens
    };

    const baseUrl = canonical?.url.replace('/product', '/p').replace('/en/', `/${query?.culture || 'en'}/`);
    const sanitizedProductName = name ? sanitizeProductName(name) : '';
    const canonicalUrl = name && getProductProps.productId ? `${baseUrl}${sanitizedProductName}/${getProductProps.productId}/` : canonical?.url;


    return (
        <Head>
            <meta charSet="utf-8" />
            <meta name="viewport" content={metaViewport} />
            <title>{name || title || 'Product'}</title>
            {description ? <meta name="description" content={description} /> : null}
            {hideFromRobots ? <meta name="robots" content="noindex" /> : null}
            {canonical ? <link rel="canonical" href={canonicalUrl} /> : null}

            {page?.hrefLangs.map(({ culture }) => (
                <link
                    rel="alternate"
                    key={`hreflang-${culture}`}
                    hrefLang={`${culture}`}
                    href={`${baseUrl?.replace(/\/[a-z]{2}\//, `/${culture}/`)}${sanitizedProductName}/${getProductProps.productId}/`}
                />
            ))}
        </Head>
    );
};
