import { useEffect, useState } from 'react';
import { signOut, useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useFrame } from '~/services/cms';

/**
 * Extension to next auth use session hook
 * will redirect active session if refresh token is non available
 */
export const useAuthentication = (shouldRedirect?: boolean, redirectUrl?: string) => {
    const { data: session, status } = useSession();
    const router = useRouter();
    const { data: frame } = useFrame();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isAuthenticating, setIsAuthenticating] = useState(false);
    const loginUrl = frame?.staticLinks?.loginPage?.url;

    useEffect(() => {
        if (session?.error === 'RefreshAccessTokenError') {
            signOut({ callbackUrl: redirectUrl ? `${loginUrl}?callback=${redirectUrl}` : loginUrl, redirect: shouldRedirect });
        }

        // if session is available the user is authenticated
        setIsAuthenticated(session !== null && session !== undefined && status === 'authenticated');
    }, [session, router, shouldRedirect, loginUrl]);

    useEffect(() => {
        if (status == 'loading') {
            setIsAuthenticating(true);
        } else {
            setIsAuthenticating(false);
        }
    }, [status]);

    return { isAuthenticated, isAuthenticating, session, status };
};
