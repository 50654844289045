import React, { FC } from 'react'
import { useTranslations } from '~/services/cms';
import { SortKeyType, SortOrderType } from '~/components/features';
import styles from './Sorting.module.css'
import { Text } from 'ui';


interface IProps {
    sortBy: SortKeyType;
    sortOrder: SortOrderType
    onChangeSortBy: (val: SortKeyType) => void
    onChangeOrder: (val: SortOrderType) => void
    isFetching?: boolean;
}

const Sorting: FC<IProps> = (props) => {
    const {
        sortBy = '',
        sortOrder = 'Ascending',
        onChangeOrder,
        onChangeSortBy,
        isFetching,
    } = props
    const translate = useTranslations()

    interface ISortKeyOption {
        label: string
        value: SortKeyType
    }
    const sortingOptions: ISortKeyOption[] = [
        {
            label: translate('sorting.labelRelevance'),
            value: ''
        },
        {
            label: translate('sorting.labelJpGroupNumber'),
            value: 'JpGroupNumber'
        }
    ]

    interface ISortOrder {
        label: string
        value: SortOrderType
    }
    const sortingOrderOptions: ISortOrder[] = [
        {
            label: translate('sorting.ascending'),
            value: 'Ascending'
        },
        {
            label: translate('sorting.descending'),
            value: 'Descending'
        }
    ]

    return (
        <div className={styles.root}>
            <Text variant='bodySmall'>{translate('sorting.sortBy')}</Text>
            <div className={styles.controls}>
                <select className={styles.select}
                    disabled={isFetching}
                    value={sortBy}
                    onChange={e => onChangeSortBy(e.target.value as SortKeyType)}>
                    {sortingOptions.map(option => (
                        <option key={option.value} value={option.value}>{option.label}</option>
                    ))}
                </select>
                {sortBy && (
                    <select className={styles.select}
                        disabled={isFetching}
                        value={sortOrder}
                        onChange={e => onChangeOrder(e.target.value as SortOrderType)}>
                        {sortingOrderOptions.map(option => (
                            <option key={option.value} value={option.value}>{option.label}</option>
                        ))}
                    </select>
                )}
            </div>

        </div>
    )
}

export default Sorting