import { useMarkets } from '~/services/globalization';
import { isBrowser } from 'utils';
import { useRouter } from 'next/router';

export const usePrefixUrl = () => {
    const { currentMarket } = useMarkets();
    const { locale, defaultLocale } = useRouter();
    const language = locale || defaultLocale || 'da';

    const marketKey = currentMarket?.storeKey;

    const prefixUrlCommerce = new URL(
        isBrowser ? '/api/commerce/proxy' : '/api',
        isBrowser ? window.location.origin : process.env.COMMERCE_API_URL
    );

    const prefixUrlCatalogue = new URL(
        isBrowser ? '/api/catalogue/proxy' : '/api',
        isBrowser ? window.location.origin : process.env.COMMERCE_API_URL
    );

    const checkoutBaseUrl = `${prefixUrlCommerce}/${marketKey}/${language}/Checkout`;
    const catalogueBaseUrl = `${prefixUrlCatalogue}/${marketKey}/${language}/Catalogue`;
    const favoritesBaseUrl = `${prefixUrlCatalogue}/${marketKey}/${language}/Favorite`;
    const stockNotificationBaseUrl = `${prefixUrlCatalogue}/${marketKey}/${language}/StockNotification`;
    const backorderUrl = `${prefixUrlCommerce}/${marketKey}/${language}/Backorder`;

    return {
        checkoutBaseUrl,
        catalogueBaseUrl,
        favoritesBaseUrl,
        stockNotificationBaseUrl,
        backorderUrl
    }
}

