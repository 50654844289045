import React from 'react';
import { useTranslations } from '~/services/cms';
import { Button } from 'ui';
import styles from './BackArrow.module.css';

type Props = { onBack?: () => void };

export const BackArrow = ({ onBack }: Props) => {
    const translate = useTranslations();

    return (
        <Button className={styles.arrowButton} variant="secondary" iconName='chevron-left' onClick={onBack} aria-describedby={translate('accessibility.goBack')} />
    );
};
