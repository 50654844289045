import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';

export const Wrapper = styled.div<{ marginBottom?: number; marginTop?: number }>(
    () => ({
        position: 'relative',
        width: 'auto',
    }),

    ifProp('marginBottom', ({ marginBottom }) => ({
        marginBottom: `${marginBottom}px`,

        ul: {
            position: 'unset',
        },
    })),

    ifProp('marginTop', ({ marginTop }) => ({
        marginTop: `${marginTop}px`,
    }))
);


type ListProps = {
    expandLeft: boolean;
    isOpen?: boolean;
    noLabel?: boolean;
};

export const List = styled.ul<ListProps>(
    ({ theme }) => ({
        zIndex: theme.zIndices[1],
        position: 'absolute',
        minWidth: '100%',
        width: '100%',
        // 40px is the actual height of the option, it can maximum be 7½ options high.
        '--sevenOptions': `calc(7 * ${theme.spaces[6]})`,
        maxHeight: `calc(var(--sevenOptions) + ${theme.spaces[4]})`,
        overflowY: 'scroll',
        backgroundColor: theme.colors.white,
        /* Foreground, Background */
        scrollbarColor: `${theme.colors.black} ${theme.colors.grey20}`,
        ':focus': {
            outline: 'none',
        },
        ['&::-webkit-scrollbar']: {
            width: '3px',
        },
        ['&::-webkit-scrollbar-thumb']: {
            background: theme.colors.black,
        },
        ['&::-webkit-scrollbar-track']: {
            background: theme.colors.grey20,
        },
    }),

    ifProp('expandLeft', () => ({
        right: 0,
    })),

    ifProp('noLabel', () => ({ boxShadow: 'none' })),

    ifProp('isOpen', ({ theme }) => ({ border: `1px solid ${theme.colors.grey30}` }))
);

type ItemProps = {
    highlighted: boolean;
};

export const Item = styled.li<ItemProps>(
    ({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
        height: theme.spaces[6],
        padding: `0 ${theme.spaces[2]}`,
        borderBottom: `1px solid ${theme.colors.grey30}`,
        ':hover': {
            color: theme.colors.grey10,
        },
    }),

    ifProp('highlighted', ({ theme }) => ({
        color: theme.colors.primary,
        backgroundColor: theme.colors.grey30,
    }))
);

export const ItemLeftContainer = styled.div(({ theme }) => ({
    display: 'flex',
    gap: theme.spaces[2],
    alignItems: 'center',
}));

export const ItemCheckbox = styled.input(({ theme }) => ({
    appearance: 'none',
    cursor: 'pointer',
    backgroundColor: theme.colors.white,
    border: `1px solid ${theme.colors.grey50}`,
    display: 'grid',
    height: theme.spaces[3],
    minHeight: theme.spaces[3],
    minWidth: theme.spaces[3],
    padding: 0,
    placeContent: 'center',
    width: theme.spaces[3],
    borderRadius: 0,

    '&:checked:before': {
        content: '""',
        backgroundColor: theme.colors.black,
        height: '0.60em',
        width: '0.60em',
    },
}));

export const ItemLabel = styled.span(({ theme }) => ({
    marginLeft: theme.spaces[2],
    fontSize: theme.fontSizes.sm,
    lineHeight: theme.lineHeights.base,
}));

export const ItemCount = styled.span(({ theme }) => ({
    fontSize: theme.fontSizes.sm,
    lineHeight: theme.lineHeights.base,
    color: theme.colors.grey50,
}));
