import React, { useEffect } from 'react';
import { StyleContent, StyledCloseButton, StyledNotification } from '../styled';
import { NotificationOptions } from '~/shared/hooks/useNotification/useNotificationModel';
import { useTranslations } from '~/services/cms';
import { useTimeoutFn } from 'react-use';
import { SvgIcon } from 'ui';

type Props = NotificationOptions & {
    onDismiss: () => void;
};

const variants = {
    pre: { opacity: 0 },
    visible: { opacity: 1 },
};

export const Notification = ({ text, onDismiss, onClick, severity = 'error', duration }: Props) => {
    const translate = useTranslations();
    const [, cancel, restart] = useTimeoutFn(onDismiss, duration);

    useEffect(() => {
        if (!duration) {
            cancel();
        }
    }, []);

    return (
        <StyledNotification
            layout
            variant={severity}
            variants={variants}
            initial="pre"
            animate="visible"
            exit="pre"
            aria-live="polite"
            role="button"
            tabIndex={0}
            onClick={onClick || onDismiss}
            onFocus={cancel}
            onMouseEnter={cancel}
            onMouseLeave={duration ? restart : undefined}
            onTouchStart={cancel}
            onTouchEnd={duration ? restart : undefined}
        >
            <StyleContent>
                {text}
                <StyledCloseButton
                    children={<SvgIcon name='close' />}
                    onClick={onDismiss}
                    title={translate('accessibility.closeIconTitle')}
                />
            </StyleContent>
        </StyledNotification>
    );
};
