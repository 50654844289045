import { InvalidMessage, HelpText } from '../../../../FormElements';
import React, { useImperativeHandle, useRef, useState } from 'react';
import { useInputField } from '../../hooks/useInputField';
import {
    StyledCheckboxWrapper,
    StyledCheckboxElement,
    StyledCheckbox,
    StyledCheckboxIndicator,
    StyledLabel,
    StyledCheckboxHelpTexts,
} from './styled';

type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

export type CheckboxProps = Omit<InputProps, 'placeholder' | 'value'> & {
    /**
     * Adds a label to the input field. This is required for accessibilty.
     */
    label?: string | React.ReactNode;

    /**
     * Add an additional help text below the input field.
     */
    helpText?: string;

    /**
     * Add an additional help text below the input field.
     */
    invalidMessage?: string;

    /**
     * Set styling to indicate input is invalid.
     * Also shows the `invalidMessage` if provided
     */
    isInvalid?: boolean;

    /**
     * Toggle between checkbox or radio
     */
    type?: 'checkbox' | 'radio';
};

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
    (
        { label, helpText, invalidMessage, isInvalid, id, onChange, type = 'checkbox', ...rest },
        ref
    ) => {
        const [isValidityValid, setIsValidityValid] = useState(true);
        const inputRef = useRef<HTMLInputElement>(null);

        const {
            fieldId,
            helpTextId,
            invalidMessageId,
            describedById,
            showHelpText,
            showInvalidMessage,
        } = useInputField({
            id,
            helpText,
            invalidMessage,
            isInvalid,
        });

        const isValid = !isInvalid && isValidityValid;

        const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
            setIsValidityValid(inputRef.current?.validity.valid ? true : false);
            onChange && onChange(event);
        };

        useImperativeHandle(ref, () => inputRef.current as HTMLInputElement, [ref]);

        return (
            <div key={fieldId}>
                <StyledCheckboxWrapper valid={isValid}>
                    <StyledCheckboxElement round={type === 'radio'}>
                        <StyledCheckbox
                            type={type}
                            id={fieldId}
                            ref={inputRef}
                            aria-describedby={describedById}
                            onChange={onChangeHandler}
                            {...rest}
                        />
                        <StyledCheckboxIndicator round={type === 'radio'} />
                    </StyledCheckboxElement>
                    {label && typeof label === 'string' ? (
                        <StyledLabel htmlFor={fieldId} title={label} children={label} />
                    ) : null}
                    {label && typeof label !== 'string' ? label : null}
                </StyledCheckboxWrapper>
                <StyledCheckboxHelpTexts>
                    {showInvalidMessage ? (
                        <InvalidMessage id={invalidMessageId} children={invalidMessage} />
                    ) : null}
                    {showHelpText ? <HelpText id={helpTextId} children={helpText} /> : null}
                </StyledCheckboxHelpTexts>
            </div>
        );
    }
);
