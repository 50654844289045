import Script from 'next/script';
import parse, {
    attributesToProps,
    domToReact,
    Element,
    HTMLReactParserOptions,
} from 'html-react-parser';
import { useMemo } from 'react';
import NextLink from 'next/link';
import clsx from 'clsx';

import { Text, TextVariant, LinkDeprecated, Image } from '../../../';

import { textStyles } from '../../../';
import styles from '../RawHtml.module.css';


type RegularTextTags = typeof regularTextTags[number];
const regularTextTags = [
    'p',
    'b',
    'strong',
    'i',
    'em',
    'u',
    'blockquote',
    'code',
    'pre',
    'li',
] as const;

type HeadingTags = typeof headingTags[number];
const headingTags = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] as const;

const options: HTMLReactParserOptions = {
    /**
     * Required to prevent warning about whitespace in tables - if tables are used.
     * Might removed intended whitespace.
     *
     * @see https://github.com/remarkablemark/html-react-parser#trim
     */
    trim: true,

    /**
     * Replace HTML tags with react components
     * @see https://github.com/remarkablemark/html-react-parser#replace-element-attributes
     */
    replace: (domNode) => {
        const { attribs, children, name } = domNode as Element;
        const props = attributesToProps(attribs || {});

        if (!attribs) {
            return;
        }

        if (regularTextTags.includes(name as RegularTextTags)) {
            const tag = name as RegularTextTags;
            return <Text {...props} as={tag} children={domToReact(children, options)} />;
        }

        if (headingTags.includes(name as HeadingTags)) {
            const size = Number(name.replace(/\D/g, ''));

            return (
                <Text
                    {...props}
                    as={name as HeadingTags}
                    variant={`display${size}` as TextVariant}
                    children={domToReact(children, options)}
                />
            );
        }

        if (name === 'a') {
            return (
                <NextLink href={props.href} passHref>
                    <LinkDeprecated {...props}>{domToReact(children, options)}</LinkDeprecated>
                </NextLink>
            );
        }

        if (name === 'img') {
            const API_URL = process.env.NEXT_PUBLIC_UMBRACO_URL;
            const isRelative = props.src.startsWith('/');
            const src = isRelative ? `${API_URL}${props.src}` : props.src;

            return (
                <Image
                    src={src}
                    alt={props.alt}
                    width={props.width}
                    height={props.height}
                    layout="responsive"
                />
            );
        }

        if (name === 'table') {
            return <table className={clsx(
                textStyles.text,
                textStyles.variantBody,
                textStyles.asTable,
                styles.table
            )} {...props} children={domToReact(children, options)} />;
        }

        if (name === 'script') {
            return <Script {...props} children={domToReact(children, options)} />;
        }
    },
};

export const useRawHtml = (html: string) => {
    return useMemo(() => parse(html, options), [html]);
};
