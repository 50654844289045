import styled from '@emotion/styled';
import { LinkDeprecated } from '../../../';
import { UspProps } from './Usp';

export const StyledImageWrapper = styled.span(({ theme }) => ({
    display: 'inline',
    marginRight: theme.spaces['3'],
    height: 18,
    width: 25,
}));

export const StyledUsp = styled.div<Pick<UspProps, 'shade'>>(({ theme, shade }) => ({
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.fontSizes.xs,
    color: shade === 'dark' ? theme.colors.black : theme.colors.white,
    textDecoration: 'none',
    '&:hover': {
        color: shade === 'dark' ? theme.colors.black : theme.colors.white,
    },
}));

export const StyledLink = styled(LinkDeprecated)({
    textDecoration: 'none',
});
