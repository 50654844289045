import { FC } from 'react'
import styles from './Progress.module.css'

interface IProgressProps {
    percentage: number;
    width?: string;
}

export const Progress: FC<IProgressProps> = ({ percentage, width = '100%' }) => (
    <div className={styles.progressBar} style={{ width }}>
        <span style={{ width: `${percentage}%` }} className={styles.progress} />
    </div>
)

