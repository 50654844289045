import { ButtonDeprecated, TextVariant } from '../../../';
import NextLink from 'next/link';
import React from 'react';
import { CmsModel } from 'api-types';
import { Text } from '../../../index'
import { StyledArea, StyledHeader } from './styled';

type ModuleHeaderProps = {
    headline?: string;
    variant?: TextVariant;
    as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    link?: CmsModel.Link;
    // Adds children into an optional meta area to the right
    children?: React.ReactNode;
};

export const ModuleHeader = ({
    headline,
    variant = 'display1',
    as = 'h2',
    link,
    children,
}: ModuleHeaderProps) => {
    return (
        <>
            <StyledHeader showMeta={!!children}>
                {headline ? (
                    <StyledArea area="headline">
                        <Text variant={variant} as={as}>
                            {headline}
                        </Text>
                    </StyledArea>
                ) : null}
                {link?.url ? (
                    <StyledArea area="link">
                        <NextLink href={link.url} passHref>
                            <ButtonDeprecated
                                as="a"
                                shape="plain"
                                children={link.title}
                                target={link.target}
                            />
                        </NextLink>
                    </StyledArea>
                ) : null}
                {children ? <StyledArea area="meta" children={children} /> : null}
            </StyledHeader>
        </>
    );
};
