import type { QueryFunctionContext } from '@tanstack/react-query';
//@ts-ignore
import { HTTPError } from 'ky';
import { globalizationApi } from '../../globalization-api';
import { MarketsError } from './errors';
import type { MarketsKey } from './types';
import { GlobalizationModel } from 'api-types';
import { isJPG } from "utils";

const exceptions = {
  500: MarketsError,
};

type Markets = { markets: GlobalizationModel.Market[] };

export function getMarkets(context: QueryFunctionContext<MarketsKey>) {
  const { signal, queryKey } = context;

  const area = isJPG() ? 'jpg' : 'how';
  return globalizationApi
    .get(`globalization/Markets/byArea/${area}`, {
      signal,
      throwHttpErrors: false,
      hooks: {
        afterResponse: [
          (request, options, response) => {
            if (!response.ok) {
              const HTTPException = exceptions[response.status as keyof typeof exceptions];

              if (HTTPException) {
                throw new HTTPException(response, queryKey);
              }

              throw new HTTPError(response, request, options);
            }

            return response;
          },
        ],
      },
    })
    .json<Markets>();
}
