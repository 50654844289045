import { AspectRatio, Image } from '../../../';
import { CatalogueModel } from 'api-types';
import { StyledWrapper, StyledThumbnail, StyledDetails, StyledName, StyledBrand } from './styled';
import { Prices } from './components/Prices';
import NextLink from 'next/link';

export const ProductCard = ({ name, brand, imageSrc, ...props }: { [key: string]: unknown }) => {
    const url = `/p/`

    const title = (props.name || props.displayName) as string || '';
    const price = (props.price || props.priceDetail) as CatalogueModel.ProductPrice || undefined;
    return (
        <StyledWrapper>
            <NextLink href={`${url}`} title={title} aria-label={title} passHref>
                <StyledThumbnail>
                    <AspectRatio ratio={1.24}>
                        <Image
                            src={imageSrc as string}
                            alt={''}
                            layout="fill"
                            objectFit="cover"
                        />
                    </AspectRatio>
                </StyledThumbnail>
            </NextLink>
            <StyledDetails>
                {brand ? (
                    <StyledBrand href={`${url}`} title={title} aria-label={title}>
                        {brand as string}
                    </StyledBrand>
                ) : null}
                <StyledName href={`${url}`} title={title} aria-label={title}>
                    <span>{title}</span>
                </StyledName>
                {price && <Prices {...price} />}
            </StyledDetails>
        </StyledWrapper>
    );
};
