import styled from '@emotion/styled';

export const StyledBadgesWrap = styled.div({
    display: 'flex',
    position: 'absolute',
    bottom: '0',
    alignItems: 'center',
});

export const StyledBadge = styled.span(({ theme }) => ({
    padding: '3px',
    marginRight: `${theme.spaces[1]}`,
    backgroundColor: `${theme.colors.grey50}`,
    fontSize: `${theme.fontSizes['2xs']}`,
    color: `${theme.colors.black}`,
}));
