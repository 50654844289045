import styled from '@emotion/styled';

export const StyledRichText = styled.div(({ theme }) => ({
    color: theme.colors.black,
    img: {
        maxWidth: '100%',
        height: 'auto',
    },

    [`p, ol, ul, pre, table`]: {
        marginBottom: theme.spaces[3],
    },

    p: {
        minHeight: '1em',
    },

    li: {
        marginBottom: theme.spaces[2],
    },

    ol: {
        paddingLeft: 25,
        listStyle: 'decimal',
    },

    ul: {
        paddingLeft: 25,
        listStyle: 'disc',
    },

    code: {
        fontFamily: theme.fonts.mono,
    },

    pre: {
        backgroundColor: theme.colors.grey20,
        padding: 15,
        fontFamily: theme.fonts.mono,
    },
    ['[class*="text"]']: {
        color: 'inherit',
    },
}));
