import React from 'react';
import { StyledPriceWrap, StyledPrice } from './styled';
import { CatalogueModel } from 'api-types';

export const Prices = ({
    displaySalePrice,
    displayUnitPrice,
    isOnSale,
    currency,
}: CatalogueModel.ProductPrice) => {
    return (
        <StyledPriceWrap>
            {isOnSale && displaySalePrice ? (
                <StyledPrice offer={'before'}>{`${currency} ${displayUnitPrice}`}</StyledPrice>
            ) : null}
            <StyledPrice offer={isOnSale ? 'new' : 'none'}>
                {`${currency} ${isOnSale ? displaySalePrice : displayUnitPrice}`}
            </StyledPrice>
        </StyledPriceWrap>
    );
};
