import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CommerceModel } from 'api-types';
import { useBasketPaths } from './useBasketPaths';
import { useNotification } from '~/shared/hooks/useNotification';
import { updateShipping } from '../service';
import { errorToJson } from '../utils/format';
import { useAuthentication } from '~/shared/hooks';
import { useBasketState } from './useBasketState';

export const useUpdateShipping = () => {
    const { push } = useNotification();
    const { shippingMethodUrl, basketQueryKey } = useBasketPaths();
    const queryClient = useQueryClient();
    const { session } = useAuthentication();
    const { setBasketId } = useBasketState();

    const { isLoading, mutateAsync: update } = useMutation<
        CommerceModel.Basket,
        Response,
        CommerceModel.PostShippingInformationRequest
    >((args) => updateShipping(args, shippingMethodUrl, session?.accessToken), {
        onSuccess: (basket) => {
            if (basket.conversionErrors?.length) {
                basket.conversionErrors.forEach((error: string) => {
                    push({ severity: 'warning', text: error });
                });
            } else {
                // push({ severity: 'success', text: `Shipping Method updated` });
            }
            queryClient.setQueryData([basketQueryKey], basket);
            if (basket.id) {
                setBasketId(basket.id);
            }
        },
        onError: async (error) => {
            const { level, errorMessages } = await errorToJson(error);
            push({ severity: level, text: errorMessages });
        },
    });

    return {
        isLoading,
        update,
    };
};
