import { CommerceModel } from 'api-types';
import { postRequest } from '~/shared/utils';

export async function addItem(
    { productId, quantity }: CommerceModel.NewBasketLine,
    apiUrl: string,
    token?: string
) {
    const request = await postRequest<CommerceModel.Basket>(apiUrl, {
        productId,
        quantity,
    }, {
        'Authorization': `Bearer ${token}`
    });

    const response = await request();

    return response;
}
