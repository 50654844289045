import * as React from 'react';
import type { ForwardRefComponent } from 'utils';
import { StyledButton } from './styled';
import { ReactNode } from 'react';
import { Content } from './components/content';

export const buttonVariants = ['primary', 'secondary', 'tertiary'];
export const buttonShapes = ['default', 'icon', 'plain'];
export const buttonShades = ['dark', 'light'];
type ButtonVariant = typeof buttonVariants[number];
type ButtonShape = typeof buttonShapes[number];
type ButtonShade = typeof buttonShades[number];

export interface ButtonDeprecatedProps {
    /**
     * Which theme variant to display button as
     * primary: solid background, without border
     * secondary: transparent background, with border
     * tertiary: transparent background, without border
     */
    variant?: ButtonVariant;

    /**
     * The usecase determines the shape
     */
    shape?: ButtonShape;

    /**
     * Which shade the button has. Useful for background distinction
     */
    shade?: ButtonShade;

    /**
     * Description of what the button does, this is for screen readers
     * and should be used when content does not describe the action
     */
    description?: string;
    children: ReactNode;
    className?: string;
}

/**
 * Handles all the button styles across the site.
 *
 * The proper way to use as link is to combine with next/link. This hooks into the router and performance optimisations.
 * @example
 * <NextLink href="#" passHref>
 *   <Button {...props} as="a" />
 * </NextLink>
 */
export const ButtonDeprecated: ForwardRefComponent<typeof StyledButton, ButtonDeprecatedProps> = React.forwardRef(
    (
        {
            variant = 'primary',
            shade = 'dark',
            shape = 'default',
            description,
            children,
            className,
            ...restProps
        },
        ref
    ) => {
        const shapeGuardedVariant = shape === 'plain' ? 'tertiary' : variant;
        return (
            <StyledButton
                className={className}
                ref={ref}
                variant={shapeGuardedVariant}
                shade={shade}
                shape={shape}
                {...restProps}
            >
                {description ? <Content description={description} children={children} /> : children}
            </StyledButton>
        );
    }
) as ForwardRefComponent<typeof StyledButton, ButtonDeprecatedProps>;
