import { useSelect, UseSelectState, UseSelectStateChangeOptions } from 'downshift';
import { useEffect, useState } from 'react';
import { MultipleSelectorProps, DropdownItem } from './model';
import {
    Item,
    ItemCount,
    ItemCheckbox,
    ItemLeftContainer,
    List,
    Wrapper,
} from './styled';
import { deepEqual } from 'utils';
import { Text, DropdownButton } from '../../../index';

export const MultipleSelectorDeprecated = ({
    expandLeft = false,
    items,
    label,
    initialSelectedItems,
    noLabel,
    disabled = false,
    onSelectedItemsChange,
}: MultipleSelectorProps) => {
    const [selectedItemList, setSelectedItemList] = useState<DropdownItem[]>(
        items.filter((item) => initialSelectedItems?.includes(item.value as string | number))
    );

    // Update selectedItems state on props change
    useEffect(() => {
        setSelectedItemList(
            items.filter((item) =>
                initialSelectedItems?.some(
                    (selected) => selected && deepEqual(selected, item.value)
                )
            )
        );
    }, [initialSelectedItems, items]);

    const {
        getItemProps,
        getMenuProps,
        getToggleButtonProps,
        highlightedIndex,
        isOpen,
    } = useSelect<DropdownItem>({
        items,
        stateReducer,
        selectedItem: null, // Att: Must be explicitly set to 'null'
        onSelectedItemChange: ({ selectedItem }) => {
            if (!selectedItem) {
                return;
            }

            const itemIsDeselected = selectedItemList.some(
                (item) => item.value === selectedItem.value
            );

            const newSelectedItemList = itemIsDeselected
                ? selectedItemList.filter((item) => item.value !== selectedItem.value)
                : [...selectedItemList, selectedItem];

            setSelectedItemList(newSelectedItemList);
            onSelectedItemsChange?.(newSelectedItemList);
        },
    });

    return (
        <Wrapper>
            <DropdownButton
                disabled={items.length == 0 || disabled}
                {...getToggleButtonProps()}>
                {label}
            </DropdownButton>

            <List {...getMenuProps()} expandLeft={expandLeft} noLabel={noLabel} isOpen={isOpen}>
                {(isOpen || noLabel) &&
                    items.map((item: DropdownItem, index: number) => {
                        return (
                            <Item
                                highlighted={highlightedIndex === index}
                                key={`${item}${index}`}
                                {...getItemProps({
                                    item,
                                    index,
                                })}
                            >
                                <ItemLeftContainer>
                                    <ItemCheckbox
                                        type="checkbox"
                                        checked={selectedItemList.some(
                                            (selected) =>
                                                selected && deepEqual(selected.value, item.value)
                                        )}
                                        onChange={() => undefined}
                                        value={item.value as string | number}
                                    />
                                    <Text
                                        color='natural700Color'
                                        variant='bodySmall'
                                        overflowEllipsis
                                    >
                                        {item.label}
                                    </Text>
                                </ItemLeftContainer>

                                {item.count ? <ItemCount>({item.count})</ItemCount> : null}
                            </Item>
                        );
                    })}
            </List>
        </Wrapper >
    );
};

function stateReducer(
    state: UseSelectState<DropdownItem>,
    actionAndChanges: UseSelectStateChangeOptions<DropdownItem>
) {
    const { changes, type } = actionAndChanges;

    switch (type) {
        case useSelect.stateChangeTypes.MenuKeyDownEnter:
        case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
            return {
                ...changes,
                isOpen: true, // keep menu open after selection.
                highlightedIndex: state.highlightedIndex,
            };
        default:
            return changes;
    }
}
