import { useQuery } from "@tanstack/react-query"
import { useAuthentication, usePrefixUrl } from '~/shared/hooks';

interface IPriceOffer {
    minimumQuantity: number;
    priceToDisplay: string;
}

interface ICustomerPricesResponse {
    productId: string;
    taxedPrice: string;
    unitPrice: string;
    priceOffers?: IPriceOffer[];
}

export const useCustomerPrices = (variantIds: string[], shouldFetch = true) => {
    const { catalogueBaseUrl } = usePrefixUrl()

    const { session } = useAuthentication();

    const url = `${catalogueBaseUrl}/prices`;

    const headers = new Headers({
        'Content-Type': 'application/json'
    });

    if (session?.accessToken) {
        headers.append('Authorization', `Bearer ${session?.accessToken}`)
    }

    return useQuery<ICustomerPricesResponse[]>({
        queryKey: ['productPrices', variantIds.join('-'), session?.accessToken],
        enabled: shouldFetch && !!variantIds?.length,
        queryFn: async () => {
            const res = await fetch(url, {
                method: 'POST',
                body: JSON.stringify(variantIds),
                headers,
            })
            const json = await res.json()
            return ({
                ...json,
                statusCode: res.status
            })
        },
    })
}