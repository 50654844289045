import { Text } from '../../../../Text';
import { useId } from '@radix-ui/react-id';
import React, { ReactNode, useContext, useEffect, useMemo } from 'react';
import { AccordionContext } from '../../context/AccordionContext';
import styles from './AccordionItem.module.css';
import * as RadixAccordion from '@radix-ui/react-accordion';
import { SvgIcon } from '../../../../SvgIcon/SvgIcon';
import clsx from 'clsx';

export type AccordionItemChildrenRenderProps = {
    isOpen: boolean;
    toggle: () => void;
    open: () => void;
    close: () => void;
    id: string;
};

export type AccordionItemProps = {
    /**
     * Text for the headline OR a component that will replace the default header and be used to trigger for the accordion
     */
    header?: string | ReactNode;

    /**
     * Controlls if the accordion is open or not
     */
    initAsOpen?: boolean;

    /**
     * Override the unique generated id
     */
    id?: string;

    /**
     * Overwrite the default icon
     */
    icon?: ReactNode | ((state: AccordionItemChildrenRenderProps) => React.ReactNode);

    /**
     * Accordion content
     */
    children?: React.ReactNode | ((state: AccordionItemChildrenRenderProps) => React.ReactNode);
    className?: string;
};

/**
 * @description
 * Wraps content (children) in an accordion.
 * Should always be used inside an <Accordion>
 *
 * @example
 *  <AccordionItem
 *      icon={<Icon icon="instagram" size="lg" />}
 *      header="Woah this is a cool accordion" />
 *      <p>Accordion body here!</p>
 *  </AccordionItem>
 *
 * @example
 *  <AccordionItem
 *      icon={<Icon icon="instagram" size="lg" />}
 *      header="Woah this is a cool accordion" />
 * .    {({ isOpen }) => (
 *         `This accordion is ${isOpen ? 'open' : 'closed' }!`
 *      )}
 *  </AccordionItem>
 */

export const AccordionItem = ({
    id: initialId,
    header,
    initAsOpen = false,
    children,
    className
}: AccordionItemProps) => {
    // Ensures present id. Fallback unique id
    const id = useId(initialId);

    const { states, disableAnimation, ...context } = useContext(AccordionContext);

    const isOpen = useMemo(() => states.includes(id), [states]);

    const methods = useMemo(() => {
        return {
            toggle: () => context.toggle(id),
            open: () => context.open(id),
            close: () => context.close(id),
        };
    }, [id]);

    useEffect(() => {
        initAsOpen && methods.open();
    }, []);

    return (
        <RadixAccordion.Item value={id} className={clsx(styles.accordionItem, className)}>
            <RadixAccordion.Trigger type={'button'} className={styles.accordionTrigger}>
                {typeof header === 'string' ? (
                    <Text variant="display5" children={header} />
                ) : (
                    header
                )}
                <SvgIcon className={styles.accordionIcon} name={'chevron-down'} />
            </RadixAccordion.Trigger>

            <RadixAccordion.Content className={styles.accordionContentContainer}>
                <div className={styles.accordionContent}>
                    {typeof children === 'function'
                        ? children({ id, isOpen, ...methods })
                        : children}
                </div>
            </RadixAccordion.Content>
        </RadixAccordion.Item>
    );
};
