import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { ModuleContainerProps } from './ModuleContainer';

type StyledModuleContainerProps = ModuleContainerProps & {
    textColor?: string;
};

export const StyledModule = styled.div<StyledModuleContainerProps>(
    ({ backgroundColor }) => ({
        margin: '0 auto',
        backgroundColor,
    }),
    ifProp('hasPadding', ({ theme }) => ({
        paddingTop: theme.spaces[4],
        paddingBottom: theme.spaces[4],
    })),

);
