import React, { FC } from 'react';

interface WrapProps {
    if: boolean;
    wrap: (children: React.ReactNode) => JSX.Element;
    children: React.ReactNode;
}

export const ConditionalWrap: FC<WrapProps> = ({ if: condition, wrap, children }) => {
    return condition ? wrap(children) : <>{children}</>;
};
