import { useRouter } from 'next/router';
import { usePage as useServicesPage } from 'services';
import locale from '~/constants/locale.mjs';
import { CmsModel } from 'api-types';

const fallbackLocale = locale.defaultLocale;

export function usePage<Select = CmsModel.PageBase>(select?: (data: CmsModel.PageBase) => Select) {
    const { locale, defaultLocale = fallbackLocale } = useRouter();
    const language = locale || defaultLocale;
    const url = useGetUrl();
    return useServicesPage({ locale: language, url, select });
}

const useGetUrl = () => {
    const { asPath, route, query } = useRouter();
    if (route !== '/[[...slug]]') {
        return decodeURIComponent(route.split(/[?#]/)[0]);
    } else {
        return !query.slug?.includes('p')
            ? decodeURIComponent(asPath.split(/[?#]/)[0])
            : '/product/';
    }
};
