import * as React from "react"
import { SVGProps, memo } from "react"

const Close = memo((props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        {...props}
    >
        <path d="M18 6 6 18M6 6l12 12" />
    </svg>
));

export default Close
