import React from 'react';
import clsx from 'clsx';
import styles from './Label.module.css';

type LabelProps = React.LabelHTMLAttributes<HTMLLabelElement>;

export type LabelFieldProps = Omit<LabelProps, 'value'> & {
    /**
     * Label text content
     */
    label?: string;

    /**
     * Set styling to indicate input is invalid.
     */
    isValid?: boolean;

    /**
     * Set styling to indicate input is in focus.
     */
    hasFocus?: boolean;

    /**
     * When active the label will move up above the actual input field
     */
    isActive?: boolean;
};

export const Label = (props: LabelFieldProps) => <label className={clsx(styles.label, props.className, {
    [styles.active]: props.isActive,
    [styles.focus]: props.hasFocus,
    [styles.invalid]: !props.isValid
})} {...props} />;
