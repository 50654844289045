export const fetcher = <T>(url: string, options?: RequestInit) => async () => {
    const response = await fetch(url, options);
    //const contentType = response.headers.get('content-type') || '';

    if (!response.ok) {
        console.error(`${response.status} ${response.statusText} at ${url}`);
        return Promise.reject(response);
    }

    const data: T = await response.json();

    return data;
};
