import create from 'zustand';
import { BasketUIState, PaymentOptionType } from '../models';

export const useBasketState = create<BasketUIState>((set) => ({
    basketId: null,
    isMiniBasketOpen: false,
    lastAddedVariantId: '',
    paymentOption: 'payNow',
    setBasketId: (basketId: string | null) => {
        set(() => ({
            basketId,
        }));
    },
    setPaymentOption: (paymentOption: PaymentOptionType) => {
        set(() => ({
            paymentOption,
        }));
    },
    toggleMiniBasket: (isMiniBasketOpen) => {
        set(() => ({
            isMiniBasketOpen,
        }));
    }
}));
