import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { ifProp } from 'styled-tools';

export const StyledGrabWrapper = styled(motion.div)(
    ifProp('drag', {
        cursor: 'grab',
        ':active': {
            cursor: 'grabbing',
        },
    })
);

export const StyledItemsWrapper = styled.div(({ theme: { spaces } }) => ({
    position: 'relative',
    display: 'flex',
    gap: spaces[5],
    paddingBottom: spaces[8],
}));
