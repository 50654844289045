import { Hydrate, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useQueryClient } from '~/services/query-client';
import { ThemeProvider } from '../theme';
import { Notifications } from '$features/Notifications';
import { SessionProvider } from "next-auth/react"
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../AppInsights";

import 'ui/styles/critical.css';
import { AppPropsWithLayout } from '~/types';

export default function App(
    {
        Component,
        pageProps: { session, ...pageProps }
    }: AppPropsWithLayout) {
    const queryClient = useQueryClient();
    const getLayout = Component.getLayout ?? ((page) => page);

    return (
        <AppInsightsContext.Provider value={reactPlugin}>
            <QueryClientProvider client={queryClient}>
                <ThemeProvider>
                    <SessionProvider session={session}>
                        <Hydrate state={pageProps.dehydratedState}>
                            {getLayout(<Component {...pageProps} />)}
                        </Hydrate>
                    </SessionProvider>
                    <Notifications />
                    <ReactQueryDevtools />
                </ThemeProvider>
            </QueryClientProvider>
        </AppInsightsContext.Provider>
    );
}
