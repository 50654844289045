import { theme } from '../theme';

/**
 * Helper method for using theme colors as a dropdown in storybook
 *
 * @example
 * ```ts
 * // example.stories.ts
 * export default {
 * 	// ...
 *  argTypes: {
 *      backgroundColor: {
 *          control: {
 *              type: 'color',
 *              presetColors: storybookPresetColors, // <<-- use storybookPresetColors here
 *          },
 *      },
 *  },
 * }
 * ```
 */
export const storybookPresetColors = Object.keys(theme.colors).map((key) => ({
    title: key as keyof typeof theme.colors,
    color: theme.colors[key as keyof typeof theme.colors],
}));

export const storybookThemeColorControl = {
    type: 'color',
    presetColors: storybookPresetColors,
};
