//@ts-ignore
import ky from 'ky-universal';
import { isBrowser } from 'utils';

const prefixUrl = new URL(
    isBrowser ? '/api/globalization/proxy' : '/api',
    isBrowser ? window.location.origin : process.env.COMMERCE_API_URL
);

export const globalizationApi = ky.create({
    prefixUrl,
    retry: {
        limit: 0,
    },
    hooks: {
        beforeRequest: [
            (request) => {
                console.log(`Request GLOBALIZATION API on ${isBrowser ? 'client' : 'server'}`);
                console.log('url: ', decodeURIComponent(request.url));
                console.log('body:', request.body);
            },
        ],
    },
});
