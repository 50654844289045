import { Severity } from '~/shared/hooks/useNotification/useNotificationModel';

/**
 * Format error response to json
 *
 * @param error
 */
export const errorToJson = async (
    error: Response
): Promise<{
    level: Severity;
    errorMessages: string[];
}> => {
    const isReadableError = error.body && !error.bodyUsed;
    if (!isReadableError) {
        console.error(error);
        return { level: 'error', errorMessages: [] };
    }

    const errorResponse = await error.json();

    return errorResponse;
};
