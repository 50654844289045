//@ts-ignore
import ky from 'ky-universal';
import { isBrowser } from 'utils';

const prefixUrl = new URL(
    isBrowser ? '/api/proxy' : '/api',
    isBrowser ? window.location.origin : process.env.API_URL
);

export const cmsApi = ky.create({
    prefixUrl,
    retry: {
        limit: 0,
    },
    headers: {
        'Content-Type': 'application/json; charset=utf-8',
    },
    hooks: {
        beforeRequest: [
            (request) => {
                console.log(`Request CMS API on ${isBrowser ? 'client' : 'server'}`);
                console.group();
                console.log('url: ', decodeURIComponent(request.url));
                console.log('body:', request.body);
                console.groupEnd();
            },
        ],
    },
});
