import { memo, PropsWithChildren, useEffect, useRef } from 'react';
import { useScrollLock, useFocusTrap, weakKey } from 'utils';
import { Text, Button } from '../../../';
import { useKey } from 'react-use';
import { animationVariants } from './animations';
import { AnimatePresence } from 'framer-motion';
import styles from './Overlay.module.css';
import clsx from 'clsx';
import { motion } from 'framer-motion';


export type Variants = 'full' | 'minimal' | 'slide' | 'page' | 'fade';

export type OverlayProps = {
    onDismiss: () => void;
    onLoad?: () => void;
    variant?: Variants;
    backdrop?: boolean;
    scrollLock?: boolean;
    open: boolean;
    headline?: string | JSX.Element;
    className?: string;
    dialogClassName?: string;
    text?: string;
};

export const Overlay = memo(
    ({
        onDismiss,
        onLoad,
        open = false,
        children,
        variant = 'minimal',
        backdrop = true,
        scrollLock = true,
        headline,
        className,
        dialogClassName,
        text
    }: PropsWithChildren<OverlayProps>) => {
        const overlayRef = useRef<HTMLDivElement>(null);
        const { lock, unlock } = useScrollLock();
        const variants = animationVariants(variant);
        const ariaDialogLabelledBy = `dialog${weakKey({ headline }).replace('-', '')}Title`; // Aria must not contain hyphens
        const ariaDialogDescribedBy = `dialog${weakKey({ headline }).replace('-', '')}Desc`; // Aria must not contain hyphens
        const isFullButton = variant === 'full' && !headline;
        useFocusTrap(overlayRef.current);

        useKey('Escape', onDismiss, {
            target: overlayRef.current,
        });

        useEffect(() => {
            if (open && scrollLock) {
                lock();
                return;
            }

            if (!open && scrollLock) {
                unlock();
                return;
            }
        }, [lock, open, scrollLock, unlock]);

        useEffect(() => {
            if (open) {
                onLoad?.();
            }
        }, [open, onLoad]);

        return (
            <AnimatePresence>
                {open && (
                    <div className={clsx(styles.overlayWrapper, {
                        [styles.slide]: variant === 'slide'
                    }, className)} ref={overlayRef}>
                        {backdrop && (
                            <motion.div
                                className={styles.backdrop}
                                key="backdrop"
                                onClick={onDismiss}
                                role="button"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                aria-label={'Close' /*translate('general.close')*/}
                            />
                        )}
                        <motion.div
                            className={clsx(styles.overlay, {
                                [styles.full]: variant === 'full',
                                [styles.minimal]: variant === 'minimal',
                                [styles.fadeSlide]: variant === 'slide' || variant === 'fade',
                                [styles.page]: variant === 'page'
                            }, dialogClassName)}
                            key="overlay"
                            variants={variants}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                            role="dialog"
                            aria-modal="true"
                            aria-labelledby={ariaDialogLabelledBy}
                            aria-describedby={ariaDialogDescribedBy}
                        >
                            <header className={clsx(styles.header, {
                                [styles.solid]: !!headline,
                                [styles.full]: variant === 'full'
                            })}>
                                {headline && (
                                    <Text
                                        id={ariaDialogLabelledBy}
                                        as="h2"
                                        style={{ fontWeight: 'bold' }}
                                    >
                                        {headline}
                                    </Text>
                                )}
                                <span>
                                    <Button
                                        onClick={onDismiss}
                                        variant={isFullButton ? 'secondary' : 'link'}
                                        iconName='close'
                                    />
                                    <span className={styles.closeText}> {text}</span>
                                </span>
                            </header>
                            <div id={ariaDialogDescribedBy}>{children}</div>
                        </motion.div>
                    </div>
                )
                }
            </AnimatePresence >
        );
    }
);
