import React, { FC } from 'react'
import styles from './ErrorBox.module.css'
import { useTranslations } from '~/services/cms';

interface IProps {
    text?: string;
    small?: boolean;
}

const ErrorBox: FC<IProps> = ({ text, small }) => {
    const translate = useTranslations()

    return (
        <div className={styles.root}>
            <div className={small ? styles.small : styles.large}>
                {text || translate('common.upsSomethingWentWrong')}
            </div>
        </div>
    )
}

export default ErrorBox