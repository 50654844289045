import React from 'react';

/**
 * Adds all favicons to the head
 *
 * @NOTE: This file is automatically generated from `/helpers/generate-favicon/createFaviconComponent.ts`
 */
export const Favicon = () => (
    <>
        <meta name="theme-color" content="#002AC9" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="application-name" content="Hovwdiaudi" />
        <meta name="apple-mobile-web-app-title" content="Hovwdiaudi" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <link rel="shortcut icon" href="/assets/favicon/favicon.ico" />
        <link rel="manifest" href="/assets/favicon/manifest.json" />
        <link rel="icon" type="image/png" sizes="48x48" href="/assets/favicon/favicon-48x48.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/assets/favicon/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/assets/favicon/favicon-16x16.png" />
        <link
            rel="apple-touch-icon"
            sizes="76x76"
            href="/assets/favicon/apple-touch-icon-76x76.png"
        />
        <link
            rel="apple-touch-icon"
            sizes="72x72"
            href="/assets/favicon/apple-touch-icon-72x72.png"
        />
        <link
            rel="apple-touch-icon"
            sizes="60x60"
            href="/assets/favicon/apple-touch-icon-60x60.png"
        />
        <link
            rel="apple-touch-icon"
            sizes="57x57"
            href="/assets/favicon/apple-touch-icon-57x57.png"
        />
        <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/assets/favicon/apple-touch-icon-180x180.png"
        />
        <link
            rel="apple-touch-icon"
            sizes="167x167"
            href="/assets/favicon/apple-touch-icon-167x167.png"
        />
        <link
            rel="apple-touch-icon"
            sizes="152x152"
            href="/assets/favicon/apple-touch-icon-152x152.png"
        />
        <link
            rel="apple-touch-icon"
            sizes="144x144"
            href="/assets/favicon/apple-touch-icon-144x144.png"
        />
        <link
            rel="apple-touch-icon"
            sizes="120x120"
            href="/assets/favicon/apple-touch-icon-120x120.png"
        />
        <link
            rel="apple-touch-icon"
            sizes="114x114"
            href="/assets/favicon/apple-touch-icon-114x114.png"
        />
    </>
);
