import NextImage, { ImageProps as NextImageProps } from 'next/image';
import { useState } from 'react';
import { defaultLoader } from './loaders/defaultLoader';
import { cloudflareLoader } from './loaders/cloudflareLoader';
import { StyledImageWrapper } from './styled';
import { getHostUrl } from 'utils';

export type ImageProps = NextImageProps & {
  disableSkeleton?: boolean;
  /**
   * Callback for when image is loaded
   */
  onLoadCallback?: () => void;
  focalPoint?: {
    left: number;
    rgiht: number;
  }
};

// @see https://github.com/vercel/next.js/blob/canary/packages/next/client/image.tsx
type OnLoadingComplete = {
  naturalWidth: number;
  naturalHeight: number;
};

export const Image = ({
  src = '',
  width: initialWidth = 0,
  height: initialHeight = 0,
  layout = 'responsive',
  disableSkeleton = false,
  onLoadCallback,
  alt = '',
  // @ts-ignore
  focalPoint,
  ...rest
}: ImageProps) => {
  const [isLoading, setIsLoading] = useState(true);

  /**
   * @TODO Look into using custom hook to cache src dimensions
   * to prevent additional layout shifts for repeated images
   */
  const [width, setWidth] = useState(initialWidth);
  const [height, setHeight] = useState(initialHeight);

  const setDimensions = layout !== 'fill';
  const isStatic = !/^http?s:\/\//.test(src.toString());
  const isSvg = src.toString().includes('.svg');

  const hostUrl = getHostUrl();
  const isProduction = !(hostUrl.includes('stag') || hostUrl.includes('dev') || hostUrl.includes('test'));
  const loader = isStatic ? undefined : isSvg ? defaultLoader : isProduction ? cloudflareLoader : defaultLoader;

  const onLoadHandler = ({ naturalWidth, naturalHeight }: OnLoadingComplete) => {
    const noDimensions = !width || !height;
    setIsLoading(false);
    onLoadCallback?.();
    /**
     * Setting naturalWidth and naturalHeight on the image element
     * if non provided.
     * isLoading ensures only initial render.
     * Updating dimensions on the image will retrigger onLoadingComplete.
     */
    if (isLoading && noDimensions && setDimensions) {
      setWidth(naturalWidth);
      setHeight(naturalHeight);
    }
  };

  return (
    <StyledImageWrapper loading={isLoading} disableSkeleton={disableSkeleton}>
      <NextImage
        loader={loader}
        src={src}
        onLoadingComplete={onLoadHandler}
        width={width}
        height={height}
        layout={layout}
        alt={alt}
        {...rest}
      />
    </StyledImageWrapper>
  );
};
