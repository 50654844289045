import React, { FC } from 'react';
import { Card, SkeletonCard } from './product/index';
import styles from './ProductList.module.css';
import { Text, Loader } from 'ui';
import { useTranslations } from '~/services/cms';
import { IFetchRelewiseProps, RelewiseProduct } from './hooks';
import DownloadProductList from '../DownloadProductList/DownloadProductList';



type Props = Omit<IFetchRelewiseProps, 'activeFacets' | 'disallowedProductsGroups' | 'locale'> & {
    products?: RelewiseProduct[];
    isLoading?: boolean;
    isFetching?: boolean;
    totalProducts: number;
    headline?: string;
    selectedVehicleTypeNumber?: number | null;
}


const SkeletonList = () => (
    <>
        {Array.from(Array(5).keys()).map((item) => (
            <SkeletonCard key={item} />
        ))}
    </>
)

export const ProductList: FC<Props> = ({
    products, totalProducts, isLoading, isFetching, headline,
    selectedVehicleTypeNumber, searchQuery, ...rest
}) => {
    const translate = useTranslations();


    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <div className={styles.headerLeft}>
                    <Text variant="body" className={styles.plpTitle}>
                        {headline ? headline : translate('product.products')}
                    </Text>
                    {isFetching && <Loader size='15px' borderWidth='2px' padding='0' />}
                </div>
                <div className={styles.headerRight}>
                    <Text variant="bodySmall">
                        {totalProducts} {translate('product.results')}
                    </Text>
                    <DownloadProductList
                        totalProductsCount={totalProducts}
                        searchQuery={searchQuery}
                        {...rest}
                    />
                </div>
            </div>

            <div className={styles.productList}>
                {isLoading
                    ? <SkeletonList />
                    : products?.map((product, index) => (
                        <Card
                            searchQuery={searchQuery}
                            key={`${index}-${product?.productId}`}
                            selectedVehicleTypeNumber={selectedVehicleTypeNumber}
                            product={product}
                        />
                    ))
                }
            </div>
        </div>
    );
};