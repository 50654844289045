import { ReactNode } from 'react';
import { StyledButtonContent } from '../styled';
import { VisuallyHidden } from '../../VisuallyHidden';

export const Content = ({
    description,
    children,
}: {
    description: string;
    children: ReactNode;
}) => {
    return (
        <>
            <StyledButtonContent aria-hidden="true">{children}</StyledButtonContent>
            <VisuallyHidden>{description}</VisuallyHidden>
        </>
    );
};
