import {
    BooleanAvailableFacetValue,
    BrandFacetResult,
    BrandNameAndIdResultAvailableFacetValue,
    //CategoryFacetResult,
    CategoryNameAndIdResultAvailableFacetValue,
    DataObjectBooleanValueFacetResult,
    DataObjectFacetResult,
    DataObjectStringValueFacetResult,
    StringAvailableFacetValue
} from '@relewise/client'
import React, { FC } from 'react'
import { MultipleSelector, MultipleSelectorOption, Skeleton } from 'ui';
import useFacets from '~/components/features/ProductsList/Facets/use-facets';
import styles from './FacetsFilter.module.css'
import { useTranslations } from '~/services/cms';
import { TranslationModel } from 'api-types';
import { IFacetsProps } from '../Facets';

const sortFacets = (
    a: StringAvailableFacetValue | BooleanAvailableFacetValue | CategoryNameAndIdResultAvailableFacetValue,
    b: StringAvailableFacetValue | BooleanAvailableFacetValue | CategoryNameAndIdResultAvailableFacetValue
) => {

    let nameA = ''
    let nameB = ''
    if (
        (typeof a?.value === 'object' && a?.value !== null)
        && (typeof b?.value === 'object' && b?.value !== null)
    ) {
        nameA = a?.value?.displayName?.toString()?.toUpperCase() || ''
        nameB = b?.value?.displayName?.toString()?.toUpperCase() || ''
    } else {
        nameA = a?.value?.toString()?.toUpperCase() || ''
        nameB = b?.value?.toString()?.toUpperCase() || ''
    }

    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }
    // names must be equal
    return 0;
}

const linkagesFacetsToHideOnMobile = ['BodyType', 'FuelType']


export const FacetsFilter: FC<IFacetsProps> = ({ relewiseFacets, isFetching, isLoading, isMobile }) => {
    const translate = useTranslations()
    const { activeFacets, setFacet } = useFacets()

    const isActive = (field: string, value: string | boolean): boolean => {
        const selectedFiled = activeFacets.find(af => af.key?.toString() === field?.toString())
        if (selectedFiled) {
            return selectedFiled?.values.some(sf => sf?.toString() === value?.toString())
        }
        return false
    }

    if (isLoading) return (
        <div className={styles.root}>
            {Array.from(Array(9).keys()).map((item) => (
                <Skeleton width='100%' height='50px' key={item} />
            ))}
        </div>
    )

    if (!relewiseFacets) return null

    const dataObjectFacets = relewiseFacets?.items?.filter(f => f.field === 'Data') as DataObjectFacetResult[] || []

    const brandFacet = relewiseFacets?.items?.find(f => f.field === 'Brand') as BrandFacetResult || null
    const brandAvailableOrdered = brandFacet !== null ?
        brandFacet?.available?.sort((a: BrandNameAndIdResultAvailableFacetValue, b: BrandNameAndIdResultAvailableFacetValue) => {
            if (!a?.value?.displayName || !b?.value?.displayName)
                return 0;

            if (a?.value?.displayName < b?.value?.displayName) {
                return -1;
            }
            if (a?.value?.displayName > b?.value?.displayName) {
                return 1;
            }
            return 0;
        })
        : null;
    brandFacet.available = brandAvailableOrdered;

    // const categoryFacet = relewiseFacets?.items?.find(f => f.field === 'Category') as CategoryFacetResult || null

    // dataFacets
    const remarkFacet = dataObjectFacets.find(f => f.key === 'Remark') as DataObjectStringValueFacetResult || null
    const newMonthFacet = dataObjectFacets.find(f => f.key === 'NewMonth') as DataObjectStringValueFacetResult || null


    // linkagesFacets
    const linkagesFacets = dataObjectFacets
        .find(f => f.key === 'Linkages')?.items as DataObjectStringValueFacetResult[] | DataObjectBooleanValueFacetResult[] || []

    // In case we need ot hardcode the order of the linkagesFacets
    // const vehicleManufacturerFacets = linkagesFacets?.find(f => f.key === 'Manufacturer') as DataObjectStringValueFacetResult || null
    // const vehicleTypeFacets = linkagesFacets?.find(f => f.key === 'VehicleType') as DataObjectStringValueFacetResult || null
    // const vehicleModelSeriesFacets = linkagesFacets?.find(f => f.key === 'ModelSeries') as DataObjectStringValueFacetResult || null
    // const vehicleFuelTypeFacets = linkagesFacets?.find(f => f.key === 'FuelType') as DataObjectStringValueFacetResult || null
    // const vehicleProductionYearsFacets = linkagesFacets?.find(f => f.key === 'ProductionYears') as DataObjectStringValueFacetResult || null




    const getLabelName = (
        facetKey: string | null = '',
        value: string | boolean | null = ''
    ): string => {
        if (typeof (value) === 'string') {
            return value || ''
        } else if (typeof (value) === 'boolean') {
            // Handle different boolean case values
            // at the moment we have only 'IsClassic'
            switch (facetKey) {
                case 'IsClassic':
                    return value
                        ? translate('facets.isClassicVehicle')
                        : translate('facets.isNotClassicVehicle')
                default:
                    return value ? 'True' : 'False'
            }
        }
        return ''
    }

    return (
        <div className={styles.root}>
            {linkagesFacets?.map(linkagesFacet => {
                const facetKey = linkagesFacet?.key as string
                const shouldHideOnMobile = isMobile && linkagesFacetsToHideOnMobile.includes((facetKey as string))

                if (shouldHideOnMobile || !linkagesFacet?.available?.length) return null
                return (
                    <MultipleSelector
                        key={facetKey}
                        label={translate(`facets.linkages${facetKey}` as TranslationModel.TranslationModel)}
                        isFetching={isFetching}
                    >
                        {linkagesFacet?.available
                            ?.sort(sortFacets)
                            ?.map((f, i) => {
                                const facetValueAsString = f?.value?.toString() as string
                                return (
                                    <MultipleSelectorOption
                                        disabled={isFetching || !f?.hits}
                                        key={`${facetValueAsString}-${i}`}
                                        label={getLabelName(facetKey, f?.value)}
                                        secondaryText={`(${f?.hits})`}
                                        onClick={() => setFacet(facetKey, facetValueAsString)}
                                        isSelected={isActive(facetKey, facetValueAsString)}
                                    />
                                )
                            })}
                    </MultipleSelector>
                )
            })}

            {remarkFacet?.available?.length ? (
                <MultipleSelector label={remarkFacet?.key as string} isFetching={isFetching}>
                    {remarkFacet?.available
                        ?.sort(sortFacets)
                        ?.map((f, i) => (
                            <MultipleSelectorOption
                                disabled={isFetching || !f?.hits}
                                key={`${f?.value}-${i}`}
                                label={f?.value as string}
                                secondaryText={`(${f?.hits})`}
                                onClick={() => setFacet(remarkFacet?.key as string, f?.value as string)}
                                isSelected={isActive(remarkFacet?.key as string, f?.value as string)}
                            />
                        ))}
                </MultipleSelector>
            ) : null}

            {brandFacet?.available?.length && !isMobile ? (
                <MultipleSelector label={translate('facets.brand')} isFetching={isFetching}>
                    {brandFacet?.available
                        ?.map((f, i) => (
                            <MultipleSelectorOption
                                disabled={isFetching || !f?.hits}
                                key={`${f?.value?.id}-${i}`}
                                label={f?.value?.displayName as string}
                                secondaryText={`(${f?.hits})`}
                                onClick={() => setFacet(brandFacet?.field, f?.value?.id as string)}
                                isSelected={isActive(brandFacet?.field, f?.value?.id as string)}
                            />
                        ))}
                </MultipleSelector>
            ) : null}
            {newMonthFacet?.available?.length ? (
                <MultipleSelector label={translate('facets.newMonth.title')} isFetching={isFetching}>
                    {newMonthFacet?.available
                        ?.sort(sortFacets)
                        ?.map((f, i) => (
                            <MultipleSelectorOption
                                disabled={isFetching || !f?.hits}
                                key={`${f?.value}-${i}`}
                                label={translate(`newMonth.month${f.value as string}` as TranslationModel.TranslationModel)}
                                secondaryText={`(${f?.hits})`}
                                onClick={() => setFacet(newMonthFacet?.key as string, f?.value as string)}
                                isSelected={isActive(newMonthFacet?.key as string, f?.value as string)}
                            />

                        ))}
                </MultipleSelector>
            ) : null}


            {/* {categoryFacet?.available?.length && !isMobile ? (
                <MultipleSelector label={translate('facets.category')} isFetching={isFetching}>
                    {categoryFacet?.available
                        ?.sort(sortFacets)
                        ?.map((f, i) => (
                            <MultipleSelectorOption
                                disabled={isFetching || !f?.hits}
                                key={`${f?.value?.id}-${i}`}
                                label={f?.value?.displayName as string}
                                secondaryText={`(${f?.hits})`}
                                onClick={() => setFacet(categoryFacet?.field, f?.value?.id as string)}
                                isSelected={isActive(categoryFacet?.field, f?.value?.id as string)}
                            />
                        ))}
                </MultipleSelector>
            ) : null} */}

        </div>
    )
}
