import { useState } from 'react';
import { ImageProps, Image } from '../../../';
import { StyledImageWrapper } from './Styled';
import { queriesIsolated } from '../../../theme';

export type Variant = 'fallback' | 'product' | 'model';

type Props = {
    image?: string;
    index?: number;
} & Omit<ImageProps, 'src'>;

/**
 * HOC that adds a wrapper around the image component
 * Which handles specific styling based on image type
 * @returns
 */
export const ProductImage = ({ image, index, priority, onLoadCallback, ...rest }: Props) => {
    const [isProductImageLoaded, setIsProductImageLoaded] = useState(false);
    const isPriority = (index !== undefined && index < 4) || !!priority;

    // Default sizes
    const sizes = `${queriesIsolated.xs} 50vw, ${queriesIsolated.sm} 30vw, ${queriesIsolated.md} 22vw, 16vw`;

    return (
        <StyledImageWrapper
            variant={'product'}
            isLoaded={isProductImageLoaded || isPriority}
        >
            <Image
                src={image!}
                sizes={sizes}
                width={2}
                height={2}
                priority={isPriority}
                objectFit="contain"
                className="product-image"
                onLoadCallback={() => {
                    onLoadCallback?.();
                    setIsProductImageLoaded(true);
                }}
                onError={() => {
                    setIsProductImageLoaded(true)
                }}
                {...rest}
            />
        </StyledImageWrapper>
    );
};
