import NextLink from 'next/link';
import React, { memo } from 'react';
import { CmsModel } from 'api-types';
import { Image } from '../../../';
import { StyledImageWrapper, StyledLink, StyledUsp } from './styled';

export type UspProps = {
    uspItem?: CmsModel.UspItem;
    shade?: 'light' | 'dark';
};

export const Usp = memo(({ uspItem, shade = 'light' }: UspProps) => {
    if (!uspItem) {
        return <></>;
    }

    const { link, text, image } = uspItem;

    const child = (
        <StyledUsp tabIndex={link?.url ? undefined : 0} shade={shade}>
            {image && (
                <StyledImageWrapper>
                    <Image
                        src={image.src}
                        alt={image.alt}
                        width="25"
                        height="18"
                        sizes="25px"
                        objectFit="contain"
                        disableSkeleton
                    />
                </StyledImageWrapper>
            )}
            {text}
        </StyledUsp>
    );

    return link?.url ? (
        <NextLink href={link.url} passHref prefetch={false}>
            <StyledLink title={link.title} target={link.target}>
                {child}
            </StyledLink>
        </NextLink>
    ) : (
        child
    );
});
