import React from 'react';
import { useTranslations } from '~/services/cms';
import { Text } from 'ui';

import styles from './Stock.module.css';
import clsx from 'clsx';

type Props = { inStock?: boolean };

export const Stock = ({ inStock = false }: Props) => {
    const translate = useTranslations();

    const text = inStock ? translate('commerce.stock.available') : translate('commerce.stock.unavailable');

    return (
        <div className={clsx(styles.stock)}>
            <div className={clsx(styles.stockBullet, {
                [styles.available]: inStock,
                [styles.unavailable]: !inStock
            })} />
            <Text className={clsx(styles.dispensableText)} variant="bodyExtraSmall">{text}</Text>
        </div>
    );
};
