import React, { FC } from 'react'
import styles from './PageHeaderContainer.module.css'
import { ModuleContainer } from 'ui';

interface IProps {
    children?: React.ReactNode;
    hidePadding?: boolean;
}

const PageHeaderContainer: FC<IProps> = ({ children, hidePadding }) => (
    <div className={hidePadding ? styles.root : styles.rootWithPadding}>
        <ModuleContainer hasGutter hasPadding={false}>
            {children}
        </ModuleContainer>
    </div>
)

export default PageHeaderContainer