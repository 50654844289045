import { useMemo } from 'react';
import { useBasket } from '$features/Basket';

type Props = string | undefined;

export interface CountryRules {
    countryCode?: string;
    zipcodeFallback?: string;
    phonePrefix?: string;
    zipcodeRegex?: string;
    phoneRegex?: string;
    minimumPhoneLength?: number;
    maximumPhoneLength?: number;
    debitorCode?: string;
    tailorCountryCode?: string;
}

/**
 * Use this hook to get the validation rules for a country.
 * @param props
 * @returns {CountryRules}
 * @example
 * const { rules } = useValidationRules({ countryCode: 'US' });
 * const { rules: rules2 } = useValidationRules({ countryCode: 'CA' });
 * console.log(rules.zipCode.maxLength); // 5
 * console.log(rules2.zipCode.maxLength); // 7
 */
export const useValidationRules = (countryCodeProp?: Props): CountryRules => {
    const { data: basket } = useBasket();

    const countryCodeToHit =
        countryCodeProp || basket?.deliveryInformationViewModel?.invoiceAddress?.countryCode;

    const harcodedValidationRules = [
        {
            countryCode: 'DK',
            zipcodeFallback: '9200',
            phonePrefix: '45',
            zipcodeRegex: '\\w',
            phoneRegex: null,
            minimumPhoneLength: 8,
            maximumPhoneLength: 20,
            debitorCode: '008',
            tailorCountryCode: 'WEB',
            alias: 'countryRules',
        },
        {
            countryCode: 'DE',
            zipcodeRegex: '\\w',
            phoneRegex: null,
            minimumPhoneLength: 8,
            maximumPhoneLength: 20,
            debitorCode: '008',
            tailorCountryCode: 'WEB',
            alias: 'countryRules',
        },
        {
            countryCode: 'FR',
            zipcodeRegex: '\\w',
            phoneRegex: null,
            minimumPhoneLength: 8,
            maximumPhoneLength: 20,
            debitorCode: '008',
            tailorCountryCode: 'WEB',
            alias: 'countryRules',
        },
        {
            countryCode: 'EN',
            zipcodeRegex: '\\w',
            phoneRegex: null,
            minimumPhoneLength: 8,
            maximumPhoneLength: 20,
            debitorCode: '008',
            tailorCountryCode: 'WEB',
            alias: 'countryRules',
        },
    ];

    const validationRules = useMemo(
        () =>
            harcodedValidationRules?.find(
                ({ countryCode }) =>
                    countryCode.toLocaleLowerCase() === countryCodeToHit?.toLocaleLowerCase()
            ) || {},
        [countryCodeToHit]
    );

    return validationRules;
};
