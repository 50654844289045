import { queries } from '../../../queries';
import type { PrefetchShared } from '../../../types';
import { getMarkets } from './get-markets';
import { GlobalizationModel } from 'api-types';

type Markets = { markets: GlobalizationModel.Market[] };

export async function prefetchMarkets({ queryClientPage, queryClientShared }: PrefetchShared) {
  const key = queries.globalization.markets;

  await queryClientShared.fetchQuery(key, getMarkets);

  return queryClientPage.setQueryData<Markets>(
    key,
    () => queryClientShared.getQueryData(key),
    {
      updatedAt: queryClientShared.getQueryState(key)?.dataUpdatedAt,
    }
  );
}
