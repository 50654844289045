import styled from '@emotion/styled';
import { switchProp } from 'styled-tools';

export const StyledWrapper = styled.div(({ theme }) => ({
    fontFamily: `${theme.fonts.body}`,
    color: `${theme.colors.black}`,
    display: 'block',
    width: '100%',
    maxWidth: '100%',
}));

export const StyledLink = styled.a({
    textDecoration: 'none',
    display: 'block',
});

export const StyledThumbnail = styled.figure({
    margin: 0,
    width: '100%',
    position: 'relative',
    cursor: 'pointer',

    img: {
        display: 'block',
        width: '100%',
        height: 'auto',
    },
});

export const StyledDetails = styled.div(
    ({ theme }) => ({
        padding: theme.spaces[2],
        display: 'flex',
        flexDirection: 'column',
    }),
    switchProp('alignment', {
        true: {
            color: '$grey50',
        },
    })
);

export const StyledName = styled.a(({ theme }) => ({
    fontSize: theme.fontSizes.xs,
    lineHeight: '16px',
    color: `${theme.colors.black}`,
    fontWeight: theme.fontWeights.regular,
    marginBottom: theme.spaces[1],
    letterSpacing: '0.5px',
    width: '100%',
    textDecoration: 'none',
    display: 'inline-grid',
    cursor: 'pointer',

    span: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}));

export const StyledBrand = styled.a(({ theme }) => ({
    fontSize: theme.fontSizes['2xs'],
    lineHeight: '14px',
    fontWeight: theme.fontWeights.regular,
    marginBottom: theme.spaces[1],
    color: theme.colors.grey50,
    letterSpacing: '0.5px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    textDecoration: 'none',
}));
