import React, { useState } from 'react';
import { Button } from 'ui';
import { useBasket } from '$features/Basket';
import styles from './FilterList.module.css';
import clsx from 'clsx';
import { useTranslations } from '~/services/cms';

type Props = {
    onFilter?: (selectedFilter: filtersEnum | null) => void;
};

export enum filtersEnum {
    OutOfStock = '0',
    ExpectedDate = '3',
}

export const FilterList = (Props: Props) => {
    const { data: basket } = useBasket();
    const [selectedValue, setSelectedValue] = useState<filtersEnum | null>(null);
    const translate = useTranslations();
    var outOfStockCount = basket?.lines?.filter(line => line.trafficLight == filtersEnum.OutOfStock).length;
    var expectedDateCount = basket?.lines?.filter(line => line.trafficLight == filtersEnum.ExpectedDate).length;

    const onFilterChange = (selectedFilter: filtersEnum) => {
        if (selectedValue == selectedFilter) {
            setSelectedValue(null);
        }
        else {
            setSelectedValue(selectedFilter);
        }

        Props.onFilter?.(selectedFilter);
    }

    return (
        <div className={styles.filterContainer}>
            <Button className={clsx(styles.filterBtn, {
                [styles.selected]: selectedValue === filtersEnum.OutOfStock
            })}
                variant={selectedValue === filtersEnum.OutOfStock ? 'primary' : 'secondary'}
                onClick={() => onFilterChange(filtersEnum.OutOfStock)}>
                {translate('commerce.basket.filter.outOfStock')}: {outOfStockCount}
            </Button>
            <Button className={clsx(styles.filterBtn, {
                [styles.selected]: selectedValue === filtersEnum.ExpectedDate
            })}
                variant={selectedValue === filtersEnum.ExpectedDate ? 'primary' : 'secondary'}
                onClick={() => onFilterChange(filtersEnum.ExpectedDate)}>
                {translate('commerce.basket.filter.expectedDate')}: {expectedDateCount}
            </Button>
        </div >
    );
}