import { CommerceModel } from 'api-types';
import { postRequest } from '~/shared/utils';

export async function updateShipping(
    args: CommerceModel.PostShippingInformationRequest,
    apiUrl: string,
    token?: string
) {
    const request = await postRequest<CommerceModel.Basket>(apiUrl, args, {
        'Authorization': `Bearer ${token}`
    });

    const response = await request();

    return response;
}
