import React from 'react';
import { StyledBadgesWrap, StyledBadge } from './styled';

export interface BadgesProps {
    items: string[];
}

export const Badges = ({ items }: BadgesProps) => {
    return (
        <StyledBadgesWrap>
            {items.map((item, index) => (
                <StyledBadge key={index}>{item}</StyledBadge>
            ))}
        </StyledBadgesWrap>
    );
};
