import { useRawHtml } from './hooks';
import clsx from 'clsx';

import styles from './RawHtml.module.css';

export type RawHtmlProps = {
    html?: string;
    className?: string;
};

export const RawHtml = ({ html = '', className, ...rest }: RawHtmlProps) => {
    const markup = useRawHtml(html);
    return (
        <div className={clsx(styles.richText, className)} {...rest} >
            {markup}
        </div>
    );
};
