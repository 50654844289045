import { useQuery } from '@tanstack/react-query';
import { GlobalizationModel } from 'api-types';
import { minutes, seconds } from 'utils';
import { queries } from '../../../queries';
import { getMarkets } from './get-markets';
import type { MarketsErrors } from './types';

type Markets = { markets: GlobalizationModel.Market[] };

export function useMarkets() {
    //@ts-ignore
    return useQuery<Markets, MarketsErrors, Markets>(queries.globalization.markets, getMarkets, {
        retry: 1,
        staleTime: minutes(10),
        cacheTime: minutes(60),
        retryDelay: seconds(10),
        keepPreviousData: true,
        refetchOnWindowFocus: false,
    });
}
