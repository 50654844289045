import { breakpoints } from '../../../theme';
import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';

type StyledHeaderProps = {
    showMeta: boolean;
};

export const StyledHeader = styled.div<StyledHeaderProps>(({ theme, showMeta }) => ({
    display: 'grid',
    alignItems: 'end',
    gap: theme.spaces['2'],
    marginBottom: theme.spaces['5'],
    justifyItems: 'start',
    justifyContent: 'space-between',
    gridTemplateAreas: `
        'headline meta'
        'link meta'
    `,
    [breakpoints.sm]: {
        gridTemplateAreas: showMeta ? `'headline link meta'` : `'headline link'`,
        marginBottom: theme.spaces['6'],
    },
}));

export const StyledArea = styled.div<{ area: 'headline' | 'link' | 'meta' }>(
    ({ area }) => ({
        gridArea: area,
    }),
    ifProp(
        { area: 'meta' },
        {
            display: 'flex',
            justifyContent: 'end',
            width: '100%',
        }
    )
);
