import type { PrefetchShared } from '../../../types';
import { getProduct } from './get-product';
import { ProductErrors } from './types';
import { CatalogueModel } from 'api-types';

interface Props extends PrefetchShared {
    market: string;
    locale: string;
    productId: string;
    variantId: string;
}


export async function prefetchProduct({ queryClientPage, queryClientShared, ...rest }: Props) {
    return queryClientPage.fetchQuery<CatalogueModel.ProductVariant, ProductErrors>({
        queryKey: ['ProductPage', rest],
        queryFn: () => getProduct({ ...rest })
    }
    );
}

