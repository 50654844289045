import React, { FC, useState } from 'react'
import { useTranslations } from '~/services/cms';
import { useNotification } from '~/shared/hooks/useNotification';
import { useAuthentication, usePrefixUrl } from '~/shared/hooks';
import { Button } from 'ui';


interface IProps {
    fileName?: string
}

const DownloadCustomerPrices: FC<IProps> = ({ fileName }) => {
    const translate = useTranslations();
    const { catalogueBaseUrl } = usePrefixUrl()
    const { push } = useNotification();
    const { session } = useAuthentication();
    const [isLoading, setIsLoading] = useState(false);

    function formatDate(timestamp: number): string {
        const date = new Date(timestamp);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based in JavaScript
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    }


    const url = `${catalogueBaseUrl}/priceslist`

    const getPrices = async () => {
        setIsLoading(true)
        try {
            const res = await fetch(url, {
                headers: {
                    'Authorization': `Bearer ${session?.accessToken}`,
                    'Content-Type': 'application/zip'
                }
            })
            const fileData = await res.blob()
            var blob = new Blob([fileData], { type: 'application/zip' });
            if (blob?.size === 0) {
                return push({ severity: 'warning', text: translate('common.upsSomethingWentWrong') })
            }

            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${fileName?.replace(/ /g, '-') || 'download'}-${formatDate(Date.now())}.zip`
            link.target = '_blank'
            link.click();
        } catch (error) {
            push({ severity: 'warning', text: translate('common.upsSomethingWentWrong') });
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Button onClick={getPrices} variant='secondary' isLoading={isLoading}>  {translate('profile.downloadPriceList')}</Button>
    )
}

export default DownloadCustomerPrices