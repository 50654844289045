import { FC, memo, SVGAttributes, ComponentType } from 'react';

// Import Icons
import ArrowDown from './icons/ArrowDown';
import ArrowLeft from './icons/ArrowLeft';
import ArrowRight from './icons/ArrowRight';
import ArrowUp from './icons/ArrowUp';
import BellOff from './icons/BellOff';
import BellOn from './icons/BellOn';
import Check from './icons/Check';
import ChevronDown from './icons/ChevronDown';
import ChevronUp from './icons/ChevronUp';
import ChevronLeft from './icons/ChevronLeft';
import ChevronRight from './icons/ChevronRight';
import Close from './icons/Close';
import Download from './icons/Download';
import Edit from './icons/Edit';
import Eye from './icons/Eye';
import EyeOff from './icons/EyeOff';
import Express from './icons/Express';
import FilePlus from './icons/FilePlus';
import Filter from './icons/Filter';
import Info from './icons/Info';
import Key from './icons/Key';
import List from './icons/List';
import Login from './icons/Login';
import Logo from './icons/Logo';
import Maximize from './icons/Maximize';
import Menu from './icons/Menu';
import Minus from './icons/Minus';
import Search from './icons/Search';
import Play from './icons/Play';
import Plus from './icons/Plus';
import ShoppingCart from './icons/ShoppingCart';
import Sliders from './icons/Sliders';
import Star from './icons/Star';
import Upload from './icons/Upload';
import User from './icons/User';
import Volume from './icons/Volume';
import Delete from './icons/Delete';

export type IconTypes =
	| 'arrow-down'
	| 'arrow-left'
	| 'arrow-right'
	| 'arrow-up'
	| 'bell-off'
	| 'bell-on'
	| 'check'
	| 'chevron-down'
	| 'chevron-up'
	| 'chevron-left'
	| 'chevron-right'
	| 'close'
	| 'download'
	| 'edit'
	| 'eye'
	| 'eye-off'
	| 'express'
	| 'file-plus'
	| 'filter'
	| 'info'
	| 'key'
	| 'list'
	| 'login'
	| 'logo'
	| 'maximize'
	| 'menu'
	| 'minus'
	| 'search'
	| 'play'
	| 'plus'
	| 'shopping-cart'
	| 'sliders'
	| 'star'
	| 'upload'
	| 'user'
	| 'volume'
	| 'delete'
	;

export type Icons = {
	[key in IconTypes]?: ComponentType | any;
};

export interface IProps extends SVGAttributes<SVGElement> {
	name: IconTypes;
}

export const SvgIcon: FC<IProps> = memo((props) => {
	const { name, ...svgProps } = props;

	const icons: Icons = {
		['arrow-down']: ArrowDown,
		['arrow-left']: ArrowLeft,
		['arrow-right']: ArrowRight,
		['arrow-up']: ArrowUp,
		['bell-off']: BellOff,
		['bell-on']: BellOn,
		['check']: Check,
		['chevron-down']: ChevronDown,
		['chevron-up']: ChevronUp,
		['chevron-left']: ChevronLeft,
		['chevron-right']: ChevronRight,
		['close']: Close,
		['download']: Download,
		['edit']: Edit,
		['eye']: Eye,
		['eye-off']: EyeOff,
		['express']: Express,
		['file-plus']: FilePlus,
		['filter']: Filter,
		['info']: Info,
		['key']: Key,
		['list']: List,
		['login']: Login,
		['logo']: Logo,
		['maximize']: Maximize,
		['menu']: Menu,
		['minus']: Minus,
		['search']: Search,
		['play']: Play,
		['plus']: Plus,
		['shopping-cart']: ShoppingCart,
		['sliders']: Sliders,
		['star']: Star,
		['upload']: Upload,
		['user']: User,
		['volume']: Volume,
		['delete']: Delete
	}

	const Svg = icons[name];

	if (!Svg) return null;

	return (
		<Svg {...svgProps} />
	);
});

//fixes missing display name error
SvgIcon.displayName = 'SvgIcon';
