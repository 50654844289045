import React, { FC, useState, useMemo, useCallback, useRef, KeyboardEvent } from 'react';
import { useClickAway } from 'react-use';
import { Button, DropdownItem } from '../../../';
import { deepEqual } from 'utils';

import styles from './Dropdown.module.css';
import clsx from 'clsx';

type ValueType = string | number | unknown;

export interface DropdownProps {
    items: DropdownItem[];
    value: ValueType;
    onChange: (value: ValueType) => void;
    name: string;
    placeholder?: string;
}

export const Dropdown: FC<DropdownProps> = ({ items, value, onChange, name, placeholder }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const currentLabel = useMemo(() => {
        return items?.find((item) => item.value === value)?.label || '';
    }, [items, value]);

    const setValue = useCallback(
        (newValue: ValueType) => {
            onChange(newValue);
        },
        [onChange]
    );

    useClickAway(
        dropdownRef,
        (e: MouseEvent) => {
            if (dropdownRef?.current?.contains(e.target as HTMLElement)) return;
            setIsOpen(false);
        },
        ['click']
    );

    if (!items.length) return null;

    // Event handler for keydowns
    const handleKeyDown = (e: KeyboardEvent, selectedValue?: ValueType) => {
        const selectedIndex = items.findIndex((item) => deepEqual(item.value, value));

        switch (e.key) {
            case 'Tab':
                setIsOpen(false);
                break;
            case 'Escape':
                e.preventDefault();
                setIsOpen(false);
                break;
            case 'Home':
                if (dropdownRef?.current !== undefined) {
                    (dropdownRef.current?.querySelector(
                        'li:first-of-type span'
                    ) as HTMLElement)?.focus();
                }
                break;
            case 'End':
                if (dropdownRef?.current !== undefined) {
                    (dropdownRef.current?.querySelector(
                        'li:last-of-type span'
                    ) as HTMLElement)?.focus();
                }
                break;
            case 'ArrowDown':
                if (!isOpen) {
                    setIsOpen(true);
                }
                if (selectedIndex == -1) {
                    setValue(items[0].value);
                } else if (items.length > selectedIndex + 1) {
                    setValue(items[selectedIndex + 1].value);
                }
                break;
            case 'ArrowUp':
                if (!isOpen) {
                    setIsOpen(true);
                }
                if (selectedIndex == -1) {
                    setValue(items[0].value);
                } else if (0 <= selectedIndex - 1) {
                    setValue(items[selectedIndex - 1].value);
                }
                break;
            case 'Enter':
                e.preventDefault();
                if (selectedValue) {
                    setValue(selectedValue);
                }
                setIsOpen(false);
                break;
            default:
                break;
        }
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen)
    }

    return (
        <div className={styles.dropdownContainer} ref={dropdownRef}>
            <Button className={clsx(styles.dropdownTrigger, {
                [styles.open]: isOpen
            })}
                aria-haspopup="listbox"
                variant='secondary'
                aria-expanded={isOpen}
                aria-labelledby={name}
                onClick={toggleDropdown}
                onKeyDown={handleKeyDown}
                iconName={isOpen ? 'chevron-up' : 'chevron-down'}
                iconPosition='right'
            >
                <label className={styles.dropdownTitle} id={name}>{currentLabel || placeholder}</label>
            </Button>
            {isOpen ? (
                <ul className={styles.dropdownList}
                    role="listbox"
                    aria-activedescendant={`${name}-${value}`}
                    aria-labelledby={name}
                    tabIndex={-1}
                >
                    {items.map((item) => (
                        <li className={styles.listItem}
                            id={item.value.toString()}
                            role="option"
                            aria-selected={value === item.value}
                            key={item.value.toString()}
                            aria-labelledby={item.value.toString()}
                            onClick={() => {
                                setValue(item.value);
                                setIsOpen(false);
                            }}
                        >
                            <span className={clsx(styles.listOption, {
                                [styles.selected]: value === item.value
                            })}
                                id={`${name}-${item.value}`}
                                aria-label={item.value.toString()}
                                onKeyDown={(e) => handleKeyDown(e, item.value)}
                                tabIndex={0}
                            >
                                {item.label}
                            </span>
                        </li>
                    ))}
                </ul>
            ) : null}
        </div>
    );
};
