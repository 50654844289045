import { Text } from '../../../';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import React, { useCallback, useEffect, useState } from 'react';
import { StyledArrow, StyledContent } from './styled';

type TooltipProps = {
    children: React.ReactNode;
    content: React.ReactNode;
    delayDuration?: number;
};
export const TooltipDeprecated = ({ children, content, delayDuration }: TooltipProps) => {
    // Undefined to prevent overwriding default behaivour
    const [isPressed, setIsPressed] = useState(false);

    /**
     * Radix tooltip does not handle mobile focus/click
     * Helper method for supporting users interacting on mobile
     */
    const onTouchStartHandler = useCallback(() => {
        setIsPressed(true);

        // Prevent propagating into newly created touch event
        requestAnimationFrame(() => {
            window.addEventListener('touchstart', onTouchOutside);
        });
    }, []);

    // Store touch handler
    const onTouchOutside = useCallback(function onTouchOutside() {
        setIsPressed(false);
        window && window.removeEventListener('touchstart', onTouchOutside);
    }, []);

    // Clean up when unmounted
    useEffect(
        () => () => {
            window && window.removeEventListener('touchstart', onTouchOutside);
        },
        []
    );

    return (
        <TooltipPrimitive.Provider>
            <TooltipPrimitive.Root delayDuration={delayDuration} open={isPressed || undefined}>
                <TooltipPrimitive.Trigger asChild onTouchStart={onTouchStartHandler}>
                    {children}
                </TooltipPrimitive.Trigger>
                <StyledContent sideOffset={5}>
                    <Text children={content} />
                    <StyledArrow />
                </StyledContent>
            </TooltipPrimitive.Root>
        </TooltipPrimitive.Provider>
    );
};
