import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { Severity } from '~/shared/hooks/useNotification/useNotificationModel';
import { switchProp } from 'styled-tools';

export const StyledList = styled(motion.ul)(() => ({
    position: 'fixed',
    bottom: 25,
    left: 0,
    right: 0,
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 10,
    zIndex: 1,
    pointerEvents: 'none',
}));

export const StyledNotification = styled(motion.div)<{ variant: Severity }>(
    ({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        background: theme.colors.black,
        color: theme.colors.white,
        fontSize: theme.fontSizes['xs'],
        borderRadius: 3,
        zIndex: theme.zIndices[4],
        overflow: 'hidden',
        pointerEvents: 'auto',
        cursor: 'pointer',
    }),
    switchProp('variant', {
        error: ({ theme }) => ({
            backgroundColor: theme.colors.negative,
        }),
        warning: ({ theme }) => ({
            backgroundColor: theme.colors.neutral,
        }),
        info: ({ theme }) => ({
            backgroundColor: theme.colors.black,
        }),
        success: ({ theme }) => ({
            backgroundColor: theme.colors.positive,
        }),
    })
);

export const StyleContent = styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.spaces[2]} 0 ${theme.spaces[2]} ${theme.spaces[5]}`,
}));

export const StyledCloseButton = styled.button(({ theme: { colors, spaces, fontSizes } }) => ({
    display: 'flex',
    justifyContent: 'center',
    marginLeft: 'auto',
    background: 'none',
    border: 'none',
    color: colors.white,
    padding: spaces[2],
    cursor: 'pointer',
    maxWidth: 580,
    fontSize: fontSizes.xs,
}));
