import { theme } from './theme';

export const breaks = {
    /**
     * Mobile devices. Use orientation media queries for landscape styling
     */
    xs: 767,
    /**
     * Small tablets
     */
    sm: 768,
    /**
     * Large tablets, small laptops
     */
    md: 1024,
    /**
     * Desktops, laptops
     */
    lg: 1367,
    /**
     * Wide screens
     */
    xl: 1730,

    /**
     * Point where content stops growing
     */
    contentMax: theme.sizes.contentMaxWidth,

    /**
     * Beyond max content width
     */
    pageMax: theme.sizes.pageMaxWidth,
};

export const queries = {
    xs: `(max-width: ${breaks.xs}px)`,
    sm: `(min-width: ${breaks.sm}px)`,
    md: `(min-width: ${breaks.md}px)`,
    lg: `(min-width: ${breaks.lg}px)`,
    xl: `(min-width: ${breaks.xl}px)`,
    contentMax: `(min-width: ${breaks.contentMax}px)`,
    pageMax: `(min-width: ${breaks.pageMax}px)`,
    reducedMotion: `(prefers-reduced-motion)`,
};

export const breakpoints = {
    xs: `@media ${queries.xs}`,
    sm: `@media ${queries.sm}`,
    md: `@media ${queries.md}`,
    lg: `@media ${queries.lg}`,
    xl: `@media ${queries.xl}`,
    contentMax: `@media ${queries.contentMax})`,
    pageMax: `@media ${queries.pageMax}`,

    /**
     * Prefers reduced motion
     */
    reducedMotion: `@media ${queries.reducedMotion}`,
};

export const queriesIsolated = {
    xs: `(max-width: ${breaks.xs}px)`,
    sm: `(min-width: ${breaks.sm}px) and (max-width: ${breaks.md - 0.01}px)`, // The 0.01px it's used here because HDPI screens (specially in Windows) can have a width not integer.
    md: `(min-width: ${breaks.md}px) and (max-width: ${breaks.lg - 0.01}px)`,
    lg: `(min-width: ${breaks.lg}px) and (max-width: ${breaks.xl - 0.01}px)`,
    xl: `(min-width: ${breaks.xl}px)`,
};

export const breakpointsIsolated = {
    xs: `@media ${queriesIsolated.xs}`,
    sm: `@media ${queriesIsolated.sm}`,
    md: `@media ${queriesIsolated.md}`,
    lg: `@media ${queriesIsolated.lg}`,
    xl: `@media ${queriesIsolated.xl}`,
};
