import React, { FC } from 'react';
import styles from './Spacer.module.css';

interface IProps {
    size: '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10'
}

export const Spacer: FC<IProps> = ({ size }) => (
    <div className={styles[`space${size}`]} />
);
