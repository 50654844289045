import React from 'react';
import { useTranslations } from '~/services/cms';
import { VisuallyHidden, TextVariant, textStyles, Text } from 'ui';
import { CatalogueModel } from 'api-types';
import clsx from 'clsx';

import styles from './PriceGroup.module.css';


export type PriceGroupProps = Pick<
    CatalogueModel.ProductPrice,
    'displayPriceInclTax' | 'displayUnitPriceInclTax' | 'displaySalePriceInclTax' | 'isOnSale' | 'currency'
> & {
    variant?: TextVariant;
    orientation?: 'horizontal' | 'vertical';
    className?: string;
    packingUnit?: number;
};

export const PriceGroup = ({
    displayPriceInclTax,
    displaySalePriceInclTax,
    displayUnitPriceInclTax,
    isOnSale,
    variant = 'body',
    orientation = 'horizontal',
    className,
    packingUnit
}: PriceGroupProps) => {
    const translate = useTranslations();
    return (
        <div className={clsx(
            styles.priceGroup,
            className,
            {
                [styles.priceGroupHorizontal]: orientation === 'horizontal',
                [styles.priceGroupVertical]: orientation === 'vertical'
            })}>
            <span className={clsx(
                textStyles.text,
                textStyles[`variant-${variant}`],
                textStyles['as-span'],
                styles.price, {
                [styles.modifierOriginal]: isOnSale === true
            })}>
                <VisuallyHidden
                    children={translate(`commerce.price.${isOnSale ? 'previous' : 'current'}`)} />
                {packingUnit ? `${(displayPriceInclTax || displayUnitPriceInclTax)} / ${packingUnit}` : `${(displayPriceInclTax || displayUnitPriceInclTax)}`}
                {packingUnit ?
                    <Text className={styles.priceUnit} variant='bodyExtraSmall'>
                        {translate('product.packageUnit')} {packingUnit}
                    </Text> :
                    null
                }
            </span>
            {
                isOnSale ? (
                    <span className={clsx(
                        textStyles.text,
                        textStyles[`variant-${variant}`],
                        textStyles['as-span'],
                        styles.price,
                        styles['modifierDiscounted'])}>
                        <VisuallyHidden children={translate('commerce.price.current')} />
                        {displaySalePriceInclTax}
                    </span>
                ) : null
            }
        </div >
    );
};
