import { FC, forwardRef } from 'react';
import styles from './Button.module.css';
import clsx from 'clsx';
import { IButtonCommonProps } from '../Buttons.models';
import ButtonWrapper from '../ButtonWrapper/ButtonWrapper';
import { SvgIcon, Loader } from '../../../index';

export interface IButtonProps extends IButtonCommonProps {
    variant?: 'primary' | 'secondary' | 'tertiary' | 'link';
    size?: 'large' | 'medium' | 'small' | 'link';
    isFlexible?: boolean;
    iconPosition?: 'left' | 'right'
    iconClassName?: string;
}

export const Button: FC<IButtonProps> = forwardRef(({
    children,
    variant = 'primary',
    size = 'medium',
    iconPosition = 'left',
    className,
    iconName,
    isLoading,
    isFlexible,
    iconClassName,
    ...restProps
}, ref) => {

    const buttonClassNames = clsx(
        styles.button,
        styles[`${variant}Variant`],
        styles[`${size}Size`],
        className,
        {
            [styles.flexible]: isFlexible,
            [styles.iconOnly]: !!iconName && !children
        }
    );


    return (
        <ButtonWrapper
            className={buttonClassNames}
            isLoading={isLoading}
            ref={ref}
            {...restProps}
        >
            {iconName && (
                <SvgIcon
                    name={iconName}
                    className={clsx(styles.icon, iconClassName, {
                        [styles.iconRight]: iconPosition === 'right'
                    })} />
            )}
            {children ? <span className={styles.content}>{children}</span> : null}
            {isLoading && (
                <Loader
                    className={styles.loader} padding="0"
                    size="22px"
                    borderWidth="2px" />
            )}
        </ButtonWrapper >
    );
});
