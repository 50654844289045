import { useForm as ReactHookForm, UseFormProps, UseFormReturn } from 'react-hook-form';
import { AnyObjectSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { setValidationLocale } from '../../config/setValidationLocale';
import { useTranslations } from '~/services/cms';

type Props = UseFormProps & { validationSchema?: AnyObjectSchema };

/**
 * Use this hook to create a form with validation.
 * Use in coherence with Yup validation schema.
 * and Yup resolver.´
 *
 * Pass Yup validation schema as props.validationSchema
 */
export const useForm = (props?: Props): UseFormReturn => {
    const translate = useTranslations();

    setValidationLocale(translate);

    const resolver = props?.validationSchema
        ? { resolver: yupResolver(props.validationSchema) }
        : {};

    return ReactHookForm({ ...resolver, ...props });
};
