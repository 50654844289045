import type { MarketsKey } from './types';

export class MarketsError extends Error {
  queryKeys: MarketsKey;
  response: Response;

  constructor(response: Response, keys: MarketsKey) {
    super();
    this.name = 'MarketsError';
    this.message =
      'Request failed with status code 500 Internal Server Error on url ' + response.url;
    this.queryKeys = keys;
    this.response = response;
  }
}
