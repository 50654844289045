import { fetcher } from './fetcher';

/**
 * Simpler version of a PUT request, supplies content type and serializes body to json
 */
export const putRequest = <ResponseBody = unknown, RequestBody = unknown>(
    url: string,
    body: RequestBody,
    headers: HeadersInit = {},
    abortSignal?: AbortSignal,
) =>
    fetcher<ResponseBody>(url, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json; charset=utf-8', ...headers },
        body: JSON.stringify(body),
        signal: abortSignal,
    });
