import React, { FC } from 'react'
import { Button, Skeleton } from 'ui';
import useFacets from '../use-facets';
import {
    BrandFacetResult,
    CategoryFacetResult,
    CategoryHierarchyFacetResult,
    DataObjectStringValueFacetResult,
    ProductDataObjectFacetResult,
    ProductDataStringValueFacetResult,
} from '@relewise/client'
import styles from './ActiveFacets.module.css'
import { IFacetsProps } from '../Facets';
import { useFrame, useTranslations } from '~/services/cms';
import { TranslationModel } from 'api-types';

export const ActiveFacets: FC<IFacetsProps> = ({ relewiseFacets, isFetching, isPLP, isMobile }) => {
    const { activeFacets, setFacet, setCategoryHierarchyFacet, clearFacets } = useFacets()

    const translate = useTranslations();
    const { data: frameData } = useFrame();
    const staticLinks = frameData?.staticLinks;

    interface IFlatFacet {
        hits: number;
        facetKey: string;
        facetValue: string;
        displayName: string;
        isCategoryHierarchyFacet: boolean
    }
    interface IFlatCategoryHierarchyFacet {
        hits: number;
        parentId: string;
        childId: string;
        displayName: string;
        isCategoryHierarchyFacet: boolean
    }

    const mapDataObjectStringValueFacet = (item: ProductDataStringValueFacetResult): IFlatFacet[] => {
        const getDisplayname = (val: string | boolean | null = ''): string => {
            if (typeof (val) === 'string') {
                switch (item?.key) {
                    case 'NewMonth':
                        return translate(`newMonth.month${val as string}` as TranslationModel.TranslationModel)

                    default:
                        return val
                }
            }
            if (typeof (val) === 'boolean') {
                if (item?.key === 'IsClassic') {
                    return val
                        ? translate('facets.isClassicVehicle')
                        : translate('facets.isNotClassicVehicle')
                } else {
                    return `${item?.key} ${val ? 'True' : 'False'}`
                }
            }
            return ''
        }

        return item?.available
            ?.filter(f => Boolean(activeFacets.find(a => f.value?.toString() && a.values.includes(f.value.toString()))))
            ?.map(f => {
                return ({
                    hits: f.hits || 0,
                    facetKey: item?.key || '',
                    facetValue: f.value || '',
                    displayName: getDisplayname(f?.value),
                    isCategoryHierarchyFacet: false
                })
            }) || []
    }

    const mapCategoryHierarchyFacet = (item: CategoryHierarchyFacetResult): IFlatCategoryHierarchyFacet[] => {
        const selectedNodes = (item as CategoryHierarchyFacetResult)?.nodes?.filter(f => f?.selected) || []
        const selectedCategories = selectedNodes?.map(c => c?.children).flat().filter(f => f?.selected) || []

        return selectedCategories?.map(f => ({
            hits: f?.hits || 0,
            parentId: f?.parentId || '',
            childId: f?.category?.categoryId || '',
            displayName: f?.category?.displayName || '',
            isCategoryHierarchyFacet: true
        })) || []
    }

    const flatFacets: IFlatFacet[] | IFlatCategoryHierarchyFacet[] = relewiseFacets?.items
        ?.map(item => {
            if (item?.field === 'Brand' || (item?.field === 'Category' && item?.$type.includes('CategoryFacet'))) {
                const filteredFacet = (item as BrandFacetResult | CategoryFacetResult)?.available
                    ?.filter(f => f.selected);
                return filteredFacet?.map(f => ({
                    hits: f.hits,
                    facetKey: item?.field,
                    facetValue: f.value?.id,
                    displayName: f?.value?.displayName
                }))
            }
            if (item?.field === 'Category' && item?.$type.includes('CategoryHierarchyFacet')) {
                return isMobile
                    ? mapCategoryHierarchyFacet(item as CategoryHierarchyFacetResult)
                    : undefined
            }
            if (item?.field === 'Data') {
                const facetData = item as ProductDataObjectFacetResult | ProductDataStringValueFacetResult
                if (facetData?.key === 'Remark' || facetData?.key === 'NewMonth') {
                    const innerFacetData = item as ProductDataStringValueFacetResult
                    return mapDataObjectStringValueFacet(innerFacetData)
                }
                if (facetData?.key === 'Linkages') {
                    return (item as ProductDataObjectFacetResult)
                        ?.items
                        ?.filter(f => Boolean(activeFacets.find(a =>
                            a.key === (f as DataObjectStringValueFacetResult)?.key)))
                        ?.map((f) => mapDataObjectStringValueFacet(f as ProductDataStringValueFacetResult))
                        .flat()

                }
            }
            //@ts-ignore
        }).flat().filter((facet: IFlatFacet) => facet !== undefined) as IFlatFacet[] | IFlatCategoryHierarchyFacet[] || []

    if (!activeFacets.filter(f => f.key !== "CategoryHierarchy")?.length && !isPLP) return null

    return (
        <div className={styles.root}>
            <div className={styles.facetsWrapper}>
                {flatFacets?.map((f, i) => {
                    if (f?.isCategoryHierarchyFacet) {
                        const facet = f as IFlatCategoryHierarchyFacet
                        return (
                            <div className={styles.buttonWrapper}>
                                <Button
                                    key={`${facet?.parentId}-${facet?.childId}-${i}`}
                                    iconName='close'
                                    iconPosition='right'
                                    variant='tertiary'
                                    size='small'
                                    onClick={() => setCategoryHierarchyFacet(facet?.parentId, facet?.childId)}>
                                    {facet?.displayName} ({facet?.hits})
                                </Button>
                                {isFetching && (
                                    <Skeleton width='100%' height='100%' className={styles.skeleton} variant='darker' />
                                )}

                            </div>
                        )
                    }
                    const facet = f as IFlatFacet
                    return (
                        <div className={styles.buttonWrapper}>
                            <Button
                                key={`${facet?.facetKey}-${facet?.facetValue}-${i}`}
                                iconName='close'
                                iconPosition='right'
                                variant='tertiary'
                                size='small'
                                onClick={() => setFacet(facet?.facetKey, facet?.facetValue)}>
                                {facet?.displayName} ({facet?.hits})
                            </Button>
                            {isFetching && (
                                <Skeleton width='100%' height='100%' className={styles.skeleton} variant='darker' />
                            )}
                        </div>
                    )
                })}
                {(activeFacets?.length > 0) ? (
                    <Button size='small' onClick={() => clearFacets()} disabled={isFetching}>
                        {translate('facets.clearAllFilter')}
                    </Button>
                ) : null}
            </div>

            {staticLinks?.allProductsPage && isPLP && <Button size='small' href={staticLinks.allProductsPage.url}>
                {staticLinks.allProductsPage.title}
            </Button>}

        </div>
    )
}
