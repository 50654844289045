import { IMAGE_CDN_URL } from "../public-variables";

interface IImageProps {
    fileName: string,
    modifiedDateTime: string | number,
    size: 'product' | 'productcarousel' | 'zoom'
}

interface IRelatedProps {
    fileName: string,
    modifiedDateTime: string | number,
    inspectedFileType: string
}

export function generateImageUrl({ size, fileName, modifiedDateTime }: IImageProps) {
    const baseUrl = `${IMAGE_CDN_URL}/pictures`;
    return `${baseUrl}/${size}/${fileName}?cachebuster=${modifiedDateTime}`
}

export function generateRelatedUrl({ fileName, modifiedDateTime, inspectedFileType }: IRelatedProps) {
    const baseUrl = `${IMAGE_CDN_URL}/pictures/${inspectedFileType === 'MP3' ? 'sound' : 'pdf'}`;
    return `${baseUrl}/${fileName}?cachebuster=${modifiedDateTime}`
}
