import React, { useImperativeHandle, useRef, useState } from 'react';
import { useInputField } from '../hooks/useInputField';
import { InvalidMessage, HelpText, Label } from '../';

import clsx from 'clsx';
import styles from './TextAreaField.module.css';

type TextAreaProps = React.InputHTMLAttributes<HTMLTextAreaElement>;

export type TextAreaFieldProps = Omit<TextAreaProps, 'value'> & {
    /**
     * Adds a label to the input field. This is required for accessibilty.
     */
    label?: string;

    /**
     * Add an additional help text below the input field.
     */
    helpText?: string;

    /**
     * Add an additional help text below the input field.
     */
    invalidMessage?: string;

    /**
     * Set styling to indicate input is invalid.
     * Also shows the `invalidMessage` if provided
     */
    isInvalid?: boolean;

    /**
     * This component is uncontrolled - you will need to controll the value.
     */
    value?: string;

    /**
     * When active the label will move up above the actual input field
     */
    isActive?: boolean;
};

export const TextAreaField = React.forwardRef<HTMLTextAreaElement, TextAreaFieldProps>(
    ({ label, helpText, invalidMessage, id, children, isActive, isInvalid, ...rest }, ref) => {
        const [isValidityValid, setIsValidityValid] = useState(true);
        const [hasFocus, setHasFocus] = useState(false);
        const textAreaRef = useRef<HTMLTextAreaElement>(null);
        const inputFieldRef = useRef<HTMLDivElement>(null);

        const {
            fieldId,
            labelId,
            helpTextId,
            invalidMessageId,
            describedById,
            showHelpText,
            showInvalidMessage,
        } = useInputField({
            id,
            helpText,
            isInvalid,
            invalidMessage,
        });

        const { value, defaultValue } = rest;

        const hasValue = value === undefined ? !!defaultValue : !!value;
        const isInputActive = isActive || hasFocus || hasValue;
        const isValid = !showInvalidMessage && isValidityValid;

        const onFocusHandler = () => setHasFocus(true);
        const onBlurHandler = () => {
            setHasFocus(false);
            setIsValidityValid(textAreaRef.current?.validity.valid ? true : false);
        };

        const onInputFieldClick = (event: React.MouseEvent) => {
            if (textAreaRef.current !== event.target) {
                textAreaRef.current?.focus();
                textAreaRef.current?.click();
                event?.preventDefault();
                event?.stopPropagation();
            }
        };

        useImperativeHandle(ref, () => textAreaRef.current as HTMLTextAreaElement, []);

        return (
            <>
                <div className={clsx(styles.textAreaFieldWrapper, {
                    [styles.valid]: isValid,
                    [styles.disabled]: rest.disabled
                })}
                    key={fieldId}
                    onFocus={onFocusHandler}
                    onBlur={onBlurHandler}
                >
                    <div className={clsx(styles.textAreaField, {
                        [styles.valid]: isValid
                    })}
                        onMouseDown={onInputFieldClick}
                        ref={inputFieldRef}
                    >
                        <>
                            {label ? (
                                <Label
                                    id={labelId}
                                    htmlFor={fieldId}
                                    title={label}
                                    children={label}
                                    isActive={isInputActive}
                                    isValid={isValid}
                                    hasFocus={hasFocus}
                                />
                            ) : null}

                            <textarea className={clsx(styles.textArea, {
                                [styles.withLabel]: !!label && isInputActive
                            })}
                                id={fieldId}
                                aria-describedby={describedById}
                                ref={textAreaRef}
                                {...rest}
                            />
                        </>
                    </div>
                    {children}
                </div>
                {showInvalidMessage ? (
                    <InvalidMessage id={invalidMessageId} children={invalidMessage} />
                ) : null}
                {showHelpText ? <HelpText id={helpTextId} children={helpText} /> : null}
            </>
        );
    }
);
