import styled from '@emotion/styled';
import { darken, transparentize } from 'color2k';
import { switchProp } from 'styled-tools';
import { ButtonDeprecatedProps } from './Button';

export const StyledResetButton = styled.button({
    alignItems: 'center',
    appearance: 'none',
    border: 'none',
    boxSizing: 'border-box',
    display: 'inline-flex',
    cursor: 'pointer',
    flexShrink: 0,
    lineHeight: 1,
    letterSpacing: 0,
    margin: 0,
    padding: 0,
    textRendering: 'inherit',
    textDecoration: 'none',
    WebkitUserSelect: 'none',
    userSelect: 'none',
    WebkitTapHighlightColor: 'rgba(0,0,0,0)',
    borderRadius: 0,
    backgroundColor: 'transparent',
    color: 'inherit',
});

export const StyledButton = styled(StyledResetButton)<ButtonDeprecatedProps>(
    ({ theme }) => ({
        padding: '1em 1.4em',
        height: '3em',
        fontFamily: theme.fonts.headline,
        fontWeight: theme.fontWeights.regular,
        justifyContent: 'center',
        gap: '0.3em', // multiple children
        // svg: {
        //     height: 25,
        //     width: 25,
        // },
        ':disabled': {
            opacity: 0.5,
            cursor: 'not-allowed',
            pointerEvents: 'none',
        },
    }),

    switchProp('variant', {
        primary: ({ theme, shade }) => {
            const isDark = shade === 'dark';
            return {
                backgroundColor: isDark ? theme.colors.black : theme.colors.white,
                color: isDark ? theme.colors.white : theme.colors.black,

                '&:hover': {
                    backgroundColor: isDark ? theme.colors.blue : darken(theme.colors.white, 0.1),
                    color: isDark ? theme.colors.white : theme.colors.black,
                },

                '&:active': {
                    backgroundColor: isDark
                        ? darken(theme.colors.blue, 0.05)
                        : darken(theme.colors.white, 0.15),
                },
            };
        },
        secondary: ({ theme, shade }) => {
            const isDark = shade === 'dark';
            return {
                backgroundColor: 'transparent',
                boxShadow: '0 0 0 2px currentColor inset',
                color: isDark ? theme.colors.black : theme.colors.white,

                '&:hover': {
                    color: isDark ? theme.colors.blue : darken(theme.colors.white, 0.1),
                    backgroundColor: isDark
                        ? transparentize(theme.colors.black, 0.95)
                        : transparentize(theme.colors.white, 0.95),
                },

                '&:active': {
                    color: isDark
                        ? darken(theme.colors.blue, 0.05)
                        : darken(theme.colors.white, 0.15),
                    backgroundColor: isDark
                        ? transparentize(theme.colors.black, 0.85)
                        : transparentize(theme.colors.white, 0.9),
                },
            };
        },
        tertiary: ({ theme, shade }) => {
            const isDark = shade === 'dark';
            return {
                backgroundColor: 'transparent',
                color: isDark ? theme.colors.black : theme.colors.white,

                '&:hover': {
                    color: isDark ? theme.colors.blue : darken(theme.colors.white, 0.1),
                    backgroundColor: isDark
                        ? transparentize(theme.colors.black, 0.95)
                        : transparentize(theme.colors.white, 0.95),
                },

                '&:active': {
                    color: isDark
                        ? darken(theme.colors.blue, 0.05)
                        : darken(theme.colors.white, 0.15),
                    backgroundColor: isDark
                        ? transparentize(theme.colors.black, 0.85)
                        : transparentize(theme.colors.white, 0.9),
                },
            };
        },
    }),
    switchProp('shape', {
        plain: ({ theme }) => ({
            height: 'auto',
            padding: '0.2em 0.3em',
            margin: '-0.2em -0.3em',
            fontFamily: theme.fonts.body,
            fontWeight: theme.fontWeights.regular,
            textDecoration: 'underline',
        }),
        icon: () => ({
            height: 'auto',
            padding: '1em',
        }),
    })
);

export const StyledButtonContent = styled.span({
    alignItems: 'center',
    display: 'inline-flex',
    gap: '0.3em', // multiple children
});
