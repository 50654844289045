import { useSpring } from 'framer-motion';

export type SpringOptions = NonNullable<Parameters<typeof useSpring>[1]>;

// Determined motions
export const durations = {
    fast01: 0.07,
    fast02: 0.11,
    moderate01: 0.15,
    moderate02: 0.24,
    slow01: 0.4,
    slow02: 0.7,
    slow03: 1.5,
};

export const bezierValues: BezierValues = {
    standard: {
        productive: [0.2, 0, 0.38, 0.9],
        expressive: [0.4, 0.14, 0.3, 1],
    },
    entrance: {
        productive: [0, 0, 0.38, 0.9],
        expressive: [0, 0, 0.3, 1],
    },
    exit: {
        productive: [0.2, 0, 1, 0.9],
        expressive: [0.4, 0.14, 1, 1],
    },
    reversible: {
        productive: [0.55, 0, 0.45, 1],
        expressive: [0.65, 0, 0.35, 1],
    },
};

export const easings: Easings = {
    standard: {
        productive: `cubic-bezier(${bezierValues.standard.productive.join(',')})`,
        expressive: `cubic-bezier(${bezierValues.standard.expressive.join(',')})`,
    },
    entrance: {
        productive: `cubic-bezier(${bezierValues.entrance.productive.join(',')})`,
        expressive: `cubic-bezier(${bezierValues.entrance.expressive.join(',')})`,
    },
    exit: {
        productive: `cubic-bezier(${bezierValues.exit.productive.join(',')})`,
        expressive: `cubic-bezier(${bezierValues.exit.expressive.join(',')})`,
    },
    reversible: {
        productive: `cubic-bezier(${bezierValues.reversible.productive.join(',')})`,
        expressive: `cubic-bezier(${bezierValues.reversible.expressive.join(',')})`,
    },
};

export function getEasing(type: EasingTypes, mode: EasingModes): string {
    if (!easings[type]) {
        throw new Error(
            `Unable to find easing \`${type}\` in our supported easings. Expected ` +
                `One of: ${Object.keys(easings).join(', ')}`
        );
    }

    const easing = easings[type];
    if (!easing[mode]) {
        throw new Error(`Unable to find a mode for the easing \`${type}\` called: \`${mode}\``);
    }

    return easing[mode];
}

export function getDuration(speed: Duration): string {
    if (!durations[speed]) {
        throw new Error(
            `Unable to find easing \`${speed}\` in our supported easings. Expected ` +
                `One of: ${Object.keys(easings).join(', ')}`
        );
    }
    return `${durations[speed]}s`;
}

export type Easings = { [key in EasingTypes]: EasingMode };
export type EasingTypes = 'standard' | 'entrance' | 'exit' | 'reversible';
export type EasingModes = 'productive' | 'expressive';
export type EasingMode = { [key in EasingModes]: string };
export type Durations = typeof durations;
export type Duration = keyof Durations;
export type BezierValues = { [key in EasingTypes]: BezierValueModes };
export type BezierValueModes = { [key in EasingModes]: BezierValueArray };
export type BezierValueArray = [number, number, number, number];

// Spring motions
export const springDefault: SpringOptions = {
    stiffness: 500,
    damping: 50,
};

export const springEntrance: SpringOptions = {
    stiffness: 364,
    damping: 31,
    mass: 1,
};

export const springExit: SpringOptions = {
    stiffness: 700,
    damping: 42,
};

// Temp variable for slide-in-out animation. Don't use this!
export const defaultTranslateX = '24px';
export const defaultTranslateY = '24px';
