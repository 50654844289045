import { useRouter } from 'next/router';
import { CmsModel } from 'api-types';
import { useFrame as useServicesFrame } from 'services';
import locale from '~/constants/locale.mjs';

const fallbackLocale = locale.defaultLocale;

export function useFrame<Select = CmsModel.Frame>(select?: (data: CmsModel.Frame) => Select) {
    const { locale, defaultLocale = fallbackLocale } = useRouter();
    const language = locale || defaultLocale;

    return useServicesFrame({ locale: language, select });
}
