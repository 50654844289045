import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useBasketState } from '$features/Basket';
import { CommerceModel } from 'api-types';
import { useBasketPaths } from './useBasketPaths';
import { useNotification } from '~/shared/hooks/useNotification';
import { addItem } from '../service';
import { errorToJson } from '../utils/format';
import { useAuthentication } from '~/shared/hooks';
import { useTranslations } from '~/services/cms';

export const useBasketAdd = () => {
    const { push } = useNotification();
    const { basketLineUrl, basketQueryKey } = useBasketPaths();
    const { toggleMiniBasket, setBasketId } = useBasketState();
    const queryClient = useQueryClient();
    const { session } = useAuthentication();
    const translate = useTranslations()

    const { isLoading, mutateAsync: add } = useMutation<
        CommerceModel.Basket,
        Response,
        CommerceModel.NewBasketLine
    >((args) => addItem(args, basketLineUrl, session?.accessToken), {
        onSuccess: (basket) => {
            if (basket.conversionErrors?.length) {
                toggleMiniBasket(false);
                basket.conversionErrors.forEach((error: string) => {
                    push({ severity: 'warning', text: error });
                });
            } else {
                push({
                    severity: 'success',
                    text: translate('notifications.newItemAddedToBasket')
                });
            }
            queryClient.setQueryData([basketQueryKey], basket);
            if (basket.id) {
                setBasketId(basket.id);
            }
        },
        onError: async (error) => {
            toggleMiniBasket(false);
            const { level, errorMessages } = await errorToJson(error);
            push({ severity: level, text: errorMessages });
        },
    });

    return {
        isLoading,
        add,
    };
};
