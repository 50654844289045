import { setLocale } from 'yup';
import { LocaleObject } from 'yup/lib/locale';
import { TranslationModel } from 'api-types';

type Translator = (
    key: TranslationModel.TranslationModel,
    values?: {
        key?: string | undefined;
        value?: any | undefined;
    }[],
    ignoreMissing?: boolean
) => string;

let localeConfig: LocaleObject | null = null;
export const setValidationLocale = (translator: Translator): void => {
    if (!localeConfig) {
        localeConfig = getLocaleConfig(translator);
        setLocale(localeConfig);
    }
};

/**
 * Returns the locale config for the current locale.
 * Add all localized validation messages here.
 * Fallback to english if no translation is available.
 *
 * Supply translation key from validation error messages.
 */

const getLocaleConfig = (translator: Translator): LocaleObject => {
    return {
        mixed: {
            default: () => translator('form.validation.default'),
            required: () => translator('form.validation.required'),
        },
        string: {
            email: () => translator('form.validation.email'),
            min: ({ min }) => translator('form.validation.min', [{ key: 'min', value: min }]),
            max: ({ max }) => translator('form.validation.max', [{ key: 'max', value: max }]),
            matches: ({ originalValue }) =>
                translator('form.validation.matches', [
                    { key: 'originalValue', value: originalValue },
                ]),
        },
        number: {
            min: ({ min }) => translator('form.validation.min', [{ key: 'min', value: min }]),
            max: ({ max }) => translator('form.validation.max', [{ key: 'max', value: max }]),
            integer: () => translator('form.validation.integer'),
        },
    };
};
