import { FetchError } from 'services';
import { CatalogueModel } from 'api-types';
import { useMarkets } from '../globalization';
import { useProductDetailsSlug } from '../use-product-details-slug';
import { useRouter } from 'next/router';
import { useAuthentication } from '~/shared/hooks';

import { useQuery } from '@tanstack/react-query';
import { minutes, seconds } from 'utils';
import { getProduct } from 'services/src/catalogue/content/product/get-product';

export function useProduct(
    select?: (data: CatalogueModel.ProductVariant) => CatalogueModel.ProductVariant
) {
    const { session } = useAuthentication();
    const { query, locale = '', defaultLocale } = useRouter();
    const { productId, variantId } = useProductDetailsSlug(query?.slug)
    const { currentMarket } = useMarkets();

    const getProductProps = {
        market: currentMarket?.storeKey || '',
        locale: locale || defaultLocale || 'da',
        productId,
        variantId,
        accessToken: session?.accessToken
    }

    return useQuery<CatalogueModel.ProductVariant, FetchError>(
        {
            queryKey: ['ProductPage', getProductProps],
            queryFn: () => getProduct(getProductProps),
            select,
            retry: 1,
            staleTime: minutes(10),
            cacheTime: minutes(60),
            retryDelay: seconds(10),
            keepPreviousData: false,
            refetchOnWindowFocus: false,
        }
    );
}
