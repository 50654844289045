import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { CatalogueModel } from 'api-types';
import { useNotification } from '~/shared/hooks/useNotification';
import { useAuthentication, usePrefixUrl } from '~/shared/hooks';
import { errorToJson } from '~/components/features';

interface IWatchListResponse extends CatalogueModel.StockNotifications {
    statusCode: number
}

export const useWatchlist = () => {
    const { stockNotificationBaseUrl } = usePrefixUrl()
    const { push } = useNotification();
    const { isAuthenticated, session } = useAuthentication();

    const queryClient = useQueryClient()


    const { isLoading, isError, data } = useQuery<IWatchListResponse, Response>({
        refetchOnWindowFocus: true,
        queryKey: ['watchlist'],
        enabled: isAuthenticated,
        queryFn: async () => {
            const res = await fetch(stockNotificationBaseUrl, {
                headers: {
                    'Authorization': `Bearer ${session?.accessToken}`
                }
            })

            if (res.status === 204) {
                return ({
                    json: {},
                    statusCode: 200
                });
            }

            const json = await res.json()
            return ({
                ...json,
                statusCode: res.status == 204 ? 200 : res.status
            })
        }
    })

    function isInTheList(itemId: string) {
        return Boolean(data?.items?.find(f => f.productId === itemId))
    }

    const watchlistMutation = useMutation({
        mutationFn: async (id: string) => {
            const isSelected = isInTheList(id);
            const res = await fetch(`${stockNotificationBaseUrl}/${id}`, {
                method: isSelected ? 'DELETE' : 'POST',
                headers: {
                    'Authorization': `Bearer ${session?.accessToken}`
                }
            });
            return await (res.json());
        },
        onError: async (error: Response) => {
            const { level, errorMessages } = await errorToJson(error);
            push({ severity: level, text: errorMessages });
        },
        onSuccess: data => {
            queryClient.setQueryData(['watchlist'], data)
        }
    })


    return {
        watchlistMutation,
        data,
        isLoading,
        isError,
        isInTheList
    }
}