import React from 'react';
import { Link, SvgIcon } from 'ui';
import { useFrame } from '~/services/cms';
import NextLink from 'next/link';
import styles from './Logo.module.css';

export type LogoProps = {
    /**
     * pass SVG Logo node example: <Logo children={<BasketIcon/>} description={'Go to basket'} />
     */
    children?: React.ReactNode;

    /**
     * Description of what the logo is and where it potientially leads to,
     * this is required for screen readers
     */
    description: string;
};

export const Logo = ({ children = <SvgIcon name="logo" />, description }: LogoProps) => {
    const { data } = useFrame();

    const frontPageUrl = data?.staticLinks?.frontPage?.url || '/';
    return (
        <NextLink href={frontPageUrl} passHref>
            <Link href={frontPageUrl} aria-label={description}>
                <div className={styles.logo}>{children}</div>
            </Link>
        </NextLink>
    );
};
