import { getRequest } from '$shared/utils';
import { CommerceModel } from 'api-types';
import { useBasketState } from './useBasketState';
import { useBasketPaths } from './useBasketPaths';
import { useQuery } from '@tanstack/react-query';
import { BASKET_STORAGE_KEY } from '../constants/keys';
import { deleteFromStorage, useLocalStorage } from '@rehooks/local-storage';
import { useNotification } from '~/shared/hooks/useNotification';
import { useEffect, useMemo } from 'react';
import { errorToJson } from '../utils/format';
import { useQueryClient } from '~/services/query-client';
import { useAuthentication } from '~/shared/hooks';
import { isJPG } from 'utils';
import { useUserInformation } from '~/page-type/page-types/P181Profile';

/**
 * Exposes basket data and actions.
 */
export const useBasket = () => {
    const queryClient = useQueryClient();
    const {
        data: userInformation,
        refetch: refetchCustomer,
    } = useUserInformation();


    const uIState = useBasketState();
    const { basketId, setBasketId } = uIState
    const { basketUrl, basketQueryKey } = useBasketPaths();
    const { push } = useNotification();
    const [localStorageBasketId, setLocalStorageBasketId] = useLocalStorage<string | null>(BASKET_STORAGE_KEY, null);
    const { session, isAuthenticated } = useAuthentication();

    const { data: basket, error, isLoading, isError, isStale, status, refetch, remove } = useQuery<
        CommerceModel.Basket,
        Response
    >([basketQueryKey], getRequest<CommerceModel.Basket>(basketUrl, {
        'Authorization': `Bearer ${session?.accessToken}`
    }), {
        enabled: Boolean(basketId) && (!isJPG() || (isJPG() && isAuthenticated)),
        // Set basket to stale after  5 minutes
        staleTime: 5 * 60 * 1000, // 5 minutes
        // Make sure we refetch basket always on focus
        refetchOnWindowFocus: 'always',
        retry: (failureCount, error) => {
            if (error instanceof Response && error?.status === 404) {
                if (!userInformation?.basketId) {
                    setBasketId(null);
                    queryClient.removeQueries([basketQueryKey], { exact: true });

                    // TODO: Basket gone figure out what to show to the user
                    return false;
                }
            }

            return failureCount < 3;
        },
        onError: async (error) => {
            const { level, errorMessages } = await errorToJson(error);
            push({ severity: level, text: errorMessages });
        },
        onSuccess: (basket) => {
            if (!basketId || basketId !== basket.id) {
                setBasketId(basket.id);
            }
        },
    });

    async function deleteBasketService() {
        try {
            await fetch(basketUrl, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${session?.accessToken}`
                }
            });
        } catch (error) {
            push({ severity: 'warning', text: 'Failed to delete basket' });
        }
    }

    useEffect(() => {
        if (!basketId && localStorageBasketId) {
            setBasketId(localStorageBasketId)
        }
    }, [])

    useEffect(() => {
        if (!basketId) {
            deleteFromStorage(BASKET_STORAGE_KEY)
        }
        if (basketId !== localStorageBasketId) {
            setLocalStorageBasketId(basketId)
        }
    }, [basketId])


    useEffect(() => {
        if (userInformation?.basketId) {
            setBasketId(userInformation?.basketId)
        }
    }, [userInformation?.basketId])

    const totalQuantity = useMemo(
        () => basket?.lines?.reduce((acc, { quantity = 1 }) => acc + quantity, 0),
        [basket?.lines]
    );

    const clearBasketCache = () => {
        setBasketId(null);
        queryClient.removeQueries([basketQueryKey], { exact: true });
        remove();
    }

    const refetchCustomerAfterDelete = async () => {
        const { data: newCustomerData } = await refetchCustomer()
        newCustomerData?.basketId && setBasketId(newCustomerData?.basketId)
    }

    const deleteBasket = async (deleteFromDB?: boolean) => {
        if (userInformation?.basketId) {
            deleteFromDB && await deleteBasketService()
            clearBasketCache()
            if (deleteFromDB) {
                window.setTimeout(() => {
                    refetchCustomerAfterDelete()
                }, 5000)

            }
        } else {
            clearBasketCache()

        }
        // router.reload()
    }

    return {
        ...uIState,
        data: basket,
        totalQuantity,
        status,
        error,
        isLoading: isLoading && Boolean(basketId),
        isError,
        isStale,
        refetch,
        remove,
        deleteBasket
    };
};
